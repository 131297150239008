import { ButtonComp } from 'atoms/Buttons';
import React, { useEffect, useContext, useRef, useState } from 'react';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { LOCAL_STORAGE_KEYS, CHANGE_LANGUAGE } from 'common/constants/SharedConstants';
import { UserOrderContext } from 'context/context';

const getCustomSelectSpan = (selectedItemVal, optionLabelFieldNameData, placeholderInfo) => {
  return selectedItemVal && selectedItemVal[optionLabelFieldNameData]
    ? selectedItemVal[optionLabelFieldNameData]
    : placeholderInfo || '';
};

export const CustomSelect = props => {
  const {
    source,
    onClick,
    onChange,
    className,
    isDisplayLabel = true,
    label,
    labelClassName,
    optionLabelFieldName,
    optionCustomLabelFn,
    optionValueFieldName,
    optionClassName,
    selectedValue,
    placeholder,
    placeholderClassName,
    customSelectedLabelFn,
    dropdownClassName,
    langSelector,
  } = props;

  const getSelectedItem = selectedVal => {
    return source?.filter(item => item[optionValueFieldName] === selectedVal)[0];
  };

  const selectRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(getSelectedItem(selectedValue));
  const { userOrderState } = useContext(UserOrderContext);

  const userLanguage = localStorage.getItem(LOCAL_STORAGE_KEYS?.USER_LANGUAGE);
  const defaultLanguage = localStorage.getItem(LOCAL_STORAGE_KEYS?.DEFAULT_LANGUAGE);
  const selectedLanguage = userLanguage ?? defaultLanguage?.key;

  const onClickHandler = event => {
    const targetName = event.target.getAttribute('name');
    setIsOpen(
      (targetName === 'customSelect' || targetName === 'customSelectSpan' || targetName === 'customSelectArrow') &&
        !isOpen
        ? !isOpen
        : false,
    );
    onClick && onClick(event);
  };

  const onClickOptionHandler = (event, item, value) => {
    setSelectedItem(getSelectedItem(value));
    onChange && onChange(event, item, value);
    setIsOpen(false);
  };

  const documentOnClickHandler = event => {
    const targetName = event.target.getAttribute('name');
    if (
      targetName !== 'customSelect' &&
      targetName !== 'customSelectSpan' &&
      targetName !== 'customSelectArrow' &&
      targetName !== 'customSelectOption' &&
      targetName !== 'customSelectOptionSpan'
    ) {
      setIsOpen(false);
    }
  };

  const onKeyDownHandler = event => {
    event.keyCode === 27 && setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', documentOnClickHandler, false);
    document.addEventListener('keydown', onKeyDownHandler);
    return () => {
      setIsOpen(false);
      document.removeEventListener('click', documentOnClickHandler, false);
      document.removeEventListener('keydown', onKeyDownHandler);
    };
  }, []);

  return (
    <React.Fragment>
      <div className='global-custom-select-container col-12'>
        {isDisplayLabel && (
          <span htmlFor='customSelect' className={labelClassName ? labelClassName : ''}>
            {label}
          </span>
        )}

        <ButtonComp
          class='customSelect'
          ref={selectRef}
          className={`${className ? className : ''}`}
          onClickHandler={onClickHandler}
          onKeyDownHandler={onKeyDownHandler}
          data-testid='customSelect'
        >
          <span
            name='customSelectSpan'
            className={placeholderClassName ? placeholderClassName : ''}
            onClick={onClickHandler}
            data-testid='customSelectSpan'
          >
            {selectedItem && customSelectedLabelFn
              ? customSelectedLabelFn(selectedItem)
              : langSelector && !userOrderState?.cartClearConfirmed
              ? placeholder
              : getCustomSelectSpan(selectedItem, optionLabelFieldName, placeholder)}
          </span>
          <img
            name='customSelectArrow'
            className={`dropdown-img ${isOpen ? 'upArrow' : ''}`}
            onClick={onClickHandler}
            data-testid='customSelectArrow'
            alt=''
          ></img>

          {source && (
            <div
              name='customSelectList'
              className={`
                global-custom-select-dropdown col-12 
                ${isOpen ? 'open ' : ''}  
                ${dropdownClassName ? dropdownClassName : ''}`}
            >
              {langSelector && <span className='lang-selector'>{translateWithI18Next(CHANGE_LANGUAGE)}:</span>}
              {source?.map(item => (
                <ButtonComp
                  class='customSelectOption'
                  className={`global-custom-select-dropdown-option col-12 
                  ${optionClassName ? optionClassName : ''}`}
                  onClickHandler={event => onClickOptionHandler(event, item, item[optionValueFieldName])}
                  data-testid='customSelectOption'
                >
                  <span
                    className={item[optionValueFieldName] === selectedLanguage ? 'active-lang' : ''}
                    name='customSelectOptionSpan'
                    data-testid='customSelectOptionSpan'
                    onClick={event => onClickOptionHandler(event, item, item[optionValueFieldName])}
                  >
                    {optionCustomLabelFn ? optionCustomLabelFn(item) : item[optionLabelFieldName]}
                  </span>
                </ButtonComp>
              ))}
            </div>
          )}
        </ButtonComp>
      </div>
    </React.Fragment>
  );
};

export default CustomSelect;
