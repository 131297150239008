import React, { useEffect, useState } from 'react';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { updateSaveCardHanlder } from 'common/utilities/PaymentUtils';
import { reachedMaxmiumSavedCardWarning } from 'organisms/MyPaymentMethods/Presentation/sharedTemplates/PaymentMethodsTemplates';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';

const PaymentSaveCardTemplate = props => {
  const [showResults, setShowResults] = useState(false);
  const { checkoutDetails, updateCheckoutDetails } = props;
  const { billingAddressAndSaveCard } = checkoutDetails;

  const updateFields = ({ target }) => {
    const { value } = target;
    const isCardNameExist = checkoutDetails?.savedCards.find(obj => obj?.cardNickName?.trim() === value);
    updateCheckoutDetails({
      showCardNickNameError: isCardNameExist,
      billingAddressAndSaveCard: {
        ...billingAddressAndSaveCard,
        nickName: value,
      },
    });
  };

  useEffect(() => {
    checkoutDetails?.isAddNewCard && setShowResults(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isAddNewCard]);

  return (
    <div className='save-card'>
      {checkoutDetails?.savedCards?.length >= checkoutDetails?.maxSavedCardLimit && reachedMaxmiumSavedCardWarning()}
      <div
        className={`${
          checkoutDetails?.isAddNewCard ? 'hidden' : 'd-flex'
        } form-checkbox-container form-checkbox-payonarrival`}
      >
        <input
          type='checkbox'
          className={`common_checkbox`}
          onChange={event => updateSaveCardHanlder({ event, checkoutDetails, updateCheckoutDetails, setShowResults })}
          data-testid={`pay-on-arrival-checkbox`}
          name='cc-Payment-save-card'
          id='Payment-save-card'
          disabled={checkoutDetails?.savedCards?.length >= checkoutDetails?.maxSavedCardLimit}
        />
        <label title='title' className='form-check-label' for='Payment-save-card'>
          {translateWithI18Next(TRANSLATE_MAPPING_KEY?.SAVE_CARD_FOR_FASTER_CHECKOUT)}
        </label>
      </div>
      {showResults && (
        <>
          <div className={`input-container ${checkoutDetails?.showCardNickNameError ? 'inputError' : ''}`}>
            <input
              data-testid='input-nickname'
              name='nickname'
              type='text'
              onChange={updateFields}
              tabIndex='-1'
              required
            />
            <label className='address-label'>{translateWithI18Next(TRANSLATE_MAPPING_KEY?.CARD_NICK_NAME)}</label>
            {checkoutDetails?.showCardNickNameError && (
              <div className='error d-flex errorRelativePosition'>
                <img className='mr-2' src={IMAGE_PATH?.Ellipse} alt='Error visible' />{' '}
                {checkoutDetails?.billingAddressAndSaveCard?.nickName === ''
                  ? translateWithI18Next(TRANSLATE_MAPPING_KEY?.ENTER_CARD_NICK_NAME)
                  : translateWithI18Next(TRANSLATE_MAPPING_KEY?.CARD_NICK_NAME_VALIDATION)}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentSaveCardTemplate;
