import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomSelect from 'atoms/DropDown/CustomSelect';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { getTernaryValue } from '../../common/utilities/utils';
import { PAYMENT_TENDER_TYPES } from '../../common/constants/SharedConstants';

export default function PaymentFormNetBankingOtherWallet({
  setFormPayload,
  setIsValid,
  tender,
  updateCheckoutDetails,
  checkoutDetails,
}) {
  const [listOfBanks, setListOfBanks] = useState([]);
  const { formPayload } = checkoutDetails || {};

  const { paymentsReducer } = useSelector(data => {
    return data;
  });

  useEffect(() => {
    let tempArr = [];

    if (paymentsReducer && (!listOfBanks || listOfBanks?.length === 0)) {
      switch (tender?.name) {
        case PAYMENT_TENDER_TYPES.NET_BANKING:
          if (paymentsReducer?.configuration) {
            tempArr = paymentsReducer?.configuration;
          }
          break;
        case PAYMENT_TENDER_TYPES.OTHER_WALLET:
          if (paymentsReducer?.otherWallets) {
            tempArr = paymentsReducer?.otherWallets;
          }
          break;
        default:
          break;
      }
      if (tempArr && tempArr?.length > 0 && tempArr?.sort) {
        setListOfBanks(
          tempArr?.sort((itemA, itemB) => {
            return itemA.displayOrder - itemB.displayOrder;
          }),
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsReducer]);

  const customSelectedLabelFn = selectedItem => {
    switch (tender?.name) {
      case PAYMENT_TENDER_TYPES.NET_BANKING:
        return selectedItem && selectedItem?.bankName
          ? selectedItem?.bankName
          : translateWithI18Next('selectYourBankHeader');
      case PAYMENT_TENDER_TYPES.OTHER_WALLET:
        return selectedItem && selectedItem?.bankName
          ? translateWithI18Next('otherWallet') + ': ' + selectedItem?.bankName
          : translateWithI18Next('selectYourOtherWalletHeader');
      default:
        break;
    }
  };

  const onChangeHandler = (event, item, value) => {
    updateCheckoutDetails?.({
      formPayload: {
        ...formPayload,
        bankId: value,
        bankName: item?.bankName,
      },
      isValid: value ? true : false,
    });
  };

  return (
    <React.Fragment>
      <div className='col-12'>
        <CustomSelect
          source={listOfBanks}
          optionLabelFieldName='bankName'
          optionValueFieldName='bankId'
          placeholder={getTernaryValue(
            tender?.name === PAYMENT_TENDER_TYPES.NET_BANKING,
            translateWithI18Next('selectYourBankHeader'),
            translateWithI18Next('selectYourOtherWalletHeader'),
          )}
          customSelectedLabelFn={customSelectedLabelFn}
          onChange={onChangeHandler}
        ></CustomSelect>
      </div>
    </React.Fragment>
  );
}
