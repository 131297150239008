const DELIVERY_ADDRESS_SEARCH_FORM_CONSTANTS = {
  ADDRESS_LABEL: 'addressLabel',
  APARTMENT_NAME: 'apartmentName',
  AUTO_SUGGESTIONS_DELIVERY_FILTER: ['address'],
  CHOME: 'chome',
  CHOMES_CONTENTFUL: 'chomes',
  CITY_TOWN: 'cityTown',
  COMPANY_NAME: 'companyName',
  CONFIRM_LOCATION: 'confirmLocation',
  DROPOFF_ADDRESS: 'dropoffAddress',
  DELIVERY_SEARCH_TYPE: 'deliverySearchType',
  OTHER_DELIVERY_SERVICES: 'otherDeliveryServices',
  NO_STORE_WITH_DELIVERY: 'noStoresWithDeliveryHeader',
  SWITCH_TO_TAKE_AWAY: 'switchToTakeaway',
  ADDRESS: 'address',
  POSTAL_CODE: 'postalCode',
  PREFECTURE: 'prefecture',
  ROOM_NUMBER: 'roomNumber',
  SCHEDULE_ORDER_DATA: 'scheduleOrderData',
  SEARCH: 'search',
  SEARCH_DELIVERY_LOCATION: 'searchDeliveryLocation',
  SPECIAL_NOTES: 'specialNote',
  STORES_VARY_MESSAGE: 'storesVaryMessage',
  STREET_ADDRESS_DETAILS: 'streetDetailAddress',
  POSTAL_CODES: 'postalCodes',
  POSTAL_CODE_CONTENTFUL: 'postcodes',
  PREFECTURES: 'prefectures',
  CITY_CODE: 'cityCode',
  CHOME_CODE: 'chomeCode',
};
const DELIVERY_ADDRESS_SEARCH_FORM_MODULE_DATA = {
  contentData: {
    searchStoreHeaderText: 'selectKFC',
    deliveryHeaderText: 'deliveryAddress',
    searchStoreModalContent: {
      formContent: {
        memberText: 'alreadyMember',
        singInLink: {
          linkUrl: null,
          linkText: 'signIn',
          targetType: '_blank',
        },
      },
    },
  },
};
export { DELIVERY_ADDRESS_SEARCH_FORM_MODULE_DATA };
export default DELIVERY_ADDRESS_SEARCH_FORM_CONSTANTS;
