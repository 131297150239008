import { AddContactInfoClick, BeginCheckoutClick, PaymentInfoClick } from 'common/utilities/analyticsUtils';
import { formatDateTime, currentDateTimeInFormat } from 'common/utilities/dayJsUtils';
import {
  safeUpperCase,
  userParameterObject,
  getEmbeddedErrorMessageAnalytic,
  getTransactionFailedAnalytic,
  getUserType,
  getCouponDetails,
} from 'common/utilities/utils';
import START_ORDER_CONSTANTS from 'organisms/StartOrderComponent/Constants/startOrderConstants';
import constants, { EMAIL_REGEX, FULL_NAME_REGEX } from './Constants/checkoutConstants';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { HOME_PAGE_CONSTANT_DATA } from 'common/utilities/homePageModulator';
import { clearPastOrderAction, resetOrderAction } from '@kfc-global/react-shared/redux/Actions/orderAction';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import ImageComponent from 'atoms/Image';
import { resetPostOrder } from '@kfc-global/react-shared/redux/Actions/PostOrderAction';
import { LOCAL_STORAGE_KEYS } from 'common/constants/SharedConstants';
import { login } from 'common/utilities/LoginUtils';

const { Error } = IMAGE_PATH;
const { ANALYTICS_LOCALISATION_SIGNIN } = START_ORDER_CONSTANTS;
const { SCHEDULE_ORDER, CHANGE_ORDER_TITLE } = TRANSLATE_MAPPING_KEY;

/* Sign in button click */
export const styleForMaterialTextInput = error => {
  return error ? 'formElement-error' : 'formElement';
};

export const getUserData = (name, callBack) => {
  name && callBack();
};

export const errorStyleForMaterialTextInput = error => {
  return error ? 'text-danger' : ' ';
};

export const isMobileClassName = isMobile => {
  return isMobile ? ' mobile' : '';
};

export const isMobilePadding = isMobile => {
  return isMobile ? '' : ' px-rt-5px';
};

export const signInClick = () => {
  localStorage.setItem(ANALYTICS_LOCALISATION_SIGNIN, JSON.stringify(true));
  login();
};

export const isValidEmail = email => {
  var pattern = EMAIL_REGEX;
  return pattern.test(email?.toLowerCase());
};

export const isValidFullName = fullName => {
  const pattern = FULL_NAME_REGEX;
  return pattern.test(fullName);
};

export const getSchedule = ({ date, time }) => {
  const isDateTomorrow = () => formatDateTime(date, 'YYYYMMDD') === currentDateTimeInFormat('YYYYMMDD');
  const { ASAP } = constants;
  let resultElmnt = '';
  if (date && time) {
    let dayIs = isDateTomorrow() ? translateWithI18Next('today') : translateWithI18Next('tomorrow');
    let timeIs = time;
    resultElmnt = (
      <>
        <label>{dayIs}</label>
        <span>{timeIs}</span>
      </>
    );
  }

  if (time === 'ASAP') {
    resultElmnt = <label>{translateWithI18Next(ASAP)}</label>;
  }
  return resultElmnt;
};

export const typeInfo = info_type => {
  const { CHECKOUT_TITLES, IS_DELIVERY, IS_TAKEAWAY, IS_DINEIN } = constants;
  let resultInfoType;
  if (safeUpperCase(info_type) === IS_DELIVERY) {
    resultInfoType = CHECKOUT_TITLES.DELIVERY_INFO;
  }
  if (safeUpperCase(info_type) === IS_TAKEAWAY) {
    resultInfoType = CHECKOUT_TITLES.TAKEAWAY_INFO;
  }
  if (safeUpperCase(info_type) === IS_DINEIN) {
    resultInfoType = CHECKOUT_TITLES.DINEIN_INFO;
  }
  return resultInfoType;
};

export const typeOpts = opts_type => {
  const { CHECKOUT_TITLES, IS_DELIVERY, IS_TAKEAWAY, IS_DINEIN } = constants;
  let resultOptsType;
  if (safeUpperCase(opts_type) === IS_DELIVERY) {
    resultOptsType = CHECKOUT_TITLES.DELIVERY_OPTIONS;
  }
  if (safeUpperCase(opts_type) === IS_TAKEAWAY) {
    resultOptsType = CHECKOUT_TITLES.TAKEAWAY_OPTIONS;
  }
  if (safeUpperCase(opts_type) === IS_DINEIN) {
    resultOptsType = CHECKOUT_TITLES.DINEIN_OPTIONS;
  }
  return resultOptsType;
};

export const getSubscribeData = (subscribeObj, checkStatus) => {
  let subObj = { ...subscribeObj };
  subObj.value = checkStatus;
  subObj.error = !checkStatus;
  return subObj;
};

export const getFullNameData = (fullNameObject, inputFn) => {
  let fullNameObj = { ...fullNameObject };
  fullNameObj.value = inputFn;
  if (!inputFn || inputFn.length < 2) {
    fullNameObj.error = true;
    fullNameObj.errorMessage = translateWithI18Next('emptyFullNameErrMsg');
  } else if (!isValidFullName(inputFn) || inputFn.length > 26) {
    fullNameObj.error = true;
    fullNameObj.errorMessage = translateWithI18Next('invalidFullNameErrMsg');
  } else {
    fullNameObj.error = false;
    fullNameObj.errorMessage = '';
  }
  return fullNameObj;
};

export const getPhoneData = (phoneObject, inputPhone) => {
  let phoneObj = { ...phoneObject };
  phoneObj.value = inputPhone;
  if (!inputPhone) {
    phoneObj.error = true;
    phoneObj.errorMessage = translateWithI18Next('emptyPhoneNumberErrMsg');
  } else if (inputPhone.length < 10 || inputPhone.length > 12 || isNaN(inputPhone) || !/^\d*$/.test(inputPhone)) {
    phoneObj.error = true;
    phoneObj.errorMessage = translateWithI18Next('invalidPhoneNumberErrMsg');
  } else {
    phoneObj.error = false;
    phoneObj.errorMessage = '';
  }
  return phoneObj;
};

export const getEmailData = (emailObject, inputEmail) => {
  let emailObj = { ...emailObject };
  emailObj.value = inputEmail;

  if (!inputEmail) {
    emailObj.error = true;
    emailObj.errorMessage = translateWithI18Next('emptyEmailErrMsg');
  } else if (!isValidEmail(inputEmail)) {
    emailObj.error = true;
    emailObj.errorMessage = translateWithI18Next('invalidEmailErrMsg');
  } else {
    emailObj.error = false;
    emailObj.errorMessage = '';
  }
  return emailObj;
};

export const getUserDetails = customerObj => {
  const customerObject = { ...customerObj };
  const { firstName = '', middleName = '', lastName = '', email = '', phone = '', phoneNumber = '' } = customerObject;

  const fullName = (firstName || '') + (middleName || '') + (lastName || '');

  return {
    fullName,
    email,
    phone: phone !== '' ? phone : phoneNumber,
  };
};

export const analyticsMethod = (cartObject, type, storeObject, productMapById) => {
  const { currencyConversionFactor, userParams = {}, currencyShortName = '' } = storeObject;
  if (cartObject?.foodLines?.length) {
    const { promoCode, discountTotal } = getCouponDetails(cartObject);
    const itemsArray = [];
    const itemsArrayStep1 = [];
    cartObject?.foodLines.forEach((i, idx) => {
      let priceWithoutTaxObj = i.item.customProperties.find(
        item => item.key.toLowerCase() === constants.PRICE_WITHOUT_TAX.toLowerCase(),
      );
      let priceAmt = (priceWithoutTaxObj?.value / currencyConversionFactor).toFixed(2);
      const { categoryId: id = 'NA', categoryName: name = 'NA' } = productMapById?.[i.item?.id] ?? {};
      const itemObj = {
        item_name: i.item?.dname[0]?.value || 'NA',
        item_id: i.item?.id || 'NA',
        price: parseFloat(priceAmt) || 'NA',
        item_category: name,
        item_list_name: name,
        item_list_id: id,
        index: idx,
        quantity: i.quantity || 'NA',
      };

      itemsArray.push(itemObj);
      itemsArrayStep1.push({
        ...itemObj,
        item_variant: !i?.item?.modGrps?.length
          ? 'NA'
          : i?.item?.modGrps?.reduce((acc, cv) => acc + `${cv?.name}, `, ''),
        currency: currencyShortName,
        coupon: promoCode,
        discount: +(discountTotal / currencyConversionFactor).toFixed(2),
      });
    });

    if (type === 'AddContactInfoClick') {
      AddContactInfoClick(itemsArray);
    }
    if (type === 'BeginCheckoutClick') {
      const analyticsObject = {
        disposition: cartObject?.service,
        store_id: cartObject?.store?.storeId,
        userType: getUserType(userParams?.localized, userParams?.loggedIn),
        coupon: promoCode,
        total: +(cartObject?.total / currencyConversionFactor).toFixed(2),
        currency: currencyShortName,
      };
      BeginCheckoutClick(itemsArrayStep1, analyticsObject);
    }
  }
};

export const getUserInformation = (userInformation, inputValue, type, radioValue) => {
  const { IS_DELIVERY, IS_TAKEAWAY } = constants;
  let userInformationObj = { ...userInformation };
  userInformationObj.value = inputValue;
  if (safeUpperCase(type) === IS_DELIVERY) {
    userInformationObj.error = false;
    userInformationObj.errorMessage = '';
  } else {
    if (!inputValue && safeUpperCase(type) === IS_TAKEAWAY && radioValue === 'handittome') {
      userInformationObj.error = true;
      userInformationObj.errorMessage = translateWithI18Next('pleaseEnterYourVehicalInformation');
    } else if (inputValue.length < 2 && safeUpperCase(type) === IS_TAKEAWAY && radioValue === 'handittome') {
      userInformationObj.error = true;
      userInformationObj.errorMessage = translateWithI18Next('invalidVehicalInformation');
    } else {
      userInformationObj.error = false;
      userInformationObj.errorMessage = '';
    }
  }
  return userInformationObj;
};

export const updatePaymentAnalytics = ({
  cartData,
  currencyConversionFactor,
  tender,
  productMapById,
  currencyShortName,
  userType,
}) => {
  const itemsArray = [];
  const couponCode = cartData?.discountLines[0]?.couponCode || 'NA';
  const value = cartData?.total / currencyConversionFactor;
  cartData?.foodLines?.forEach((itemDetails, index) => {
    const { categoryId: id = 'NA', categoryName: name = 'NA' } = productMapById?.[itemDetails?.item?.id] ?? {};
    itemsArray.push({
      item_name: itemDetails?.item?.name || 'NA',
      item_id: itemDetails?.item?.id || 'NA',
      price: Number((itemDetails?.unitPrice / currencyConversionFactor).toFixed(2)) || 0,
      item_category: name !== '' ? cartData?.foodLines[0]?.item?.categoryName : name,
      item_list_name: name !== '' ? cartData?.foodLines[0]?.item?.categoryName : name,
      item_list_id: id !== '' ? cartData?.foodLines[0]?.item?.categoryId : id,
      index: index,
      quantity: itemDetails?.quantity || 0,
    });
  });
  PaymentInfoClick({ tender, itemsArray, couponCode, currencyShortName, value, userType });
};

const getOrderReducerErrorMessage = orderReducer => {
  const errorsDetails = orderReducer?.response?.response
    ? orderReducer?.response?.response?.data
    : orderReducer?.response;
  let errorMessage = '';

  if (errorsDetails?.errors && errorsDetails?.errors.length > 0) {
    errorMessage = Array.isArray(errorsDetails?.errors) ? errorsDetails?.errors[0] : errorsDetails?.errors;
  } else if (errorsDetails?.errorMessages) {
    errorMessage = Array.isArray(errorsDetails?.errorMessages)
      ? errorsDetails?.errorMessages[0]
      : errorsDetails?.errorMessages;
  }
  errorMessage = errorMessage?.message ? errorMessage?.message : errorMessage;
  return errorMessage;
};

export const showCheckoutPageError = (orderReducer, showPaymentError, showPaymentPreValidationError) => {
  const { PAYMENT_FAILED_MESSAGE, PAYMENT_PRE_VALIDATION_FAILED_MESSAGE } = HOME_PAGE_CONSTANT_DATA;
  let errorMessage = '';

  if (showPaymentPreValidationError) {
    errorMessage = translateWithI18Next(PAYMENT_PRE_VALIDATION_FAILED_MESSAGE);
  } else if (showPaymentError) {
    errorMessage = translateWithI18Next(PAYMENT_FAILED_MESSAGE);
  } else if (orderReducer && orderReducer?.error) {
    errorMessage = getOrderReducerErrorMessage(orderReducer);
  }

  return (
    errorMessage !== '' && (
      <div className='row row-cols-12 mt-10px payment-failed-error-box'>
        <ImageComponent srcFile={Error} className='mt-3px mr-5px' />
        <div className='ml-10px'>{errorMessage}</div>
      </div>
    )
  );
};

export const showCheckoutPageErrorAnalytic = (orderReducer, showPaymentError, showPaymentPreValidationError) => {
  const { PAYMENT_FAILED_MESSAGE, PAYMENT_PRE_VALIDATION_FAILED_MESSAGE } = HOME_PAGE_CONSTANT_DATA;
  let errorMessage = '';

  if (showPaymentPreValidationError) {
    errorMessage = translateWithI18Next(PAYMENT_PRE_VALIDATION_FAILED_MESSAGE);
  } else if (showPaymentError) {
    errorMessage = translateWithI18Next(PAYMENT_FAILED_MESSAGE);
  } else if (orderReducer && orderReducer?.error) {
    errorMessage = getOrderReducerErrorMessage(orderReducer);
  }

  if (errorMessage !== '') {
    getEmbeddedErrorMessageAnalytic(document.title, errorMessage);
    getTransactionFailedAnalytic(errorMessage);
  }
};

export const executeAnalyticsForInStore = (Type, CollectionPoint) => {
  if (Type === 'PICKUP') {
    CollectionPoint = CollectionPoint === 'leaveatmydoor' ? 'in-store' : 'KFC to your Car';
  }
  let setAttributePayload = sessionStorage.getItem('setAttributePayload');
  if (setAttributePayload) {
    setAttributePayload = JSON.parse(setAttributePayload);
    setAttributePayload.CollectionPoint = CollectionPoint;
    userParameterObject(setAttributePayload);
  }
};

export const kfcToCarCollectionPointCheck = (availableCollection, type) => {
  const { IS_TAKEAWAY, IS_CURBSIDE } = constants;
  return type === IS_TAKEAWAY
    ? Array.isArray(availableCollection) &&
        availableCollection.length > 0 &&
        Boolean(availableCollection.find(collection => collection.collectionPointName === IS_CURBSIDE))
    : true;
};

export const resetOrderReducer = (dispatch, orderReducer) => {
  if (orderReducer && orderReducer?.error && orderReducer?.response) {
    dispatch(resetOrderAction());
    dispatch(clearPastOrderAction({ value: [] }));
  }
  dispatch(resetPostOrder());
  sessionStorage.setItem(LOCAL_STORAGE_KEYS.ORDER_TRACKING, null);
};

export const embeddedErrorMessageAnalyticFn = (popupOrPageTitle = '', error = false, errorMessage = '') => {
  if (error) {
    getEmbeddedErrorMessageAnalytic(popupOrPageTitle, errorMessage);
  }
};

export const getCouponModalEmbeddedErrorMgsAnalyticFn = (popupOrPageTitle, couponErr) => {
  if (couponErr) {
    couponErr !== 'NA' && getEmbeddedErrorMessageAnalytic(popupOrPageTitle, couponErr);
  }
};

export const getScheduleOrderEmbeddedErrorMgsAnalyticFn = (changeFlow, warningMessage) => {
  const popupOrPageTitle = changeFlow ? translateWithI18Next(CHANGE_ORDER_TITLE) : translateWithI18Next(SCHEDULE_ORDER);

  if (warningMessage) {
    getEmbeddedErrorMessageAnalytic(popupOrPageTitle, warningMessage);
  }
};

export const paymentFormEmbeddedErrorMessageAnalyticFn = (popupOrPageTitle = '', error = false, errorMessage = '') => {
  embeddedErrorMessageAnalyticFn(popupOrPageTitle, error, errorMessage);
};
