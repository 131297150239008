import { config } from '@kfc-global/react-shared/config/config.utils';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import {
  DOORDASH_DELIVERY_ORDER_STATUS,
  ORDER_STATUS,
  SESSION_STORAGE_KEYS,
  PURCHASE_TYPE,
} from 'common/constants/SharedConstants';
import {
  isCateringDisposition,
  isDeliveryDisposition,
  isDeliveryOrPickupOrDineInDisposition,
  isPickupDisposition,
  isValidObject,
  isValidValue,
} from 'common/utilities/utils';
import {
  ORDER_COMPLETED_WITHOUT_FULFILLMENT_MINS,
  CATERING_ORDER_COMPLETED_WITHOUT_FULFILLMENT_MINS,
} from '@kfc-global/react-shared/config/config.constants';
import { getCurrentDateTimeWithTimeZoneInUnix, getUnixTimeInSeconds } from './dayJsUtils';

/*********************************************************************
 * isValidOrderStatus
 *
 *********************************************************************/
export const isValidOrderStatus = orderDetails => isValidValue(getOrderStatusValue(orderDetails));

/*********************************************************************
 * getOrderStatusValue
 *
 *********************************************************************/
export const getOrderStatusValue = orderDetails =>
  orderDetails?.enableGlobalOrderTracking &&
  (isDeliveryOrder(orderDetails) ||
    (isPickupDisposition(orderDetails?.service) && orderDetails?.enableRetryCountForPickupOrderTracking)) &&
  orderDetails?.deliveryDetails?.riderStatus?.length &&
  !isOrderRefundStatus(orderDetails?.status) &&
  !isCancelledOrder(orderDetails?.status)
    ? orderDetails?.deliveryDetails?.riderStatus
    : orderDetails?.status;

/*********************************************************************
 * getOrderStatusChangedAt
 *
 *********************************************************************/
export const getOrderStatusChangedAt = ({ orderDetailsFromReducer, orderDetails }) => {
  return orderDetailsFromReducer?.statusUpdatedDate
    ? getUnixTimeInSeconds(orderDetailsFromReducer?.statusUpdatedDate)
    : (orderDetails?.orderStatus !== getOrderStatusValue(orderDetailsFromReducer) && new Date().getTime()) ||
        orderDetails?.orderStatusChangedAt;
};

/*********************************************************************
 * isCheckSameOrderStatusMaintainLast30Mins
 *
 *********************************************************************/
export const isCheckSameOrderStatusMaintainLast30Mins = ({ orderDetailsFromReducer, orderDetails }) => {
  return (
    Math.ceil(
      Math.abs(new Date().getTime() - getOrderStatusChangedAt({ orderDetailsFromReducer, orderDetails })) / (1000 * 60),
    ) > 30
  );
};

/*********************************************************************
 * getStatusString
 *
 *********************************************************************/
export const getStatusValue = value => {
  return value?.orderStatus?.toUpperCase?.() ?? value?.status?.toUpperCase?.() ?? value?.toUpperCase?.() ?? '';
};

export const isStagedOrder = status => getStatusValue(status) === ORDER_STATUS.STAGED;

export const isAcceptedOrder = status => getStatusValue(status) === ORDER_STATUS.ACCEPTED;

export const isPaymentInitiated = status => getStatusValue(status) === ORDER_STATUS.PAYMENT_INITIATED;

export const isStagedOrAcceptedOrder = status =>
  isStagedOrder(status) || isAcceptedOrder(status) || isPaymentInitiated(status);

export const isSentPosCompletedOrder = status => getStatusValue(status) === ORDER_STATUS.SENT_TO_POS_COMPLETED;

export const isReadyForPosInjection = status => getStatusValue(status) === ORDER_STATUS.READY_FOR_POS_INJECTION;

export const isSentToPosCompletedOrisReadyPosInjection = status =>
  isSentPosCompletedOrder(status) || isReadyForPosInjection(status);

export const isCheckedInOrder = status => getStatusValue(status) === ORDER_STATUS.CHECKED_IN;

export const isCheckedInClosedOrder = status => getStatusValue(status) === ORDER_STATUS.CHECKED_IN_CLOSED;

export const isCheckedInCooking = status => getStatusValue(status) === ORDER_STATUS.CHECKED_IN_COOKING;

export const isCheckedInPrepared = status => getStatusValue(status) === ORDER_STATUS.CHECKED_IN_PREPARED;

export const isCheckedInCheckedInRemake = status => getStatusValue(status) === ORDER_STATUS.CHECKED_IN_REMAKE;

export const isDasherEnrouteToPickup = status =>
  getStatusValue(status) === DOORDASH_DELIVERY_ORDER_STATUS.DASHER_ENROUTE_TO_PICKUP;

export const isDasherEnrouteToDropOff = status =>
  getStatusValue(status) === DOORDASH_DELIVERY_ORDER_STATUS.DASHER_ENROUTE_TO_DROPOFF;

export const isDasherDroppedOff = status =>
  getStatusValue(status) === DOORDASH_DELIVERY_ORDER_STATUS.DASHER_DROPPED_OFF;

export const isUndelivered = status => getStatusValue(status) === DOORDASH_DELIVERY_ORDER_STATUS.UNDELIVERED;

export const isDeliveryCancelled = status =>
  getStatusValue(status) === DOORDASH_DELIVERY_ORDER_STATUS.DELIVERY_CANCELLED;

export const isUnassigned = status => getStatusValue(status) === DOORDASH_DELIVERY_ORDER_STATUS.UNASSIGNED;

export const isCheckedInCheckedInNotCollected = status =>
  getStatusValue(status) === ORDER_STATUS.CHECKED_IN_NOT_COLLECTED;

export const isCheckedInAbandoned = status => getStatusValue(status) === ORDER_STATUS.CHECKED_IN_ABANDONED;

export const isCancelledOrder = status =>
  getStatusValue(status) === ORDER_STATUS.CANCELLED ||
  getStatusValue(status) === ORDER_STATUS.CANCEL ||
  getStatusValue(status) === DOORDASH_DELIVERY_ORDER_STATUS.DELIVERY_CANCELLED ||
  isDeliveryUnassignedCanceled(status);

export const isRefundOrder = status => getStatusValue(status) === ORDER_STATUS.REFUND;

export const isRefundInitiated = status => getStatusValue(status) === ORDER_STATUS.REFUND_INITIATED;

export const isRefundSuccessOrder = status => getStatusValue(status) === ORDER_STATUS.REFUND_SUCCESS;

export const isRefundFailedOrder = status => getStatusValue(status) === ORDER_STATUS.REFUND_FAILED;

export const isPaymentFailedOrder = status => getStatusValue(status) === ORDER_STATUS.PAYMENT_FAILED;

export const isSentPosFailedOrder = status => getStatusValue(status) === ORDER_STATUS.SENT_TO_POS_FAILED;

export const isFailedOrder = status => isPaymentFailedOrder(status) || isSentPosFailedOrder(status);

export const isUnassignedDeliveryCancel = status =>
  getStatusValue(status) === ORDER_STATUS.UNASSIGNED_DELIVERY_CANCELLED;

export const isUndeliveredDeliveryCancel = status =>
  getStatusValue(status) === ORDER_STATUS.UNDELIVERED_DELIVERY_CANCELLED;

export const isCreateOrder = status =>
  getStatusValue(status) === ORDER_STATUS.CREATED ||
  getStatusValue(status) === DOORDASH_DELIVERY_ORDER_STATUS.DELIVERY_CREATED ||
  getStatusValue(status) === ORDER_STATUS.SENT_TO_POS_COMPLETED;

export const isAssignedOrder = status =>
  getStatusValue(status) === ORDER_STATUS.ASSIGNED ||
  getStatusValue(status) === DOORDASH_DELIVERY_ORDER_STATUS.DASHER_CONFIRMED;

export const isStoreArrivalOrder = status =>
  getStatusValue(status) === ORDER_STATUS.STORE_ARRIVAL ||
  getStatusValue(status) === DOORDASH_DELIVERY_ORDER_STATUS.DASHER_ENROUTE_TO_PICKUP ||
  getStatusValue(status) === DOORDASH_DELIVERY_ORDER_STATUS.RELEASE_ORDER_FOR_PREPARATION ||
  getStatusValue(status) === DOORDASH_DELIVERY_ORDER_STATUS.DASHER_CONFIRMED_STORE_ARRIVAL;

export const isPickedUpOrder = status =>
  getStatusValue(status) === ORDER_STATUS.PICKED_UP ||
  getStatusValue(status) === ORDER_STATUS.CHECKED_IN ||
  getStatusValue(status) === DOORDASH_DELIVERY_ORDER_STATUS.DASHER_PICKED_UP;

export const isOutForDeliveryOrder = status =>
  getStatusValue(status) === ORDER_STATUS.OUT_FOR_DELIVERY ||
  getStatusValue(status) === DOORDASH_DELIVERY_ORDER_STATUS.DASHER_ENROUTE_TO_DROPOFF;

export const isArrivedOrder = status =>
  getStatusValue(status) === ORDER_STATUS.ARRIVED ||
  getStatusValue(status) === DOORDASH_DELIVERY_ORDER_STATUS.DASHER_CONFIRMED_CONSUMER_ARRIVAL;

export const isDeliveredOrder = status =>
  getStatusValue(status) === ORDER_STATUS.DELIVERED ||
  getStatusValue(status) === DOORDASH_DELIVERY_ORDER_STATUS.DASHER_DROPPED_OFF;

/*********************************************************************
 * Group validation
 *
 *********************************************************************/
export const isCreateOrAssignedOrder = orderDetails => isCreateOrder(orderDetails) || isAssignedOrder(orderDetails);

export const isCreateOrAssignedOrStoreArrivalOrder = orderDetails =>
  isCreateOrder(orderDetails) || isAssignedOrder(orderDetails) || isStoreArrivalOrder(orderDetails);

export const isPickedUpOrOutForOrArrivedOrder = orderDetails =>
  isPickedUpOrder(orderDetails) || isOutForDeliveryOrder(orderDetails) || isArrivedOrder(orderDetails);

export const isCreateOrAssignedOrTrackOrDeliveredOrCancelOrders = orderDetails =>
  isCreateOrAssignedOrStoreArrivalOrder(orderDetails) ||
  isPickedUpOrOutForOrArrivedOrder(orderDetails) ||
  isDeliveryOrderDeliveredOrCancelledOrRefund(orderDetails);

/*********************************************************************
 * StagedOrAccepted - Delivery
 *
 *********************************************************************/
export const isDeliveryOrderStagedOrAccepted = orderDetails =>
  isDeliveryDisposition(orderDetails?.service) && isStagedOrAcceptedOrder(orderDetails);

/*********************************************************************
 * POSCompleted - OrderStatus for all disposition
 *
 *********************************************************************/
export const isDeliveryOrderPOSCompleted = orderDetails =>
  isDeliveryDisposition(orderDetails?.service) && isSentToPosCompletedOrisReadyPosInjection(orderDetails);

export const isDeliveryOrPickupOrDineInOrderWithPosCompletedOrder = orderDetails =>
  isDeliveryOrPickupOrDineInDisposition(orderDetails?.service) && isSentPosCompletedOrder(orderDetails);

/*********************************************************************
 * DELIVERED - OrderGroupStatus
 *
 *********************************************************************/
export const isDeliveryOrderDelivered = orderDetails =>
  isDeliveryDisposition(orderDetails?.service) && isDeliveredOrder(orderDetails);

/*********************************************************************
 * CREATE - ASSIGN - Delivery
 *
 *********************************************************************/
export const isDeliveryOrderCreate = orderDetails =>
  isDeliveryDisposition(orderDetails?.service) && isCreateOrder(orderDetails);

export const isDeliveryOrderAssign = orderDetails =>
  isDeliveryDisposition(orderDetails?.service) && isAssignedOrder(orderDetails);

export const isDeliveryOrderStoreArrival = orderDetails =>
  isDeliveryDisposition(orderDetails?.service) && isStoreArrivalOrder(orderDetails);

export const isDeliveryOrderPickedUp = orderDetails =>
  isDeliveryDisposition(orderDetails?.service) && isPickedUpOrder(orderDetails);

export const isDeliveryOrderOutForDelivery = orderDetails =>
  isDeliveryDisposition(orderDetails?.service) && isOutForDeliveryOrder(orderDetails);

export const isDeliveryOrderArrivedOrder = orderDetails =>
  isDeliveryDisposition(orderDetails?.service) && isArrivedOrder(orderDetails);

export const isDeliveryOrderCreateOrAssigned = orderDetails =>
  isDeliveryOrderCreate(orderDetails) || isDeliveryOrderAssign(orderDetails);

export const isDeliveryOrderCreateOrAssignedOrStoreArrival = orderDetails =>
  isDeliveryOrderCreate(orderDetails) ||
  isDeliveryOrderAssign(orderDetails) ||
  isDeliveryOrderStoreArrival(orderDetails);

export const isDeliveryOrderPOSCompletedCreateAssigned = orderDetails =>
  isDeliveryOrderPOSCompleted(orderDetails) || isDeliveryOrderCreateOrAssigned(orderDetails);

/*********************************************************************
 * PICK UP - OUT FOR ARRIVED - ARRIVED - OrderGroupStatus
 *
 *********************************************************************/
export const isDeliveryOrderPickedUpOrOutForOrArrived = orderDetails =>
  isDeliveryDisposition(orderDetails?.service) && isPickedUpOrOutForOrArrivedOrder(orderDetails);

/*********************************************************************
 * ASSIGN - PICK UP - OUT FOR ARRIVED - OrderGroupStatus
 *
 *********************************************************************/
export const isDeliveryOrderAssignedOrPickedUpOrOutFor = orderDetails =>
  isDeliveryDisposition(orderDetails?.service) &&
  (isAssignedOrder(orderDetails) || isPickedUpOrder(orderDetails) || isOutForDeliveryOrder(orderDetails));

/*********************************************************************
 * ARRIVED - OrderGroupStatus
 *
 *********************************************************************/
export const isDeliveryOrderArrived = orderDetails =>
  isDeliveryDisposition(orderDetails?.service) && isArrivedOrder(orderDetails);

/*********************************************************************
 * CANCEL - FAILED - OrderGroupStatus
 *
 *********************************************************************/
export const isOrderRefund = orderDetails =>
  isRefundSuccessOrder(orderDetails) ||
  isRefundFailedOrder(orderDetails) ||
  isRefundOrder(orderDetails) ||
  isRefundInitiated(orderDetails);

export const isOrderRefundStatus = status =>
  status?.toUpperCase?.() === ORDER_STATUS.REFUND ||
  status?.toUpperCase?.() === ORDER_STATUS.REFUND_SUCCESS ||
  status?.toUpperCase?.() === ORDER_STATUS.REFUND_FAILED ||
  status?.toUpperCase?.() === ORDER_STATUS.REFUND_INITIATED;

export const isOrderCancelledOrRefund = orderDetails => isCancelledOrder(orderDetails) || isOrderRefund(orderDetails);

export const isDeliveryOrderCancelled = orderDetails =>
  isDeliveryDisposition(orderDetails?.service) && isCancelledOrder(orderDetails);

export const isDeliveryOrderDeliveredOrCancelled = orderDetails =>
  isDeliveryOrderDelivered(orderDetails) || isDeliveryOrderCancelled(orderDetails);

export const isDeliveryOrderCancelledOrRefund = orderDetails =>
  isDeliveryOrderCancelled(orderDetails) ||
  isDeliveryOrderRefundSuccess(orderDetails) ||
  isDeliveryOrderRefundFailed(orderDetails);

export const isDeliveryOrderDeliveredOrCancelledOrRefund = orderDetails =>
  isDeliveryOrderDelivered(orderDetails) || isDeliveryOrderCancelledOrRefund(orderDetails);

export const isDeliveryOrderFailed = orderDetails =>
  isDeliveryDisposition(orderDetails?.service) && isFailedOrder(orderDetails);

export const isDeliveryOrderRefundSuccess = orderDetails =>
  isDeliveryDisposition(orderDetails?.service) && isRefundSuccessOrder(orderDetails);

export const isDeliveryOrderRefundFailed = orderDetails =>
  isDeliveryDisposition(orderDetails?.service) && isRefundFailedOrder(orderDetails);

export const isDeliveryUnassignedCanceled = orderDetails =>
  isUnassignedDeliveryCancel(orderDetails) || isUndeliveredDeliveryCancel(orderDetails);

/*********************************************************************
 * Delivery
 *
 *********************************************************************/
export const isDeliveryOrder = orderDetails =>
  isDeliveryDisposition(orderDetails?.service) &&
  (isDeliveryOrderPOSCompleted(orderDetails) ||
    isDeliveryOrderCreateOrAssignedOrStoreArrival(orderDetails) ||
    isDeliveryOrderPickedUpOrOutForOrArrived(orderDetails) ||
    isDeliveryOrderDeliveredOrCancelledOrRefund(orderDetails) ||
    isUndelivered(orderDetails) ||
    isUnassigned(orderDetails));

export const isDeliveryOrderStatusPolling = orderDetails =>
  isDeliveryOrder(orderDetails) &&
  !isDeliveryOrderDelivered(orderDetails) &&
  !isDeliveryOrderCancelled(orderDetails) &&
  !isDeliveryOrderRefundSuccess(orderDetails) &&
  !isDeliveryOrderRefundFailed(orderDetails);

export const isDeliveryOrderCompleted = orderDetails =>
  isDeliveryOrder(orderDetails) && isDeliveryOrderDeliveredOrCancelledOrRefund(orderDetails);

export const isDeliveryOrderPartner = orderDetails =>
  isDeliveryOrder(orderDetails) && isValidObject(orderDetails?.deliveryPartner);

export const isDeliveryOrderDetails = orderDetails =>
  isDeliveryOrder(orderDetails) && isValidObject(orderDetails?.deliveryDetails);

/*********************************************************************
 * Iframe
 *
 *********************************************************************/
export const isDeliveryOrderIframeComponents = orderDetails =>
  isDeliveryOrderPickedUpOrOutForOrArrived(orderDetails) ||
  isDeliveryOrderDelivered(orderDetails) ||
  isDeliveryOrderCancelled(orderDetails);

export const isDeliveryOrderIframeComponentsWithoutDelivered = orderDetails =>
  isDeliveryOrderPickedUpOrOutForOrArrived(orderDetails) || isDeliveryOrderCancelled(orderDetails);

/*********************************************************************
 *
 *
 *********************************************************************/
export const getDeliveryOrderStatusMessageString = orderDetails => {
  if (isDeliveryOrderPOSCompleted(orderDetails)) {
    return TRANSLATE_MAPPING_KEY?.ORDER_RECEIVED;
  } else if (isDeliveryOrderCreate(orderDetails)) {
    return orderDetails?.sameOrderStatusMaintainLast30Mins
      ? TRANSLATE_MAPPING_KEY?.WE_RE_READY_TO_MAKE_YOUR_ORDER
      : TRANSLATE_MAPPING_KEY?.ORDER_READY;
  } else if (isDeliveryOrderAssign(orderDetails)) {
    return TRANSLATE_MAPPING_KEY?.WE_VE_FOUND_DELIVERY_DRIVER;
  } else if (isDeliveryOrderStoreArrival(orderDetails)) {
    return TRANSLATE_MAPPING_KEY?.WE_RE_MAKING_YOUR_ORDER_FRESH;
  } else if (isDeliveryOrderPickedUp(orderDetails)) {
    return TRANSLATE_MAPPING_KEY?.ORDER_ON_THE_WAY;
  } else if (isDeliveryOrderOutForDelivery(orderDetails)) {
    return TRANSLATE_MAPPING_KEY?.ORDER_ON_THE_WAY;
  } else if (isDeliveryOrderArrivedOrder(orderDetails)) {
    return TRANSLATE_MAPPING_KEY?.DRIVER_ARRIVING;
  } else if (isDeliveryOrderDelivered(orderDetails)) {
    return TRANSLATE_MAPPING_KEY?.ENJOY_MEAL;
  } else if (isDeliveryOrderCancelledOrRefund(orderDetails)) {
    return TRANSLATE_MAPPING_KEY?.CANCEL_ORDER;
  }
  return TRANSLATE_MAPPING_KEY?.ORDER_ACCEPTED;
};

/*********************************************************************
 * PickUp
 *
 *********************************************************************/
export const isPickupOrderSentPOSCompleted = orderDetails =>
  isPickupDisposition(orderDetails?.service) && isSentPosCompletedOrder(orderDetails);

export const isPickupOrderCheckedIn = orderDetails =>
  orderDetails?.enableRetryCountForPickupOrderTracking
    ? isPickupDisposition(orderDetails?.service) &&
      (isCheckedInClosedOrder(orderDetails) || isCheckedInAbandoned(orderDetails))
    : isPickupDisposition(orderDetails?.service) && isCheckedInOrder(orderDetails);

export const isPickupOrderCancelled = orderDetails =>
  isPickupDisposition(orderDetails?.service) &&
  (isCancelledOrder(orderDetails) ||
    isRefundSuccessOrder(orderDetails) ||
    isRefundFailedOrder(orderDetails) ||
    isRefundInitiated(orderDetails));

export const isPickupOrderCompleted = orderDetails =>
  orderDetails?.enableGlobalOrderTracking
    ? isPickupOrderCheckedIn(orderDetails) || isPickupOrderCancelled(orderDetails)
    : isPickupOrderSentPOSCompleted(orderDetails) || isPickupOrderCancelled(orderDetails);

export const isPickupOrderStatusPolling = orderDetails =>
  orderDetails?.enableRetryCountForPickupOrderTracking &&
  isPickupDisposition(orderDetails?.service) &&
  !isPickupOrderCompleted(orderDetails);

/*********************************************************************
 * Catering
 *
 *********************************************************************/
export const isCateringOrderAccepted = orderDetails =>
  isCateringDisposition(orderDetails?.service) && isAcceptedOrder(orderDetails);

export const isCateringOrderSentPOSCompleted = orderDetails =>
  isCateringDisposition(orderDetails?.service) && isSentPosCompletedOrder(orderDetails);

export const isCateringOrderCheckedIn = orderDetails =>
  isCateringDisposition(orderDetails?.service) && isCheckedInOrder(orderDetails);

export const isCateringOrderCancelled = orderDetails =>
  isCateringDisposition(orderDetails?.service) && isCancelledOrder(orderDetails);

export const isCateringOrderCompleted = orderDetails =>
  orderDetails?.enableGlobalOrderTracking
    ? isCateringOrderCheckedIn(orderDetails) || isCateringOrderCancelled(orderDetails)
    : isCateringOrderSentPOSCompleted(orderDetails) || isCateringOrderCancelled(orderDetails);

/*********************************************************************
 * Completed Orders
 *
 *********************************************************************/
export const isOrderProcessCompleted = orderDetails =>
  isDeliveryOrderCompleted(orderDetails) ||
  isPickupOrderCompleted(orderDetails) ||
  isCateringOrderCompleted(orderDetails);

/*********************************************************************
 * isCheckRetryCountForGetOrderTrackingStatus
 * retry count check for both delivery and pick up order status.
 *********************************************************************/
export const isCheckRetryCountForGetOrderTrackingStatus = (orderDetails, postOrPastOrderDetails = null) =>
  isValidOrderStatus(orderDetails) &&
  !isStagedOrAcceptedOrder(orderDetails) &&
  ((isDeliveryOrder(orderDetails) && !isDeliveryOrderDeliveredOrCancelled(postOrPastOrderDetails ?? orderDetails)) ||
    isPickupOrderStatusPolling(orderDetails));

/*********************************************************************
 * isOrderStatusPolling
 * check for both delivery and pick up order status polling.
 *********************************************************************/
export const isOrderStatusPolling = orderDetails =>
  isDeliveryOrderStatusPolling(orderDetails) || isPickupOrderStatusPolling(orderDetails);

/*********************************************************************
 * isOrderCompletedOnFulfillmentDateTime
 * console.log(getFormatFromUnix(orderDetails?.fulfillmentDateTime, DATE_FORMATS.ddd_DD_MM_YYYY_hh_mm_ss_A));
 * console.log(orderDetails?.fulfillmentDateTime + config(ORDER_COMPLETED_WITHOUT_FULFILLMENT_MINS) * 60);
 * console.log(getFormatFromUnix(orderDetails?.fulfillmentDateTime + config(ORDER_COMPLETED_WITHOUT_FULFILLMENT_MINS) * 60, DATE_FORMATS.ddd_DD_MM_YYYY_hh_mm_ss_A,),);
 * console.log(getCurrentDateTimeWithTimeZoneInUnix(orderDetails?.store?.timeZoneIANA));
 * console.log(getFormatFromUnix(getCurrentDateTimeWithTimeZoneInUnix(orderDetails?.store?.timeZoneIANA),DATE_FORMATS.ddd_DD_MM_YYYY_hh_mm_ss_A,),);
 *********************************************************************/
export const isOrderCompletedOnFulfillmentDateTime = orderDetails => {
  let calc = 0;
  if (isCateringDisposition(orderDetails?.service)) {
    calc =
      config(CATERING_ORDER_COMPLETED_WITHOUT_FULFILLMENT_MINS) !== undefined &&
      !isNaN(config(CATERING_ORDER_COMPLETED_WITHOUT_FULFILLMENT_MINS))
        ? config(CATERING_ORDER_COMPLETED_WITHOUT_FULFILLMENT_MINS)
        : 0;
  } else {
    calc =
      config(ORDER_COMPLETED_WITHOUT_FULFILLMENT_MINS) !== undefined &&
      !isNaN(config(ORDER_COMPLETED_WITHOUT_FULFILLMENT_MINS))
        ? config(ORDER_COMPLETED_WITHOUT_FULFILLMENT_MINS)
        : 0;
  }

  return (
    getCurrentDateTimeWithTimeZoneInUnix(orderDetails?.store?.timeZoneIANA) >
    orderDetails?.fulfillmentDateTime + calc * 60
  );
};

export const isAddHopePurchase = () =>
  sessionStorage.getItem(SESSION_STORAGE_KEYS.POST_PAYMENT_TYPE) === PURCHASE_TYPE.ADD_HOPE_PURCHASE;

export const isAddHopePaymentInProcess = status =>
  isAddHopePurchase() && getStatusValue(status) === ORDER_STATUS.MISC_PAYMENT_INPROGRESS;

export const isAddHopePaymentSuccess = status =>
  isAddHopePurchase() && getStatusValue(status) === ORDER_STATUS.MISC_PAYMENT_SUCSESS;

export const isAddHopePaymentFailure = status =>
  isAddHopePurchase() && getStatusValue(status) === ORDER_STATUS.MISC_PAYMENT_FAILURE;

export const orderStatusListOfFn = () => ({
  isDeliveryOrderPOSCompleted,
  isDeliveryOrderCreate,
  isDeliveryOrderAssign,
  isDeliveryOrderStoreArrival,
  isDeliveryOrderPickedUp,
  isDeliveryOrderOutForDelivery,
  isDeliveryOrderArrivedOrder,
  isSentPosCompletedOrder,
  isDasherEnrouteToPickup,
  isDasherEnrouteToDropOff,
  isDeliveryOrderCancelledOrRefund,
  isUnassigned,
  isUndelivered,
});

export const getScheduleOrderContainerClassName = orderDetails => {
  return isSentPosCompletedOrder(orderDetails) ? 'pos-completed-scheduleorder' : 'order-checkedin';
};

export const isGiftCardPurchase = () =>
  sessionStorage.getItem(SESSION_STORAGE_KEYS.POST_PAYMENT_TYPE) === PURCHASE_TYPE.GIFT_CARD_PURCHASE;

export const isGetGiftCardDetails = () =>
  sessionStorage.getItem(SESSION_STORAGE_KEYS.POST_PAYMENT_TYPE) === PURCHASE_TYPE.GET_GIFT_CARD_DETAILS;

export const isGiftCardPaymentInProcess = status =>
  isGiftCardPurchase() && getStatusValue(status) === ORDER_STATUS.MISC_PAYMENT_INPROGRESS;

export const isGiftCardPaymentSuccess = status =>
  isGiftCardPurchase() && getStatusValue(status) === ORDER_STATUS.MISC_PAYMENT_SUCSESS;

export const isGiftCardPaymentFailure = status =>
  isGiftCardPurchase() && getStatusValue(status) === ORDER_STATUS.MISC_PAYMENT_FAILURE;

export const isAddHopeOrGiftCardPurchase = () => isGiftCardPurchase() || isAddHopePurchase();

export const isOrderProcessingStatus = status => {
  const orderProcessingArray = [
    ORDER_STATUS?.STAGED,
    ORDER_STATUS?.ACCEPTED,
    ORDER_STATUS?.SENT_TO_POS_COMPLETED,
    ORDER_STATUS?.CHECKED_IN,
    ORDER_STATUS?.CHECKED_IN_CLOSED,
    ORDER_STATUS?.CHECKED_IN_COOKING,
    ORDER_STATUS?.CHECKED_IN_EXPIRED,
    ORDER_STATUS?.CHECKED_IN_ABANDONED,
    ORDER_STATUS?.CHECKED_IN_PREPARED,
    DOORDASH_DELIVERY_ORDER_STATUS?.DELIVERY_CREATED,
  ];
  return orderProcessingArray.includes(getStatusValue(status));
};

/*********************************************************************
 * to get available collection point for PICK order
 *********************************************************************/
export const handleAvailablePickupServices = (collectionPoints, pickupMethods) => {
  const collectionPointNames = collectionPoints.map(point => point.collectionPointName);
  const commonServices = Object.keys(pickupMethods).filter(key => collectionPointNames.includes(pickupMethods[key]));
  return commonServices;
};
