import React from 'react';

const TileButtonGroup = props => {
  return (
    <div className='checkoutContactInfoContainer'>
      <div className='row row-cols-12 mt-10px donationbutton'>
        <div className='tipping-details px-25px col-sm-8 col-12'>
          <div className='flex-container'>
            {props?.source?.map((detail, index) => {
              return (
                <div
                  name={`${props?.name}-${detail.value}-${props?.index}`}
                  className={`${props?.className} ${detail?.value === props?.selected ? 'selected-tip' : ''}`}
                  data-testid='sort-visit-change'
                  onClick={event => props?.onClickHandler({ event, detail, index })}
                >
                  <div className={'tippingpercentage'}>{detail?.label}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

TileButtonGroup.propTypes = {};

TileButtonGroup.defaultProps = {};

export default TileButtonGroup;
