export const ColorConstants = {
  WHITE: '#fff',
};

export const Environments = {
  DEV: 'development',
};

export const HIDE_CONSOLE_LOGS = 'HIDE_CONSOLE_LOGS';

export const BrandPageTemplates = {
  Template1: 'brandPageTemplate1',
  Template2: 'brandPageTemplate2',
};

export const BrandPageAUTemplates = {
  socialImpact: '/social-impact',
  meetTheColonel: '/meet-the-colonel',
};

export const APP_SOURCE_WEB = 'web';
export const userMilesFixedQr = '3000000';
