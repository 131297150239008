import React, { useRef, useEffect, useState } from 'react';
import { isTreeScrollable } from './isScrollable';
import RefreshContent from './RefreshContent';
import PullingContent from './PullingContent';
import { DIRECTION } from './types';
import { emptyFunction } from 'common/utilities/utils';

/**
 * Returns distance to bottom of the container
 */
export const getScrollToBottomValue = childRefObj => {
  if (!childRefObj || !childRefObj.current) {
    return -1;
  }
  const scrollTop = window.scrollY; // is the pixels hidden in top due to the scroll. With no scroll its value is 0.
  const scrollHeight = childRefObj.current.scrollHeight; // is the pixels of the whole container
  return scrollHeight - scrollTop - window.innerHeight;
};

export const initContainerFn = refsObj => {
  const { containerRef, pullDownRef, childrenRef, pullToRefreshThresholdBreached, fetchMoreTresholdBreached } = refsObj;

  /**
   * Reset Styles
   */

  if (childrenRef.current) {
    refsObj.childrenRef.current.style.marginTop = 0;
  }
  if (pullDownRef.current) {
    refsObj.pullDownRef.current.style.opacity = '0';
  }
  if (containerRef.current) {
    refsObj.containerRef.current.classList.remove('ptr--pull-down-treshold-breached');
    refsObj.containerRef.current.classList.remove('ptr--dragging');
    refsObj.containerRef.current.classList.remove('ptr--fetch-more-treshold-breached');
  }

  if (pullToRefreshThresholdBreached) {
    refsObj.pullToRefreshThresholdBreached = false;
  }
  if (fetchMoreTresholdBreached) {
    refsObj.fetchMoreTresholdBreached = false;
  }

  return refsObj;
};

export const onTouchStartActions = (e, posObj) => {
  posObj.isDragging = false;
  if (e instanceof MouseEvent) {
    posObj.startY = e.pageY;
  }
  if (window.TouchEvent && e instanceof TouchEvent) {
    posObj.startY = e.touches[0]?.pageY;
  }
  posObj.currentY = posObj.startY;
  // Check if element can be scrolled
  if (e.type === 'touchstart' && isTreeScrollable(e.target, DIRECTION.UP)) {
    return posObj;
  }
  // Top non visible so cancel
  if (posObj.childrenRef?.current?.getBoundingClientRect().top < 0) {
    return posObj;
  }
  posObj.isDragging = true;
  return posObj;
};

export const onTouchMoveFn = (e, touchObj) => {
  if (!touchObj.isDragging) {
    return touchObj;
  }

  if (window.TouchEvent && e instanceof TouchEvent) {
    touchObj.currentY = e.touches[0].pageY;
  } else {
    touchObj.currentY = e.pageY;
  }

  touchObj.containerRef.current?.classList.add('ptr--dragging');

  if (touchObj.currentY < touchObj.startY) {
    touchObj.isDragging = false;
    return touchObj;
  }

  // Limit to trigger refresh has been breached
  if (touchObj.currentY - touchObj.startY >= touchObj.pullDownThreshold) {
    touchObj.isDragging = true;
    touchObj.pullToRefreshThresholdBreached = true;
    touchObj.containerRef.current?.classList.remove('ptr--dragging');
    touchObj.containerRef.current?.classList.add('ptr--pull-down-treshold-breached');
  }

  // maxPullDownDistance breached, stop the animation
  if (touchObj.currentY - touchObj.startY > touchObj.maxPullDownDistance) {
    return touchObj;
  }

  touchObj.pullDownRef.current.style.opacity = ((touchObj.currentY - touchObj.startY) / 65).toString();
  touchObj.childrenRef.current.style.overflow = 'visible';
  touchObj.childrenRef.current.style.marginTop = `${touchObj.currentY - touchObj.startY}px`;
  touchObj.pullDownRef.current.style.visibility = 'visible';

  return touchObj;
};

const PullToRefresh = ({
  isPullable = true,
  canFetchMore = false,
  onRefresh = emptyFunction,
  onFetchMore = emptyFunction,
  refreshingContent = <RefreshContent />,
  pullingContent = <PullingContent />,
  children = null,
  pullDownThreshold = 67,
  fetchMoreThreshold = 100,
  maxPullDownDistance = 95, // max distance to scroll to trigger refresh
  backgroundColor,
  className = 'ptr-def',
  dataTestId = 'ptr-component',
}) => {
  let containerRef = useRef(null);
  let childrenRef = useRef(null);
  let pullDownRef = useRef(null);
  let fetchMoreRef = useRef(null);
  let pullToRefreshThresholdBreached = false;
  const [fetchMoreTresholdBreached, setFetchMoreTresholdBreached] = useState(false); // if true, fetchMore loader is displayed
  let isDragging = false;
  let startY = 0;
  let currentY = 0;

  const onTouchStart = e => {
    const posObj = onTouchStartActions(e, {
      isDragging,
      startY,
      currentY,
      childrenRef,
    });

    isDragging = posObj.isDragging;
    startY = posObj.startY;
    currentY = posObj.currentY;
  };

  useEffect(() => {
    if (!isPullable || !childrenRef || !childrenRef.current) {
      return;
    }
    childrenRef.current.addEventListener('touchstart', onTouchStart, { passive: true });
    childrenRef.current.addEventListener('mousedown', onTouchStart);
    childrenRef.current.addEventListener('touchmove', onTouchMove, { passive: false });
    childrenRef.current.addEventListener('mousemove', onTouchMove);
    window.addEventListener('scroll', onScroll);
    childrenRef.current.addEventListener('touchend', onEnd);
    childrenRef.current.addEventListener('mouseup', onEnd);
    document.body.addEventListener('mouseleave', onEnd);

    return () => {
      if (!isPullable || !childrenRef || !childrenRef.current) {
        return;
      }
      childrenRef.current.removeEventListener('touchstart', onTouchStart);
      childrenRef.current.removeEventListener('mousedown', onTouchStart);
      childrenRef.current.removeEventListener('touchmove', onTouchMove);
      childrenRef.current.removeEventListener('mousemove', onTouchMove);
      window.removeEventListener('scroll', onScroll);
      childrenRef.current.removeEventListener('touchend', onEnd);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      childrenRef.current.removeEventListener('mouseup', onEnd);
      document.body.removeEventListener('mouseleave', onEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, isPullable, onRefresh, pullDownThreshold, maxPullDownDistance, canFetchMore, fetchMoreThreshold]);

  /**
   * Check onMount / canFetchMore becomes true
   *  if fetchMoreThreshold is already breached
   */
  useEffect(() => {
    /**
     * Check if it is already in fetching more state
     */
    if (!containerRef?.current) {
      return;
    }
    const isAlreadyFetchingMore = containerRef.current.classList.contains('ptr--fetch-more-treshold-breached');
    if (isAlreadyFetchingMore) {
      return;
    }
    /**
     * Proceed
     */
    if (canFetchMore && getScrollToBottomValue(childrenRef) < fetchMoreThreshold && onFetchMore) {
      containerRef.current.classList.add('ptr--fetch-more-treshold-breached');
      setFetchMoreTresholdBreached(true);
      onFetchMore()?.then(initContainer).catch(initContainer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canFetchMore, children]);

  const initContainer = () => {
    const initializer = initContainerFn({
      containerRef,
      pullDownRef,
      childrenRef,
      pullToRefreshThresholdBreached,
      fetchMoreTresholdBreached,
    });
    containerRef = initializer.containerRef;
    pullDownRef = initializer.pullDownRef;
    childrenRef = initializer.childrenRef;
    pullToRefreshThresholdBreached = initializer.pullToRefreshThresholdBreached;
    setFetchMoreTresholdBreached(initializer.fetchMoreTresholdBreached);
  };

  const onTouchMove = e => {
    const touchObject = onTouchMoveFn(e, {
      isDragging,
      currentY,
      startY,
      containerRef,
      pullDownThreshold,
      pullToRefreshThresholdBreached,
      maxPullDownDistance,
      pullDownRef,
      childrenRef,
    });

    isDragging = touchObject.isDragging;
    currentY = touchObject.currentY;
    containerRef = touchObject.containerRef;
    pullToRefreshThresholdBreached = touchObject.pullToRefreshThresholdBreached;
    pullDownRef = touchObject.pullDownRef;
    childrenRef = touchObject.childrenRef;
  };

  const onScroll = e => {
    /**
     * Check if component has already called onFetchMore
     */
    if (fetchMoreTresholdBreached) {
      return;
    }
    /**
     * Check if user breached fetchMoreThreshold
     */
    if (canFetchMore && getScrollToBottomValue(childrenRef) < fetchMoreThreshold && onFetchMore) {
      setFetchMoreTresholdBreached(true);
      containerRef.current?.classList.add('ptr--fetch-more-treshold-breached');
      onFetchMore()?.then(initContainer).catch(initContainer);
    }
  };

  const onEnd = () => {
    isDragging = false;
    startY = 0;
    currentY = 0;

    // Container has not been dragged enough, put it back to it's initial state
    if (!pullToRefreshThresholdBreached) {
      if (pullDownRef.current) {
        pullDownRef.current.style.visibility = 'hidden';
      }
      initContainer();
      return;
    }

    if (childrenRef.current) {
      childrenRef.current.style.overflow = 'visible';
      childrenRef.current.style.marginTop = `${pullDownThreshold}px`;
    }
    onRefresh().then(initContainer).catch(initContainer);
  };

  return (
    <div className={`ptr ${className}`} style={{ backgroundColor }} ref={containerRef} data-testid={dataTestId}>
      <div className='ptr__pull-down' ref={pullDownRef} data-testid='ptr-pulldown-id'>
        <div className='ptr__loader ptr__pull-down--loading'>{refreshingContent}</div>
        <div className='ptr__pull-down--pull-more'>{pullingContent}</div>
      </div>
      <div className='ptr__children' ref={childrenRef} data-testid='ptr-children-id'>
        {children ?? <></>}
        <div className='ptr__fetch-more' ref={fetchMoreRef}>
          <div className='ptr__loader ptr__fetch-more--loading'>{refreshingContent}</div>
        </div>
      </div>
    </div>
  );
};

export default PullToRefresh;
