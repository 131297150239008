import { useEffect } from 'react';
import { updateClickPlaceOrderState } from 'common/utilities/PaymentUtils';
import { getGlobalPaymentsEnvironment } from '../utils/GlobalPaymentUtils';
import { config } from '@kfc-global/react-shared/config/config.utils';
import {
  GLOBAL_PAYMENTS_GOOGLE_PAY_MERCHANT_NAME,
  GLOBAL_PAYMENTS_GOOGLE_PAY_MERCHANT_ID,
  GLOBAL_PAYMENTS_GOOGLE_PAY_CLIENT_ID,
} from '@kfc-global/react-shared/config/config.constants';
import PAYMENT_CONSTANTS from 'organisms/Payment/constants/PaymentConstants';

const { GLOBAL_PAYMENT_GOOGLE_PAY_CONFIGURATION_CONSTANTS } = PAYMENT_CONSTANTS;

const updateGlobalPaymentGooglePayForm = params => {
  const { checkoutDetails, updateCheckoutDetails } = params;

  const globalPaymentGooglePayForm = window?.GlobalPayments?.apm?.form?.(
    '#payment-provider-place-order-google-pay-form',
    {
      amount: checkoutDetails?.finalAmount?.toString(),
      style: 'gp-default',
      apms: ['google-pay'],
    },
  );

  globalPaymentGooglePayForm?.ready(() => {
    console.log('Global Payment Card Form Ready');
  });

  globalPaymentGooglePayForm?.on('token-success', response => {
    updateCheckoutDetails({ globalPaymentsOneTimeResponse: response });
    updateClickPlaceOrderState(checkoutDetails, updateCheckoutDetails);
  });

  globalPaymentGooglePayForm?.on('token-error', response => {
    // Show error to the consumer
    console.log('Error on Global Payment Card Form');
  });
};

const GlobalPaymentGooglePayForm = props => {
  const { checkoutDetails } = props;

  useEffect(() => {
    window?.GlobalPayments?.configure({
      accessToken: checkoutDetails?.globalPaymentsAccesstoken,
      apiVersion: GLOBAL_PAYMENT_GOOGLE_PAY_CONFIGURATION_CONSTANTS?.API_VERSION,
      env: getGlobalPaymentsEnvironment(),
      apms: {
        currencyCode: checkoutDetails?.currencyCode,
        allowedCardNetworks: GLOBAL_PAYMENT_GOOGLE_PAY_CONFIGURATION_CONSTANTS?.ALLOWED_CARD_NETWORKS,
        googlePay: {
          currencyCode: checkoutDetails?.currencyCode,
          countryCode: checkoutDetails?.countryCode,
          merchantName: config(GLOBAL_PAYMENTS_GOOGLE_PAY_MERCHANT_NAME),
          allowedAuthMethods: GLOBAL_PAYMENT_GOOGLE_PAY_CONFIGURATION_CONSTANTS?.ALLOWED_AUTH_METHODS,
          allowedCardNetworks: GLOBAL_PAYMENT_GOOGLE_PAY_CONFIGURATION_CONSTANTS?.ALLOWED_GOOGLEPAY_CARD_NETWORKS,
          buttonColor: GLOBAL_PAYMENT_GOOGLE_PAY_CONFIGURATION_CONSTANTS?.BUTTON_STYLE_COLOR,
          buttonType: GLOBAL_PAYMENT_GOOGLE_PAY_CONFIGURATION_CONSTANTS?.BUTTON_TYPE,
          merchantId: config(GLOBAL_PAYMENTS_GOOGLE_PAY_MERCHANT_ID),
          globalPaymentsClientID: config(GLOBAL_PAYMENTS_GOOGLE_PAY_CLIENT_ID),
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.globalPaymentsAccesstoken]);

  useEffect(() => {
    updateGlobalPaymentGooglePayForm({ ...props });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.finalAmount]);

  return <></>;
};

export default GlobalPaymentGooglePayForm;
