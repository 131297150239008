import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DATE_FORMATS } from '../constants/SharedConstants';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isToday from 'dayjs/plugin/isToday';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import { days_data } from 'common/constants/SharedConstants';
import { compose } from 'common/utilities/FunctionUtils';

dayjs.extend(duration);
dayjs.extend(advancedFormat);
dayjs.extend(LocalizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isTomorrow);
dayjs.extend(isToday);
dayjs.extend(isLeapYear);

export const getFormatFromUnix = (time, format) => dayjs.unix(time).format(format);

export const formatDateTime = (time, format) => dayjs(time).format(format);

export const currentDateTimeInFormat = format => dayjs().format(format);

export const getCurrentDateTimeInUnix = () => dayjs().unix();

export const getCurrentDateTimeWithTimeZoneInUnix = timezoneData =>
  timezoneData ? dayjs().tz(timezoneData).unix() : getCurrentDateTimeInUnix();

export const now = () => dayjs();

export const getCurrentDateTime = () => dayjs(new Date());

export const parseInvalidDate = (value, format) => dayjs(value, format);

export const parseInvalidDateAndFormat = (val, inputFormat, outputFormat) =>
  dayjs(val, inputFormat).format(outputFormat);

export const getTimeInUnixMilliseconds = () => dayjs().valueOf();

export const getDayJsInstance = value => dayjs(value);

export const getUnixTimeInSeconds = time => dayjs.unix(time);

export const getEpochTime = (time, format) => dayjs(time, format).unix();

export const getEpochTimeonTimeZone = (dateTime, timezoneData) =>
  dateTime && timezoneData ? dayjs.tz(dateTime, timezoneData).unix() : getCurrentDateTimeInUnix();

export const getDateTimeWithTimeZone = (dateTime, timezoneData) =>
  dateTime && timezoneData ? dayjs.tz(dateTime, timezoneData).format() : currentDateTimeInFormat();

export const getCurrentEpochTimeonTimeZone = timezoneData =>
  timezoneData ? dayjs().tz(timezoneData).unix() : getCurrentDateTimeInUnix();

export const getUTCEpochTime = (dateTime, keepLocalTime = false) => dayjs(dateTime).utc(keepLocalTime).unix();

export const getUTCDateTimeFormat = (dateTime, keepLocalTime = false) => dayjs(dateTime).utc(keepLocalTime).format();

export const getDurationWithDiffInHours = time => dayjs.duration(dayjs().diff(dayjs(time), 'ms')).asHours();

export const getDuration = (diff, unit) => dayjs.duration(diff, unit);

export const getTimeDifference = (current, end, unit) => current?.diff(end, unit);

export const compareDateTime = (dateOne, dateTwo, format) => {
  if (dateTwo === 'ASAP') {
    return false;
  } else if (dateTwo === '-') {
    dateTwo = getCurrentDateTime();
  }
  let dateOneDayjs = dayjs(dateOne, format);
  let dateTwoDayjs = dayjs(dateTwo, format);
  return dayjs(dateOneDayjs).isAfter(dateTwoDayjs);
};

export const dateIsBetween = (dateOne, dateTwo, dateThree) => dayjs(dateOne).isBetween(dateTwo, dateThree);

export const addTime = (time, addTimeVal, format) => dayjs(time).add(addTimeVal, format);

export const addDay = (day, timezoneData) =>
  day && timezoneData ? dayjs().tz(timezoneData).add(day, DATE_FORMATS.day) : getCurrentDateTimeInUnix();

export const formatWithInstance = (dayjsInstance, format) => dayjsInstance.format(format);

export const isDateSame = (dateOne, dateTwo) => dayjs.unix(dateOne).isSame(dateTwo);

export const getDate = (weekDay, format = 'Do') => {
  const day = days_data.indexOf(weekDay);
  return dayjs().day(day).format(format);
};

export const getDayDate = () => {
  return dayjs().format('DD');
};

export const getTimeString = time => {
  const endTime = getUnixTimeInSeconds(time);
  const currTime = getCurrentDateTime();
  const hourDiff = getTimeDifference(endTime, currTime, 'hour');
  const addedHour = currTime.add(hourDiff, 'hours');
  const minDiff = getTimeDifference(endTime, addedHour, 'minute');
  const addedMinute = addedHour.add(minDiff, 'minutes');
  const secDiff = getTimeDifference(endTime, addedMinute, 'second');
  return hourDiff < 0 || minDiff < 0 || addedMinute < 0 || secDiff < 0 ? false : true;
};

export const getTimeDifferenceInSeconds = time => dayjs(new Date()).diff(getUnixTimeInSeconds(time), 'second');

export const getDayFromDate = (val, inputFormat) => dayjs(val, inputFormat).day();

export const getTimeStampFromUnixWithTimeZone = (timestamp, timezoneData) =>
  timestamp && timezoneData ? dayjs.unix(timestamp).tz(timezoneData) : getUnixTimeInSeconds(timestamp);

export const getFormattedFromUnixWithTimeZone = (
  timestamp,
  timeZone,
  format = DATE_FORMATS.ddd_DD_MM_YYYY_hh_mm_ss_A,
) => getTimeStampFromUnixWithTimeZone(timestamp, timeZone).format(format);

export const getFormattedTimeWithTimeZone = (timeZone, format = DATE_FORMATS.HHmm) =>
  dayjs().tz(timeZone).format(format);

export const getStoreCurrentTimeAndDate = timeZone => dayjs().tz(timeZone);

export const convertTwentyFourHrToTwelveHr = (hour, min, format = DATE_FORMATS.hh_mm_A, epoctime = '') =>
  epoctime ? dayjs(epoctime).hour(hour).minute(min).format(format) : dayjs().hour(hour).minute(min).format(format);

export const subtractMin = (hour, min, subtractNo, format = DATE_FORMATS.HHmm) =>
  dayjs().hour(hour).minute(min).subtract(subtractNo, DATE_FORMATS.minutes).format(format);

export const getDayFromTime = (hour, min) => dayjs().hour(hour).minute(min);

export const subtractMinuteDate = (hour, min, subtractNo) =>
  dayjs().hour(hour).minute(min).subtract(subtractNo, DATE_FORMATS.minutes);

export const isTomorrowDate = param => dayjs(param).isTomorrow();
export const checkIsToday = (timeStamp, timezoneData) => dayjs(timeStamp).tz(timezoneData).isToday();

export const monthNameToNumber = monthName => {
  const year = new Date().getFullYear();
  return new Date(`${monthName} 1, ${year}`).getMonth() + 1;
};

export const getUnix = (dateTime = '', inUtc = false) => {
  const dateObj = dateTime ? dayjs.unix(dateTime) : dayjs();
  if (inUtc) {
    dayjs.extend(utc);
    return dateObj.utc();
  }
  return dateObj;
};

export const stringToTime = (time = '') => {
  // transforms a 4 digit string to a time string
  // ex: 1000 -> 10:00
  if (!time) {
    return '';
  }

  return time.slice(0, 2) + ':' + time.slice(2);
};

const getPaddedTime = timeObj => {
  if (!timeObj) {
    return '';
  }

  const { hours, minutes } = timeObj;

  const paddedHours = String(hours).padStart(2, '0').trim();
  const paddedMinutes = String(minutes).padStart(2, '0').trim();

  return `${paddedHours}:${paddedMinutes}`;
};

const stringTo24hObject = (timeString = '') => {
  if (!timeString) {
    return null;
  }

  // Convert 12-hour time format to 24-hour time format
  const [time, period] = timeString.split(/(?=[AP]M)/i);
  const [hours, minutes] = time.split(':');

  const normalizedHours = parseInt(hours, 10);

  // No AM/PM in string
  if (!period) {
    return { hours: normalizedHours, minutes };
  }

  if (period.toLowerCase() === 'pm') {
    const hoursIn24Format = (normalizedHours % 12) + 12;

    return { hours: hoursIn24Format, minutes };
  } else if (normalizedHours === 12) {
    return { hours: 0, minutes };
  } else {
    return { hours: normalizedHours, minutes };
  }
};

export const to24hTimeFormat = compose(getPaddedTime, stringTo24hObject);
