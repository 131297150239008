import {
  getPaymentRequestedData,
  dgftCreditCardGenerateToken,
  dgftAuthorizePayment,
  giftCardBalance,
  getDgftSavedCardDetails,
  deleteDgftCardDetails,
} from '@kfc-global/react-shared/redux/Actions/PaymentsAction';
import { getLanguage } from '@kfc-global/kfc-i18n/lib/i18N';
import { PAYMENT_TENDER_TYPES, PAYMENT_STATUS } from 'common/constants/SharedConstants';
import { redirectToPaymentFailurePage, toastifyFn } from 'common/utilities/utils';
import { getCardType } from 'common/utilities/PaymentUtils';
import React from 'react';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { validateToDisplayTender } from 'organisms/Payment/presentation/templates/PaymentViewTemplates.js';

export const cartDataFields = data => {
  return data?.map((key, index) => {
    return (
      <>
        <input type='hidden' name={`name[${index}]`} value={`${key.item.name}`}></input>
        <input type='hidden' name={`quantity[${index}]`} value={`${key.quantity}`}></input>
        <input type='hidden' name={`price[${index}]`} value={`${key.amount / 100}`}></input>
      </>
    );
  });
};

export const dispatchPaymentRequest = props => {
  const { checkoutDetails, updateCheckoutDetails } = props;
  const { dispatch } = checkoutDetails;
  if (checkoutDetails?.isClickPlaceOrder && checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.DGFT_CREDIT_CARD) {
    dispatch(
      getPaymentRequestedData(
        {
          tenantId: checkoutDetails?.tenantId,
          data: {
            orderId: checkoutDetails?.orderId,
            tenderType: checkoutDetails?.tender?.name,
          },
        },
        param => {
          updateCheckoutDetails({ DgftPaymentId: param?.paymentId, DgftTokenApiKey: param?.tokenApiKey });
          !checkoutDetails?.cardid
            ? dispatchDgftTokenGeneration(props, param)
            : dispatchAuthorizePayment(props, param?.paymentId, param?.tokenApiKey);
        },
        () => {
          redirectToPaymentFailurePage({ ...props });
        },
      ),
    );
  } else if (
    checkoutDetails?.isClickAddPayment &&
    checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.DGFT_APPLEPAY
  ) {
    dispatch(
      getPaymentRequestedData(
        {
          tenantId: checkoutDetails?.tenantId,
          data: {
            orderId: checkoutDetails?.orderId,
            tenderType: checkoutDetails?.tender?.name,
          },
        },
        param => {
          updateCheckoutDetails({ DgftPaymentId: param?.paymentId, DgftTransactionId: param?.transactionId });
        },
      ),
    );
  } else {
    dispatch(
      getPaymentRequestedData({
        tenantId: checkoutDetails?.tenantId,
        data: {
          orderId: checkoutDetails?.orderId,
          tenderType: checkoutDetails?.tender?.name,
        },
      }),
    );
  }
};

export const dispatchDgftTokenGeneration = (props, param) => {
  const { checkoutDetails, updateCheckoutDetails } = props;
  const { dispatch } = checkoutDetails;
  checkoutDetails?.isClickAddPayment &&
    dispatch(
      dgftCreditCardGenerateToken(
        {
          data: {
            card_number: checkoutDetails?.paymentCardDetails?.cardNumber?.replace(/ /g, ''),
            card_expire: checkoutDetails?.paymentCardDetails?.cardExpDate?.replace(/ /g, ''),
            security_code: checkoutDetails?.paymentCardDetails?.cardCVV,
            cardholder_name: checkoutDetails?.paymentCardDetails?.cardHolderName,
            token_api_key: param?.tokenApiKey,
            lang: getLanguage?.()?.replace(/-JP/g, ''),
          },
        },
        response => {
          updateCheckoutDetails({ DgftTokenApiKey: response?.token });
          dispatchAuthorizePayment(props, param?.paymentId, response?.token);
        },
        () => {
          redirectToPaymentFailurePage({ ...props });
        },
      ),
    );
};

export const dispatchAuthorizePayment = (props, paymentId = null, tokenApiKey = null) => {
  const { checkoutDetails, updateCheckoutDetails } = props;
  const { dispatch } = checkoutDetails;
  dispatch(
    dgftAuthorizePayment(
      {
        tenantId: checkoutDetails?.tenantId,
        paymentType: PAYMENT_TENDER_TYPES?.CREDIT_CARD,
        data: {
          orderId: checkoutDetails?.orderId,
          PaymentRefNumber: paymentId,
          token: !checkoutDetails?.cardid ? tokenApiKey : null,
          total: checkoutDetails?.finalAmount,
          Accept: 'application/json',
          UserAgent: window?.navigator?.userAgent,
          accountId: checkoutDetails?.cardid ? checkoutDetails?.dgftAccountId : null,
          cardId: checkoutDetails?.cardid ? checkoutDetails?.cardid : null,
        },
      },
      response => {
        response?.status === PAYMENT_STATUS?.SUCCESS.toLowerCase()
          ? updateCheckoutDetails({
              DgftModalJs: /<SCRIPT.*?>([\s\S]*)<\/SCRIPT>/?.exec(
                response?.resResponseContents?.replace(/[\r\n]+/g, ''),
              ),
              DgftModalOpen: true,
              DgftresHtml: response?.resResponseContents?.replace(/[\r\n]+/g, ''),
            })
          : redirectToPaymentFailurePage({ ...props });
      },
      () => {
        redirectToPaymentFailurePage({ ...props });
      },
    ),
  );
};

export const dispatchGiftCardBalanceRequest = props => {
  const { checkoutDetails } = props;
  checkoutDetails?.dispatch(
    giftCardBalance({
      tenantId: checkoutDetails?.tenantId,
    }),
  );
};

export const updateCheckoutDetailsWithPaymentReducerForKFCcard = props => {
  const { checkoutDetails, updateCheckoutDetails, paymentsReducer, finalAmount } = props;
  if (checkoutDetails?.userParams?.loggedIn && paymentsReducer?.giftCardbalanceResponse) {
    updateCheckoutDetails({
      getGiftCardBalance: paymentsReducer?.giftCardbalanceResponse?.cardBalance,
      giftCardStatus: paymentsReducer?.giftCardbalanceResponse?.cardStatus,
      finalGiftCardValue: finalAmount,
    });
  }
};
export const dispatchGetSavedCardDetails = props => {
  const { checkoutDetails, updateCheckoutDetails } = props;
  const { dispatch } = checkoutDetails;
  dispatch(
    getDgftSavedCardDetails(
      {
        tenantId: checkoutDetails?.tenantId,
        customerId: checkoutDetails?.customerId,
      },
      response => {
        response.length > 0
          ? updateCheckoutDetails({
              savedCards: savedCardResponseFormatted(response),
              isPaymentTypeSelected: true,
              cardid: response[0]?.cardId,
            })
          : updateCheckoutDetails({
              savedCards: response,
            });
      },
    ),
  );
};

export const savedCardResponseFormatted = response => {
  let newResponse = [];
  response.forEach((item, index) => {
    newResponse.push({
      expiryDate: item?.cardExpire,
      id: item?.cardId,
      maskedCardNumber: item?.cardNumber?.replace(/[*]/g, ' • '),
      type: getCardType(item?.cardNumber)?.replace(/[- ]/g, ''),
      isDefaultCard: index === 0,
      dgftAccountId: item?.accountId,
    });
  });
  return newResponse;
};

export const dispatchDeleteDgftCardDetails = (checkoutDetails, updateCheckoutDetails) => {
  const { customerId, tenantId, dispatch } = checkoutDetails;
  updateCheckoutDetails({
    deleteCardModal: false,
  });
  dispatch(
    deleteDgftCardDetails(
      {
        customerId: customerId,
        cardId: checkoutDetails?.deleteCardId,
        tenantId: tenantId,
      },
      response => {
        if (response?.mstatus === PAYMENT_STATUS?.SUCCESS.toLowerCase()) {
          updateCheckoutDetails({
            isPaymentTypeSelected: false,
          });
          dispatchGetSavedCardDetails({ checkoutDetails, updateCheckoutDetails });
          toastifyFn(translateWithI18Next(TRANSLATE_MAPPING_KEY?.CARD_DELETED), '', 'card-deleted-toast');
        } else {
          toastifyFn(translateWithI18Next(TRANSLATE_MAPPING_KEY?.CARD_DELETED_FAILED), '', 'card-deleted-toast');
        }
      },
      () => {
        toastifyFn(translateWithI18Next(TRANSLATE_MAPPING_KEY?.CARD_DELETED_FAILED), '', 'card-deleted-toast');
      },
    ),
  );
};

export const updateCheckoutDetailsForRenderCanalFromRNA = props => {
  const { checkoutDetails, updateCheckoutDetails } = props;
  checkoutDetails?.isRenderFromRNA &&
    checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.CANAL &&
    updateCheckoutDetails({
      isClickAddPayment: true,
      //  showPlaceOrderButton: false,
      isClickContinuePayment: false,
    });
};

export const getsavedCardId = checkoutDetails => {
  const { savedCards, lastUsedPaymentMethod } = checkoutDetails;
  return savedCards?.length > 0 && lastUsedPaymentMethod === PAYMENT_TENDER_TYPES?.DGFT_CREDIT_CARD
    ? savedCards[0]?.id
    : null;
};

export const checklastUsedPaymentMethodInDisplayTenders = checkoutDetails => {
  const { lastUsedPaymentMethod = '', allowedTenders = [] } = checkoutDetails;
  const validateToDisplayTenderList = validateToDisplayTender(allowedTenders, checkoutDetails);
  return validateToDisplayTenderList?.includes(lastUsedPaymentMethod);
};
