import START_ORDER_CONSTANTS from 'organisms/StartOrderComponent/Constants/startOrderConstants';
import {
  ROUTE_URL,
  MENU,
  DYNAMIC_MENU_CONSTANTS,
  CART,
  LOCAL_STORAGE_KEYS,
  DATE_FORMATS,
} from 'common/constants/SharedConstants';
import {
  getFormattedTimeWithTimeZone,
  getTimeDifference,
  getCurrentDateTimeWithTimeZoneInUnix,
} from 'common/utilities/dayJsUtils';
import isEmpty from 'lodash/isEmpty';
import { getTimeSpecificAlertContent } from 'common/utilities/content';
import dayjs from 'dayjs';
import { getStoreDetails } from 'organisms/HeaderComponent/AU/HeaderUtilities/HeaderUtils';
import { getClosestAvailableTimeSlot } from 'common/utilities/utils';

export const updateTimebasedCategory = (categoryObj, userOrderStateDispatch) => {
  localStorage.setItem(START_ORDER_CONSTANTS.TIME_BASED_CETEGORY, JSON.stringify(categoryObj));
  setCategoryCurrentStatus(categoryObj, userOrderStateDispatch);
};

export const getUpdateTimeBasedCategory = (updatedTimeBasedCategory, cartReducer, userOrderStateDispatch) => {
  const foodLinesItems = cartReducer?.cartData?.foodLines || [];
  let timebasedItemInCart = false;
  if (updatedTimeBasedCategory) {
    timebasedItemInCart = checkTimeSpecificItem(foodLinesItems, updatedTimeBasedCategory);
  }
  updateTimebasedCategory({ ...updatedTimeBasedCategory, timebasedItemInCart, foodLinesItems }, userOrderStateDispatch);
};

export const setTimeBasedCategoryAsNotTimeExpired = (timeBasedCetegory, userOrderStateDispatch) => {
  if (timeBasedCetegory && !timeBasedCetegory?.isTimeExpired) {
    updateTimebasedCategory(timeBasedCetegory, userOrderStateDispatch);
  }
};

export const getRedirectPage = (pathnameToNavigate, history) => {
  if (history.location.pathname === ROUTE_URL.MENU_PAGE && pathnameToNavigate === ROUTE_URL.MENU_PAGE) {
    window.location.reload();
  }
  history.push({
    pathname: pathnameToNavigate,
  });
};

export const checkTimeSpecificItem = (foodLines, timeBasedCetegory) => {
  return foodLines?.some(
    foodItem =>
      foodItem?.item?.categoryUrl === timeBasedCetegory?.url || foodItem?.item?.categoryId === timeBasedCetegory?.id,
  );
};
export const getCategoryUrl = (itemId, flatResponse) => {
  return flatResponse?.itemMapById?.[itemId]?.categoryUrl?.[0];
};

export const getTimeSpecificCategoryUrl = (foodItem, timeBasedCetegory) => {
  return foodItem?.categoryUrl === timeBasedCetegory?.url ? timeBasedCetegory?.url : '';
};

export const checkIteminCart = (itemId, timeBasedCategory) =>
  timeBasedCategory?.foodLinesItems?.filter?.(foodItem => foodItem?.item?.id === itemId)?.length > 0;

export const getSupportContentAlertData = supportContentAlertData => {
  return supportContentAlertData
    ? supportContentAlertData
    : getObjectFromLocalStorage(START_ORDER_CONSTANTS.TIME_BASED_CETEGORY).supportContentAlertData;
};
export const setAlertDataContent = (category, alertModel, userOrderStateDispatch) => {
  const fieldData = getSupportContentAlertData(category?.supportContentAlertData)?.filter(
    field => field?.fields?.title === alertModel,
  );
  if (fieldData?.length) {
    const alertData = fieldData?.[0]?.fields;
    alertData.flag = true;
    alertData.showCrossIcon = false;
    alertData.buttonText = alertData?.cta?.fields?.buttonText;
    alertData.contentImg = category?.itemImg ?? alertData?.image?.fields?.file?.url;
    alertData.pathnameToNavigate = null;
    alertData.cName = category?.itemName ?? category.name;
    if (
      alertModel === START_ORDER_CONSTANTS?.CART_REVIEW_ORDER_MODAL ||
      alertModel === START_ORDER_CONSTANTS?.REVIEW_ORDER_MODAL
    ) {
      alertData.showCrossIcon = true;
      alertData.alertShowed = true;
      alertData.pathnameToNavigate = ROUTE_URL.CART;
      category.pathnameToNavigate = ROUTE_URL.CART;
      if (alertModel === START_ORDER_CONSTANTS?.CART_REVIEW_ORDER_MODAL) {
        alertData.contentImg = null;
      }
    } else if (
      alertModel === START_ORDER_CONSTANTS?.MENU_CHANGE_MODAL ||
      alertModel === START_ORDER_CONSTANTS.PDP_UNAVAILABLE_MODAL
    ) {
      alertData.pathnameToNavigate = ROUTE_URL.MENU_PAGE;
      category.pathnameToNavigate = ROUTE_URL.MENU_PAGE;
    }
    userOrderStateDispatch({
      type: START_ORDER_CONSTANTS?.UPDATE_TIMEBASED_MENU_ALERT,
      value: alertData,
    });
  }
};

export const showTimeSpecificMessage = (category, userOrderStateDispatch) => {
  let alertModel = category.alertType || START_ORDER_CONSTANTS?.MENU_CHANGE_MODAL;
  if (category?.timebasedItemInCart && category?.itemInCart) {
    alertModel = category?.alertType ?? START_ORDER_CONSTANTS?.REVIEW_ORDER_MODAL;
  }
  setAlertDataContent(category, alertModel, userOrderStateDispatch);
};

export const getCheckItemExpiry = checkoutDetails => {
  if (checkoutDetails?.isClickAddPayment) {
    const timeBasedCetegory = getObjectFromLocalStorage(START_ORDER_CONSTANTS.TIME_BASED_CETEGORY);
    const timebasedItemInCart = checkTimeSpecificItem(checkoutDetails?.foodLines, timeBasedCetegory);
    if (timebasedItemInCart) {
      const checkExiryItem = checkCategoryAvailability(
        {
          categoryUrl: timeBasedCetegory?.url,
          alertType: START_ORDER_CONSTANTS?.CART_REVIEW_ORDER_MODAL,
          itemInCart: true,
        },
        timeBasedCetegory,
        checkoutDetails?.userOrderStateDispatch,
      );
      if (checkExiryItem) {
        return true;
      }
    } else {
      return false;
    }
  }
};
export const checkCategoryAvailability = (category, timeBasedCategory, userOrderStateDispatch) => {
  const item = { categoryName: timeBasedCategory?.name };
  const getStoreDetail = timeBasedCategory?.getStoreDetail;
  const menuReducer = { ...timeBasedCategory?.menuData, timeSpecificResponse: [timeBasedCategory] };
  const checkTimeSpecificCategoryAvailability = getCheckTimeSpecificCategoryAvailability({
    item,
    getStoreDetail,
    menuReducer,
    showModal: false,
  });
  const checkTimeSpecificItem1 =
    timeBasedCategory?.url && category?.categoryUrl && timeBasedCategory?.url === category?.categoryUrl;
  const checkTimeSpecificItemID =
    timeBasedCategory?.id && category?.categoryId && timeBasedCategory?.id === category?.categoryId;

  const checkItemAvailability = checkAvailabiltyItemExpiry(
    timeBasedCategory?.menuData,
    getStoreDetail,
    category?.itemId,
  );
  const isScheduledOrder = checkScheduledDateTime(getStoreDetail?.timeZone);
  if (isScheduledOrder) {
    return false;
  }
  if (
    (checkTimeSpecificItem1 || checkTimeSpecificItemID) &&
    (timeBasedCategory?.isTimeExpired ||
      localStorage.getItem(START_ORDER_CONSTANTS.TIME_EQUAL_EXPIRED) ||
      checkTimeSpecificCategoryAvailability)
  ) {
    const itemInCart = category?.itemInCart ?? false;
    const alertType = category?.alertType ?? false;
    showTimeSpecificMessage({ ...timeBasedCategory, itemInCart, alertType }, userOrderStateDispatch);
    return true;
  } else if (checkItemAvailability) {
    const timebasedItemInCart = checkIteminCart(category?.itemId, timeBasedCategory);
    showTimeSpecificMessage(
      {
        ...timeBasedCategory,
        timebasedItemInCart,
        alertType: getDynamicMenuAlertType(timebasedItemInCart, DYNAMIC_MENU_CONSTANTS?.ITEM),
      },
      userOrderStateDispatch,
    );
    return true;
  }
  return false;
};

export const isMenuPage = () => {
  const pageParams = window?.location?.pathname?.split('/') || '/';
  pageParams.shift();
  return (pageParams.length === 2 || pageParams.length === 1) && pageParams.includes(MENU);
};

export const getAlertModalForEndTime = (category, userOrderStateDispatch) => {
  if (!category.isTimeEqual && !category?.timebasedItemInCart) {
    category.isTimeEqual = true;
    const showModal = (!category?.timebasedItemInCart && isMenuPage()) || category?.timebasedItemInCart;
    showModal &&
      showTimeSpecificMessage({ ...category, itemInCart: category?.timebasedItemInCart }, userOrderStateDispatch);
  } else if (category?.timebasedItemInCart && !category?.isAlert5Showed) {
    category.isAlert5Showed = true;
    category?.timebasedItemInCart &&
      showTimeSpecificMessage({ ...category, itemInCart: category?.timebasedItemInCart }, userOrderStateDispatch);
  }
};

export const getTimeDifferenceForTimeSpecificCategory = (endTime, currentT) => {
  return getTimeDifference(dayjs(`2000-01-01 ${endTime}`), dayjs(`2000-01-01 ${currentT}`), 'minutes');
};
export const getDynamicMenuAlert = (category, endTime, currentT, userOrderStateDispatch) => {
  category.isTimeExpired = false;
  localStorage.removeItem(START_ORDER_CONSTANTS.TIME_EQUAL_EXPIRED);
  if (+currentT > +endTime && endTime != null) {
    category.isTimeExpired = true;
    category.isTimeExpiredAndEqual = true;
    localStorage.setItem(START_ORDER_CONSTANTS.TIME_EQUAL_EXPIRED, true);
  } else if (+currentT === +endTime) {
    category.isTimeExpired = true;
    category.isTimeExpiredAndEqual = true;
    localStorage.setItem(START_ORDER_CONSTANTS.TIME_EQUAL_EXPIRED, true);
    getAlertModalForEndTime(category, userOrderStateDispatch);
  } else {
    const timeDiff = getTimeDifferenceForTimeSpecificCategory(endTime, currentT);
    category.timeDifference = timeDiff;
    if (timeDiff === DYNAMIC_MENU_CONSTANTS?.AlERT_0MIN) {
      category.isTimeExpired = true;
      category.isTimeExpiredAndEqual = true;
      localStorage.setItem(START_ORDER_CONSTANTS.TIME_EQUAL_EXPIRED, true);
    }
    if (timeDiff === DYNAMIC_MENU_CONSTANTS?.ALERT_15MINS && category?.timebasedItemInCart && !category.isAlert1) {
      showTimeSpecificMessage(
        { ...category, alertType: START_ORDER_CONSTANTS?.MENU_CHANGE_WARNING_MODAL },
        userOrderStateDispatch,
      );
    }
  }
};
export const setCategoryCurrentStatus = (category, userOrderStateDispatch) => {
  const isScheduledOrder = checkScheduledDateTime(category?.getStoreDetail?.timeZone);
  if (category && !isScheduledOrder) {
    const availability = getClosestAvailableTimeSlot(category?.availability, category?.getStoreDetail?.timeZone);
    const endTime = availability?.availableHours?.endTime?.time ?? null;
    const currentT = getFormattedTimeWithTimeZone(category?.getStoreDetail?.timeZone);
    getDynamicMenuAlert(category, endTime, currentT, userOrderStateDispatch);
  }
  userOrderStateDispatch({
    type: START_ORDER_CONSTANTS?.UPDATE_TIMEBASED_MENU_DATA,
    value: category || { isTimeExpired: true },
  });
};

export const getAlertType = itemInCart => {
  return itemInCart ? START_ORDER_CONSTANTS?.CART_REVIEW_ORDER_MODAL : START_ORDER_CONSTANTS?.PDP_UNAVAILABLE_MODAL;
};

export const getDynamicMenuAlertType = (timebasedItemInCart, isTimeSpecificClick) => {
  switch (isTimeSpecificClick) {
    case DYNAMIC_MENU_CONSTANTS?.CATEGORY:
      return timebasedItemInCart ? START_ORDER_CONSTANTS?.REVIEW_ORDER_MODAL : START_ORDER_CONSTANTS?.MENU_CHANGE_MODAL;
    case DYNAMIC_MENU_CONSTANTS?.ITEM:
      return timebasedItemInCart
        ? START_ORDER_CONSTANTS?.REVIEW_ORDER_MODAL
        : START_ORDER_CONSTANTS?.PDP_UNAVAILABLE_MODAL;
    default:
      return '';
  }
};

export const getObjectFromLocalStorage = storageName => {
  return localStorage.getItem(storageName) ? JSON.parse(localStorage.getItem(storageName)) : {};
};

export const getUpdateTimeBasedMenuData = (isCart, timeBasedCetegory, userOrderStateDispatch) => {
  if (isCart) {
    userOrderStateDispatch({
      type: START_ORDER_CONSTANTS?.UPDATE_TIMEBASED_MENU_DATA,
      value: { ...timeBasedCetegory, isAlert5: true, isAlert7: true },
    });
  }
};

export const getAvailableTimeBasedCategory = (categories, getStoreDetail) => {
  const result = categories.filter(cate => {
    if (cate?.isTimeSpecific) {
      const availability = getClosestAvailableTimeSlot(cate?.availability, getStoreDetail?.timeZone);
      const endTime = availability?.availableHours?.endTime?.time;
      const currentTime = getFormattedTimeWithTimeZone(getStoreDetail?.timeZone);
      const startTime = availability?.availableHours?.startTime?.time;
      return cate && currentTime > startTime && currentTime < endTime;
    } else {
      return null;
    }
  });
  return result || [];
};

export const getTimeSpecificCategories = (categories, getStoreDetail) => {
  if (!isEmpty(categories)) {
    const timeSpecificCategory = getAvailableTimeBasedCategory(categories, getStoreDetail) || [];
    if (!isEmpty(timeSpecificCategory)) {
      localStorage.setItem(DYNAMIC_MENU_CONSTANTS?.TIME_SPECIFIC_CATEGORY, JSON.stringify(timeSpecificCategory));
    }
    return timeSpecificCategory;
  } else {
    return getObjectFromLocalStorage(DYNAMIC_MENU_CONSTANTS?.TIME_SPECIFIC_CATEGORY);
  }
};

export const updateTimeBasedCategoryDetailsWithMenuReducer = params => {
  const { menuReducer, userParams, dispatch, getStoreDetail, userOrderStateDispatch, timeBasedCetegory, cartReducer } =
    params;
  if (!isEmpty(menuReducer) && userParams?.localized) {
    const categories = menuReducer?.flatResponse?.categories || [];
    const category = getTimeSpecificCategories(categories, getStoreDetail);
    const checkTimeSpecificItems = menuReducer?.timeSpecificItemResponse;
    const timeBasedCategoryInStorage = getObjectFromLocalStorage(START_ORDER_CONSTANTS.TIME_BASED_CETEGORY);
    if (category?.length || checkTimeSpecificItems?.length) {
      dispatch(getTimeSpecificAlertContent());
      if (timeBasedCetegory?.timeDifference) {
        getUpdateTimeBasedCategory(timeBasedCetegory, cartReducer, userOrderStateDispatch);
      }
      if (timeBasedCategoryInStorage?.timeDifference && category?.[0]?.id !== timeBasedCategoryInStorage?.id) {
        getUpdateTimeBasedCategory(timeBasedCategoryInStorage, cartReducer, userOrderStateDispatch);
      } else {
        getUpdateTimeBasedCategory({ ...category?.[0], getStoreDetail }, cartReducer, userOrderStateDispatch);
      }
    }
  }
};

export const getTimeSpecificCategoryNameForOrderNow = OrderNowItem => {
  if (OrderNowItem.categoryLinkItem) {
    return DYNAMIC_MENU_CONSTANTS?.CATEGORY;
  } else if (OrderNowItem.pdpLinkItem) {
    return DYNAMIC_MENU_CONSTANTS?.ITEM;
  }
  return DYNAMIC_MENU_CONSTANTS?.CATEGORY;
};

export const onClickTimeSpecificBtnHandler = ({
  timeBasedAlertModalData,
  userOrderStateDispatch,
  timeBasedCetegory,
  history,
}) => {
  if (timeBasedAlertModalData?.pathnameToNavigate) {
    getUpdateTimeBasedMenuData(
      timeBasedAlertModalData.pathnameToNavigate.includes(CART),
      timeBasedCetegory,
      userOrderStateDispatch,
    );
    getRedirectPage(timeBasedAlertModalData?.pathnameToNavigate, history);
  } else {
    userOrderStateDispatch({
      type: START_ORDER_CONSTANTS?.UPDATE_TIMEBASED_MENU_DATA,
      value: { ...timeBasedCetegory, isAlert1: true },
    });
  }
  userOrderStateDispatch({
    type: START_ORDER_CONSTANTS?.UPDATE_TIMEBASED_MENU_ALERT,
    value: {
      ...timeBasedAlertModalData,
      flag: false,
      isReviewOrderClick: true,
      isBrowseMenuOrReviewOrderCTAClicked: true,
      isCloseButtonClicked: false,
    },
  });
};

export const onClickTimeSpecificCloseBtnHandler = (userOrderStateDispatch, timeBasedAlertModalData) => {
  userOrderStateDispatch({
    type: START_ORDER_CONSTANTS?.UPDATE_TIMEBASED_MENU_ALERT,
    value: {
      ...timeBasedAlertModalData,
      flag: false,
      dynamicMenuAlertMessage: timeBasedAlertModalData?.title === START_ORDER_CONSTANTS?.CART_REVIEW_ORDER_MODAL,
      isCloseButtonClicked: true,
      isBrowseMenuOrReviewOrderCTAClicked: false,
    },
  });
};

export const updateAlertModalData = (timeBasedAlertModalData, userOrderStateDispatch) => {
  userOrderStateDispatch({
    type: START_ORDER_CONSTANTS?.UPDATE_TIMEBASED_MENU_ALERT,
    value: {
      ...timeBasedAlertModalData,
      isReviewOrderClick: false,
      isBrowseMenuOrReviewOrderCTAClicked: false,
      isCloseButtonClicked: false,
    },
  });
};

export const checkScheduledDateTime = timeZone => {
  const scheduleTime = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS?.DATE_TIME_ORDER));
  const currentEpocTime = getCurrentDateTimeWithTimeZoneInUnix(timeZone);
  return scheduleTime?.epochTime > currentEpocTime;
};

export const convertTimeTo24Zone = (currentT, timeZone) => {
  const scheduleTime = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS?.DATE_TIME_ORDER));
  if (scheduleTime) {
    const [time, modifier] = scheduleTime?.selectedTime?.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === DATE_FORMATS?.TWELVE_TIME) {
      hours = DATE_FORMATS?.ZERO_HOURS;
    }
    if (hours?.length == 1) {
      hours = '0' + hours;
    }
    if (minutes?.length == 1) {
      minutes = '0' + minutes;
    }
    if (modifier === DATE_FORMATS?.PM) {
      hours = parseInt(hours, 10) + 12;
    }
    const scheduleTimeInFormat = `${hours}${minutes}`;
    if (+scheduleTimeInFormat > +currentT || checkScheduledDateTime(timeZone)) {
      currentT = scheduleTimeInFormat;
    }
  }
  return currentT;
};
export const checkTimeBasedCategoryTime = (cate, getStoreDetail) => {
  if (cate?.isTimeSpecific) {
    const availability = getClosestAvailableTimeSlot(cate?.availability, getStoreDetail?.timeZone);
    const endTime = availability?.availableHours?.endTime?.time;
    const currentTime = convertTimeTo24Zone(
      getFormattedTimeWithTimeZone(getStoreDetail?.timeZone),
      getStoreDetail?.timeZone,
    );
    const startTime = availability?.availableHours?.startTime?.time;
    return currentTime > startTime && currentTime > endTime;
  } else {
    return false;
  }
};
export const checkAvailabiltyItemExpiry = (menuReducer, getStoreDetail, itemObj) => {
  if (menuReducer?.timeSpecificItemResponse) {
    const timeSpecificItemResponse = menuReducer?.timeSpecificItemResponse;
    const timeSpecificItems = timeSpecificItemResponse?.filter(item => {
      return item?.id === itemObj;
    });
    let chekItemAvailability = false;
    if (timeSpecificItems?.length) {
      chekItemAvailability = checkTimeBasedCategoryTime(timeSpecificItems?.[0], getStoreDetail);
    }
    return chekItemAvailability;
  }
};

export const getCheckTimeSpecificCategoryAvailability = itemObj => {
  const {
    item,
    menuReducer,
    getStoreDetail,
    timeBasedCetegory,
    cartReducer,
    userOrderStateDispatch,
    showModal,
    dispatch,
    itemId,
    showCouroselAlertItem,
  } = itemObj;
  const timeSpecificResponse = menuReducer?.timeSpecificResponse;
  const timeSpecificCategory = timeSpecificResponse?.filter(cate => {
    return cate?.name?.toLowerCase() === item?.categoryName?.toLowerCase() || cate?.id === item?.categoryId;
  });

  const timeSecificItemResponse = menuReducer?.timeSpecificItemResponse;
  const timeSpecificItems = timeSecificItemResponse?.filter(filteredItem => {
    return filteredItem?.id === itemId;
  });
  if (!timeSpecificCategory?.length && !timeSpecificItems?.length) {
    return false;
  }
  const chekCatAvailability = checkTimeBasedCategoryTime(timeSpecificCategory?.[0], getStoreDetail);
  const chekItemAvailability = checkAvailabiltyItemExpiry(menuReducer, getStoreDetail, itemId);
  if ((showModal && chekCatAvailability) || chekItemAvailability) {
    if (!timeBasedCetegory?.supportContentAlertData) {
      dispatch(getTimeSpecificAlertContent());
    }
    const foodLinesItems = cartReducer?.cartData?.foodLines || [];
    const timebasedItemInCart = timeSpecificItems?.length
      ? checkIteminCart(itemId, { foodLinesItems })
      : checkTimeSpecificItem(foodLinesItems, timeSpecificCategory?.[0]);

    const pdpLinkItem = (chekItemAvailability && !chekCatAvailability) || showCouroselAlertItem;

    showTimeSpecificMessage(
      {
        ...timeSpecificCategory?.[0],
        timebasedItemInCart,
        itemInCart: timebasedItemInCart,
        alertType: getDynamicMenuAlertType(
          timebasedItemInCart,
          getTimeSpecificCategoryNameForOrderNow({ ...item, pdpLinkItem }),
        ),
      },
      userOrderStateDispatch,
    );
    return chekCatAvailability || chekItemAvailability;
  } else {
    return chekCatAvailability;
  }
};

export const getUpdateStoreDetails = storeData => {
  return storeData?.storesWithStatus && getStoreDetails(storeData?.storesWithStatus[0]);
};
