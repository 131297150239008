import { useEffect } from 'react';
import PAYMENT_CONSTANTS from 'organisms/Payment/constants/PaymentConstants';
import { cartDataFields } from '../utils/JapanPaymentUtils';

const CanalPaymenthiddenForm = props => {
  const { CANAL_PAYMENT_CONSTANTS } = PAYMENT_CONSTANTS;
  const { checkoutDetails, data } = props;
  const { cartData: { foodLines = [] } = {} } = checkoutDetails;

  const frm = document && document?.Bankfrm;
  const callbackUrl = `${data?.paymentRequestedResponse?.callbackUrl}?orderId=${data?.paymentRequestedResponse?.orderId}`;
  useEffect(() => {
    document && document?.Bankfrm?.submit();
  }, [frm, data?.paymentRequestedResponse?.postUrl]);
  return (
    <>
      {data?.paymentRequestedResponse?.postUrl && (
        <div>
          <form name='Bankfrm' method='POST' action={data?.paymentRequestedResponse?.postUrl}>
            <input TYPE='hidden' name='transNo' value={data?.paymentRequestedResponse?.paymentId} />
            <input TYPE='hidden' name='merchantCode' value={data?.paymentRequestedResponse?.merchantId} />
            <input type='hidden' name='branchCode' value={data?.paymentRequestedResponse?.storeId} />
            <input TYPE='hidden' name='amount' value={data?.paymentRequestedResponse?.transactionAmount} />
            <input TYPE='hidden' name='callbackUrl' value={callbackUrl} />
            <input TYPE='hidden' name='notifyUrl' value={data?.paymentRequestedResponse?.notifyUrl} />
            <input TYPE='hidden' name='serviceMode' value={CANAL_PAYMENT_CONSTANTS?.SERVICEMODE} />
            {cartDataFields(foodLines)}
          </form>
        </div>
      )}
    </>
  );
};

export default CanalPaymenthiddenForm;
