import React, { useState, useEffect } from 'react';
import SharedConstants from '../../common/constants/SharedConstants';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import { getTernaryValue } from 'common/utilities/utils';

const getAccordianSubHeaderText = (subHeaderTextVal, subHeaderClassVal) =>
  subHeaderTextVal ? (
    <div className={`global-accordion-subheadertext ${subHeaderClassVal}`}>{subHeaderTextVal}</div>
  ) : null;

const Accordian = props => {
  const {
    header,
    subHeader,
    onClickHandler,
    isDarkMode = false,
    isBlackArrow = false,
    cssClass = '',
    open,
    key,
    shouldDefaultOpen = false,
    dataTestId = '',
    footerActiveMenu,
    subHeaderText = '',
    subHeaderClass = '',
    additionalDetails,
  } = props;
  const [showContent, setShowContent] = useState(shouldDefaultOpen);
  const clickHander = e => {
    setShowContent(!showContent);
    onClickHandler && onClickHandler(e, additionalDetails);
  };

  useEffect(() => {
    setShowContent(open);
  }, [open]);

  useEffect(() => {
    if (footerActiveMenu !== undefined) {
      setShowContent(footerActiveMenu);
    }
  }, [footerActiveMenu]);

  const keyDownPress = e => {
    const { ENTER } = SharedConstants.KEY_CHAR_CODE;
    if (e.charCode === ENTER) {
      setShowContent(!showContent);
      onClickHandler && onClickHandler(e, additionalDetails);
    }
  };

  return (
    <div
      key={key}
      className={`global-accordian-container ${cssClass}`}
      data-testid='global-accordian'
      onKeyPress={keyDownPress}
    >
      <button type='button' aria-expanded='false' className='global-accordian-header-container'>
        <span className='global-accordian-header' onClick={clickHander} data-testid={dataTestId}>
          {header}
          {getAccordianSubHeaderText(subHeaderText, subHeaderClass)}
        </span>
        {isDarkMode ? (
          <img
            src={getTernaryValue(showContent, IMAGE_PATH.AccordianUpDark, IMAGE_PATH.AccordianDownDark)}
            alt=''
            onClick={clickHander}
            className='global-accordian-arrow'
            data-testid='acordian-handle-dark'
          />
        ) : (
          (isBlackArrow && (
            <img
              src={getTernaryValue(showContent, IMAGE_PATH.AccordianUPBlack, IMAGE_PATH.AccordianDownBlack)}
              alt=''
              onClick={clickHander}
              className='global-accordian-arrow'
              data-testid='acordian-handle-dark'
            />
          )) || (
            <img
              src={getTernaryValue(showContent, IMAGE_PATH.SingleArrowBlackExpand, IMAGE_PATH.SingleArrowBlack)}
              alt=''
              onClick={clickHander}
              className='global-accordian-arrow'
              data-testid='acordian-handle'
            />
          )
        )}
      </button>
      {subHeader && !showContent ? (
        <ul className='global-accordion-subheader cart-food-modifier'>
          {subHeader.map((mod, ind) => {
            return (
              <li>
                {mod}
                {ind === subHeader.length - 1 ? <span>...</span> : null}
              </li>
            );
          })}
        </ul>
      ) : null}
      {/* introduced new props subHeaderText (to display subheader) and  subHeaderClass(to add class to subheader) */}
      {showContent && <div className='global-accordian-content'>{props.children}</div>}
    </div>
  );
};

export default Accordian;
