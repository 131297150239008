import { CHECKOUT_CONSTANTS } from 'common/constants/SharedConstants';
import React from 'react';
import InputMask from 'react-input-mask';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';

const InputMaskComponent = props => {
  const {
    id = '',
    value,
    name,
    mask = '',
    maskChar = '',
    alwaysShowMask = false,
    placeholder = '',
    labelTestId = '',
    labelClassName = '',
    inputTestId = '',
    className = '',
    ariaRequired = '',
    autoComplete = 'off',
    onChange = null,
    onBlur = null,
    isMandatory = false,
    showClearTextInputBtn = false,
    ...rest
  } = props;

  return (
    <div className={`field material-input`}>
      <InputMask
        id={`mt-input-${id || name || placeholder}`}
        name={`mt-input-${name || placeholder}`}
        className={className}
        mask={mask}
        maskChar={maskChar}
        alwaysShowMask={alwaysShowMask}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        data-testid={inputTestId}
        aria-required={ariaRequired}
        autoComplete={autoComplete}
        {...rest}
      ></InputMask>
      <label data-testid={labelTestId} className={labelClassName} htmlFor={`mt-input-${name || placeholder}`}>
        {placeholder}
        {isMandatory && CHECKOUT_CONSTANTS.STAR}
      </label>
      {showClearTextInputBtn && (
        <img
          src={IMAGE_PATH?.clearTextIcon}
          name={`${props?.name}-clear-text-btn`}
          alt='clear-text-btn'
          data-testid='clear-text-btn'
          className='cleariconsection'
          onClick={event => props?.onClearButtonClickHandler?.({ event, name: `${props?.name}-clear-text-btn` })}
        />
      )}
    </div>
  );
};
export default InputMaskComponent;
