import React, { useEffect, useRef } from 'react';
import { loadScript } from 'common/utilities/utils';
import {
  addQRcodeFormClass,
  dispatchOrderDynamicApiRequest,
  KbankLoadFunction,
  removeQRcodeFormClass,
  removeQRcodeFormScript,
} from '../utils/ThailandPaymentUtils';
import { config } from '@kfc-global/react-shared/config/config.utils';
import { KBANK_QR_PAYMENTS_SCRIPT_URL } from '@kfc-global/react-shared/config/config.constants';
import { ROUTE_URL } from 'common/constants/SharedConstants';

const KBankPaymentQRCodeForm = props => {
  const { checkoutDetails, updateCheckoutDetails } = props;
  const qrRef = useRef(null);
  const qrFormActionUrl = window.location.origin + ROUTE_URL?.ORDER_PROCESSING;
  const KbankQRCodeFormHandling = () => {
    const kPaymentForm = window?.KPayment;
    kPaymentForm?.onClose(function (event) {
      updateCheckoutDetails({
        tender: {
          name: '',
          payload: {
            tender: '',
          },
        },
        isClickAddPayment: false,
        isClickPlaceOrder: false,
        isPaymentTypeSelected: false,
        showPlaceOrderLoading: false,
        isCateringSomeOneElseContactDetailsAreValid: true,
        placeOrderButtonEnabled: true,
        paymentEnabled: true,
        cancelledQRCode: true,
        isUpdatedOrderActionRequest: false,
        dispatchOrderAction: false,
        isCreateOrderValidateCompleted: false,
        trueMoneyWalletGeneratetOTPErrorResponse: '',
      });
      removeQRcodeFormClass();
    });
  };
  useEffect(() => {
    if (checkoutDetails?.publicTokenApiKey && checkoutDetails?.isClickPlaceOrder) {
      dispatchOrderDynamicApiRequest(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.publicTokenApiKey]);

  useEffect(() => {
    if (checkoutDetails?.qrOrderId && qrRef?.current) {
      removeQRcodeFormScript();
      loadScript(
        config(KBANK_QR_PAYMENTS_SCRIPT_URL),
        'kbankQR',
        () => {
          window?.KPayment?.create();
          window?.KPayment?.show();
          KbankLoadFunction(KbankQRCodeFormHandling);
          addQRcodeFormClass();
        },
        (script, publicTokenApiKey) => {
          const scriptAttributes = {
            'data-apikey': publicTokenApiKey,
            'data-amount': '1.00',
            'data-payment-methods': 'qr',
            'data-name': 'KFC',
            'data-order-id': checkoutDetails?.qrOrderId,
          };
          for (const [key, value] of Object.entries(scriptAttributes)) {
            script.setAttribute(key, value);
          }
        },
        checkoutDetails?.publicTokenApiKey,
        'kbankTHQR',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.qrOrderId, qrRef]);

  return (
    <>
      <form id='kbankTHQR' ref={qrRef} method='POST' action={qrFormActionUrl}></form>
    </>
  );
};
export default React.memo(KBankPaymentQRCodeForm);
