import { useEffect } from 'react';
import { config } from '@kfc-global/react-shared/config/config.utils';
import { PAYTM_POST_URL } from '@kfc-global/react-shared/config/config.constants';

function PaymentFormPayTm({ merchantId, transactionId, transactionToken }) {
  useEffect(() => {
    transactionId && document?.payTmForm?.submit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document && document?.payTmForm, transactionId]);

  return (
    <div>
      {transactionId && (
        <form
          name='payTmForm'
          method='POST'
          action={`${config(PAYTM_POST_URL)}mid=${merchantId}&orderId=${transactionId}`}
        >
          <input type='hidden' name='mid' value={merchantId} />
          <input type='hidden' name='orderId' value={transactionId} />
          <input type='hidden' name='txnToken' value={transactionToken} />
        </form>
      )}
    </div>
  );
}

export default PaymentFormPayTm;
