import { useEffect } from 'react';

function PaymentFormPhonePe({ tenderType, orderId, storeId, transactionAmount, transactionId, redirectUrl }) {
  useEffect(() => {
    redirectUrl && document?.phonepeForm?.submit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document && document?.phonepeForm, redirectUrl]);

  return (
    <div>
      {redirectUrl && (
        <form name='phonepeForm' method='POST' action={redirectUrl}>
          <table border='1'>
            <tbody>
              <input type='hidden' name='storeId' value={storeId}></input>
              <input type='hidden' name='orderId' value={orderId}></input>
              <input type='hidden' name='tenderType' value={tenderType}></input>
              <input type='hidden' name='transactionId' value={transactionId}></input>
              <input type='hidden' name='transactionAmount' value={transactionAmount}></input>
            </tbody>
          </table>
        </form>
      )}
    </div>
  );
}

export default PaymentFormPhonePe;
