/**
 * Compose multiple functions into a single function.
 *
 * @param {...Function} functions - Functions to compose.
 * @returns {Function} A composed function.
 */
export const compose = (...functions) => {
  return input => {
    return functions.reduceRight((acc, fn) => {
      return fn(acc);
    }, input);
  };
};
