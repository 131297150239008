import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  redirectToOrderProcessingPage,
  redirectToPaymentFailurePage,
  redirectToPaymentSuccessPage,
} from 'common/utilities/utils';
import Constants from '../../../components/checkout/Constants/checkoutConstants';
import { MINI_APP_CONSTANTS } from '@kfc-global/react-shared/constants';
import { ChannelManager } from 'common/manager';
import { resetValidateBasketAction } from '@kfc-global/react-shared/redux/Actions/CartAction';
import {
  getNetbankingConfigurations,
  getOtherWalletsList,
} from '@kfc-global/react-shared/redux/Actions/PaymentsAction';
import { PAYMENT_TENDER_TYPES, ROUTE_URL } from 'common/constants/SharedConstants';
import {
  checkPhonepeSwitchPaymentStatus,
  resetMiniAppPaymentReducer,
} from '@kfc-global/react-shared/redux/Actions/MicroAppPaymentActions';
import {
  dispatchCreateOrderAction,
  dispatchPayUsingNetBankings,
  dispatchPayUsingPaytm,
  dispatchGiftCardAction,
  dispatchPayUsingPhonePe,
  dispatchPayUsingPhonepeSwitch,
} from 'components/checkout/PaymentDispatchFn';
import {
  resetGoogleSpotPayments,
  googleSpotCheckTransactionStatus,
  googleSpotInitiatePayment,
  googleSpotValidateResponse,
} from '@kfc-global/react-shared/redux/Actions/PaymentGoogleSpotActions';
import PaymentFormNetBankingOtherWalletUPIBilldesk from 'components/checkout/PaymentFormNetBankingOtherWalletUPIBilldesk';
import PaymentFormPayTm from 'components/checkout/PaymentFormPayTm';
import PaymentFormPhonePe from 'components/checkout/PaymentFormPhonePe';
import PaymentFormCreditCardBilldesk from 'components/checkout/PaymentFormCreditCardBilldesk';
import { payUsingPhonePeSDK } from 'common/utilities/phonepeSwitchAppUtils';
import { proceedToPaymentTemplate } from './templates/PaymentViewTemplates';
import { onClickContinuePayment } from 'components/checkout/CheckoutUtils/CheckoutUtils';
import { GuestContactDetailsClick } from 'common/utilities/analyticsUtils';
import { updatePaymentAnalytics } from '../../../components/checkout/CheckoutFn';
import { paymentErrorHandler } from 'common/utilities/CheckoutUtils';

export const renderPaymentForms = params => {
  const { tender, formPayload } = params;
  const { name: tenderName } = tender;
  switch (tenderName) {
    case PAYMENT_TENDER_TYPES.CREDIT_CARD:
      return <PaymentFormCreditCardBilldesk {...formPayload} />;
    case PAYMENT_TENDER_TYPES.NET_BANKING:
      return <PaymentFormNetBankingOtherWalletUPIBilldesk {...formPayload} />;
    // no default
  }
};

const IndiaPaymentView = props => {
  const { checkoutDetails, updateCheckoutDetails } = props;
  const { formPayload, tender, tenantId, isValidated, isPaymentTypeSelected, isClickContinuePayment } =
    checkoutDetails || {};
  const dispatch = useDispatch();

  const { paymentsReducer, paymentsGiftCardReducer, orderReducer, profileReducer } = useSelector(data => {
    return data;
  });
  const { flatResponse: { productMapById = {} } = {} } = useSelector(({ menuReducer }) => menuReducer);
  const { cartReducer } = useSelector(data => {
    return data;
  });
  const [isGpayPaymentValid, setGpayPaymentValidity] = useState(false);
  const { cartData = {} } = cartReducer;
  const {
    initiatePaymentStatus,
    initiatePaymentResponse,
    validatePaymentStatus,
    validatePaymentResponse,
    transactionStatus,
    transactionStatusResponse,
  } = useSelector(data => data?.paymentsGoogleSpotReducer || {});
  const { PHONEPE_SWITCH_CONSTANTS } = Constants;
  const {
    response: microAppPaymentResponse = {},
    isPaymentCompleted = false,
    phonepeStatusResponse = {},
  } = useSelector(state => state.microAppPaymentReducer) || {};
  const { paymentStatusMaxCallCount } = useSelector(data => data.tenantReducer);

  useEffect(() => {
    dispatch(resetMiniAppPaymentReducer());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ChannelManager.isGoogle && cartData?.allowedTenders?.includes(ChannelManager.channel)) {
      updateCheckoutDetails({
        tender: { name: ChannelManager.channel },
        isPaymentTypeSelected: true,
      });
    } else if (ChannelManager.isPhonePe && cartData?.allowedTenders?.includes(PAYMENT_TENDER_TYPES.PHONEPE_SWITCH)) {
      updateCheckoutDetails({
        tender: { name: PAYMENT_TENDER_TYPES.PHONEPE_SWITCH },
        isPaymentTypeSelected: true,
      });
    }
  }, [cartData?.allowedTenders, isPaymentTypeSelected, updateCheckoutDetails]);

  useEffect(() => {
    let paymentObject = {
      isClickContinuePayment,
      subscription: checkoutDetails?.userSubscription?.value,
      isLoggedIn: checkoutDetails?.userParams?.loggedIn,
      GuestContactDetailsClick,
      updatePaymentAnalytics,
      cartData,
      currencyConversionFactor: checkoutDetails?.currencyConversionFactor,
      tender,
      productMapById,
      updateCheckoutDetails,
      dispatchCreateOrderAction,
      dispatch,
      createOrderRequest: checkoutDetails?.placeOrderRequest,
    };

    onClickContinuePayment(paymentObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClickContinuePayment]);

  useEffect(() => {
    switch (tender?.name) {
      case PAYMENT_TENDER_TYPES.NET_BANKING:
        dispatch(getNetbankingConfigurations({ tenantId }));
        break;
      case PAYMENT_TENDER_TYPES.OTHER_WALLET:
        dispatch(getOtherWalletsList({ tenantId }));
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tender?.name]);

  useEffect(() => {
    if (isValidated) {
      dispatch(resetValidateBasketAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidated]);

  const phonepeSwitchPaymentFailureHandler = useCallback(() => {
    dispatch(resetMiniAppPaymentReducer());
    redirectToPaymentFailurePage(props);
  }, [props, dispatch]);

  useEffect(() => {
    if (isPaymentCompleted && phonepeStatusResponse?.status) {
      switch (phonepeStatusResponse?.status) {
        case MINI_APP_CONSTANTS.FAILURE:
          phonepeSwitchPaymentFailureHandler();
          break;
        case MINI_APP_CONSTANTS.PENDING:
        case MINI_APP_CONSTANTS.SUCCESS:
        default:
          dispatch(resetMiniAppPaymentReducer());
          redirectToPaymentSuccessPage(props);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentCompleted, phonepeStatusResponse]);

  const phonepeCB = useCallback(
    status => {
      if (status === PHONEPE_SWITCH_CONSTANTS.SUCCESS) {
        dispatch(
          checkPhonepeSwitchPaymentStatus({
            tenantId,
            customerId: cartData?.customer?.id,
            transactionId: microAppPaymentResponse?.transactionId,
            maxCallCount: paymentStatusMaxCallCount,
          }),
        );
      } else {
        phonepeSwitchPaymentFailureHandler();
      }
    },
    [
      PHONEPE_SWITCH_CONSTANTS.SUCCESS,
      cartData?.customer?.id,
      dispatch,
      microAppPaymentResponse?.transactionId,
      paymentStatusMaxCallCount,
      phonepeSwitchPaymentFailureHandler,
      tenantId,
    ],
  );

  useEffect(() => {
    if (isGpayPaymentValid && microAppPaymentResponse?.reservationId && ChannelManager.isPhonePe) {
      const fallbackURL = `${window.location.origin}${ROUTE_URL.CHECKOUT}`;
      payUsingPhonePeSDK(microAppPaymentResponse?.reservationId, fallbackURL, phonepeCB);
      setGpayPaymentValidity(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [microAppPaymentResponse]);

  const initiatePhonepeSwitchPayments = useCallback(() => {
    dispatchPayUsingPhonepeSwitch(
      tenantId,
      orderReducer?.response?.order?.id,
      tender?.name,
      cartData,
      dispatch,
      setGpayPaymentValidity,
    );
    updateCheckoutDetails?.({
      isClickContinuePayment: false, //
    });
  }, [cartData, dispatch, orderReducer?.response?.order, isClickContinuePayment, tenantId, tender?.name]);

  const failGooglePayment = useCallback(() => {
    if (ChannelManager.isGoogle) {
      setGpayPaymentValidity(false);
      dispatch(resetGoogleSpotPayments());
      ChannelManager.payment = null;
      ChannelManager.oloPaymentInitializer = null;
      updateCheckoutDetails?.({
        isClickContinuePayment: false,
      });
      redirectToPaymentFailurePage(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isClickContinuePayment]);

  const initiateGoogleSpotPayments = useCallback(() => {
    const payload = {
      tenantId: tenantId,
      body: {
        orderId: orderReducer?.response?.order?.id,
        tenderType: ChannelManager.channel,
      },
    };
    ChannelManager.orderId = orderReducer?.response?.order?.id;
    setGpayPaymentValidity(true);
    dispatch(googleSpotInitiatePayment(payload));
  }, [dispatch, orderReducer?.response?.order, tenantId]);

  const googlePaymentSuccess = useCallback(() => {
    if (ChannelManager.isGoogle) {
      dispatch(resetGoogleSpotPayments());
      redirectToPaymentSuccessPage(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      ChannelManager.addFeedback(
        `(initiatePaymentStatus, initiatePaymentResponse): ${JSON.stringify({
          initiatePaymentStatus,
          initiatePaymentResponse,
        })}`,
      );
      if (initiatePaymentResponse && isGpayPaymentValid) {
        await ChannelManager.BindPayment(initiatePaymentResponse);
        const payment = ChannelManager.payment;
        ChannelManager.addFeedback('Payment isSuccess data: ' + payment);

        if (payment?.code === 1) {
          updateCheckoutDetails?.({
            isClickContinuePayment: false, //
          });
        } else if (payment?.code === 0 || (payment?.code && payment?.code !== 1)) {
          failGooglePayment();
        } else if (payment) {
          const validateGooglePayResponsePayload = {
            tenantId: tenantId,
            body: payment,
          };
          dispatch(googleSpotValidateResponse(validateGooglePayResponsePayload));
        }
        setGpayPaymentValidity(false);
      } else if (initiatePaymentStatus === MINI_APP_CONSTANTS.FAILURE) {
        failGooglePayment();
      }
    })();
  }, [
    dispatch,
    failGooglePayment,
    initiatePaymentResponse,
    initiatePaymentStatus,
    isClickContinuePayment,
    tenantId,
    isGpayPaymentValid,
  ]);

  useEffect(() => {
    ChannelManager.addFeedback(
      `(validatePaymentStatus, validatePaymentResponse): ${JSON.stringify({
        validatePaymentStatus,
        validatePaymentResponse,
      })}`,
    );

    if (validatePaymentResponse) {
      if (validatePaymentResponse?.status === MINI_APP_CONSTANTS.SUCCESS) {
        googlePaymentSuccess();
      } else {
        failGooglePayment();
      }
    } else if (validatePaymentStatus === MINI_APP_CONSTANTS.PENDING && ChannelManager.transactionId) {
      const transactionStatusCheckPayload = {
        tenantId: tenantId,
        transactionId: ChannelManager.transactionId,
      };
      dispatch(googleSpotCheckTransactionStatus(transactionStatusCheckPayload));
    }
  }, [dispatch, failGooglePayment, googlePaymentSuccess, tenantId, validatePaymentResponse, validatePaymentStatus]);

  useEffect(() => {
    ChannelManager.addFeedback(
      `(transactionStatus, transactionStatusResponse): ${JSON.stringify({
        transactionStatus,
        transactionStatusResponse,
      })}`,
    );
    if (transactionStatusResponse) {
      if (transactionStatusResponse?.status === MINI_APP_CONSTANTS.SUCCESS) {
        ChannelManager.addFeedback(
          `Great, channel manager payment status is NOT fail... ${transactionStatusResponse?.status}`,
        );
        googlePaymentSuccess();
      } else {
        ChannelManager.addFeedback(
          `Hmmm, channel manager payment status IS FAIL... ${transactionStatusResponse?.status}`,
        );
        failGooglePayment();
      }
    } else if (transactionStatus === MINI_APP_CONSTANTS.FAILURE) {
      if (ChannelManager.shouldGoogleSpotIgnoreOloFailure) {
        googlePaymentSuccess();
      } else {
        failGooglePayment();
      }
    }
  }, [dispatch, failGooglePayment, googlePaymentSuccess, transactionStatus, transactionStatusResponse]);

  useEffect(() => {
    const orderId = orderReducer?.response?.order?.id;
    if (orderId) {
      localStorage.setItem('postPaymentType', tender?.name);
      localStorage.setItem('paymentStatus', '');
      const paymentProvider = checkoutDetails?.paymentProvider;
      switch (tender?.name) {
        case PAYMENT_TENDER_TYPES.CREDIT_CARD:
        case PAYMENT_TENDER_TYPES.NET_BANKING:
        case PAYMENT_TENDER_TYPES.OTHER_WALLET:
        case PAYMENT_TENDER_TYPES.UPI:
          dispatchPayUsingNetBankings({ dispatch, tenantId, orderId, tender, formPayload, paymentProvider });
          break;
        case PAYMENT_TENDER_TYPES.PAY_BY_CASH:
          redirectToPaymentSuccessPage(props);
          break;
        case PAYMENT_TENDER_TYPES.PAYTM:
          dispatchPayUsingPaytm(dispatch, tenantId, orderId, tender);
          break;
        case PAYMENT_TENDER_TYPES.GIFT_CARD:
          dispatchGiftCardAction(dispatch, tenantId, orderId, tender, formPayload, profileReducer);
          break;
        case PAYMENT_TENDER_TYPES.PHONEPE:
          dispatchPayUsingPhonePe(dispatch, tenantId, orderId, tender);
          break;
        case PAYMENT_TENDER_TYPES.GOOGLE_PAY_SPOT:
          initiateGoogleSpotPayments();
          break;
        case PAYMENT_TENDER_TYPES.PHONEPE_SWITCH:
          initiatePhonepeSwitchPayments();
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderReducer?.response?.order]);

  useEffect(() => {
    if (paymentsReducer?.response) {
      switch (tender?.name) {
        case PAYMENT_TENDER_TYPES.CREDIT_CARD:
          updateCheckoutDetails?.({
            formPayload: { ...formPayload, paymentRequestMessage: paymentsReducer?.response?.requestMessage },
          });
          break;
        case PAYMENT_TENDER_TYPES.NET_BANKING:
        case PAYMENT_TENDER_TYPES.OTHER_WALLET:
        case PAYMENT_TENDER_TYPES.UPI:
          updateCheckoutDetails?.({
            formPayload: {
              ...formPayload,
              requestParameters: paymentsReducer?.response?.requestParameters,
              postUrl: paymentsReducer?.response?.postUrl,
            },
          });
          break;
        case PAYMENT_TENDER_TYPES.PAYTM:
          if (paymentsReducer?.response?.merchantId && paymentsReducer?.response?.merchantId !== '') {
            updateCheckoutDetails?.({
              formPayload: {
                ...formPayload,
                ...paymentsReducer?.response,
              },
            });
          } else {
            paymentErrorHandler({ errorCode: 'payTmMerchantIdError', checkoutDetails, updateCheckoutDetails });
          }
          break;
        case PAYMENT_TENDER_TYPES.GIFT_CARD:
          if (paymentsGiftCardReducer.giftCardTransactionStatus === true) {
            redirectToPaymentSuccessPage(props);
          } else if (
            paymentsGiftCardReducer.giftCardTransactionStatus === false &&
            paymentsGiftCardReducer.giftCardTransactionStatus === undefined
          ) {
            //Redirect to failure page
            redirectToPaymentFailurePage(props);
          }
          break;

        case PAYMENT_TENDER_TYPES.PHONEPE:
          updateCheckoutDetails({
            formPayload: { ...formPayload, ...paymentsReducer?.response, tenderType: tender?.name },
          });
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsReducer?.response, paymentsGiftCardReducer]);

  useEffect(() => {
    checkoutDetails?.isUpdatedOrderActionRequest && updateCheckoutDetails({ dispatchOrderAction: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isUpdatedOrderActionRequest]);

  return (
    <>
      {renderPaymentForms({ tender, formPayload, orderReducer, paymentsReducer })}

      {(tender?.name === PAYMENT_TENDER_TYPES.OTHER_WALLET || tender?.name === PAYMENT_TENDER_TYPES.UPI) && (
        <PaymentFormNetBankingOtherWalletUPIBilldesk {...formPayload} />
      )}

      {tender?.name === PAYMENT_TENDER_TYPES.PAYTM && <PaymentFormPayTm {...formPayload} />}

      {tender?.name === PAYMENT_TENDER_TYPES.PHONEPE && <PaymentFormPhonePe {...formPayload} />}
      {proceedToPaymentTemplate(checkoutDetails, updateCheckoutDetails)}
    </>
  );
};

export default React.memo(IndiaPaymentView);
