import { useEffect } from 'react';

const PayGateCreditDebitCardHiddenForm = ({ formData }) => {
  const { redirectUri, payRequestId, checksum } = formData;

  useEffect(() => {
    redirectUri && document?.bankForm?.submit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document && document?.bankForm, redirectUri]);

  return (
    <div>
      {redirectUri && (
        <form name='bankForm' method='POST' action={redirectUri}>
          <input type='hidden' name='PAY_REQUEST_ID' value={payRequestId}></input>
          <input type='hidden' name='CHECKSUM' value={checksum}></input>
        </form>
      )}
    </div>
  );
};

export default PayGateCreditDebitCardHiddenForm;
