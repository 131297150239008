import { Controller } from 'react-hook-form';
import { MaterialTextInput } from 'atoms/MaterialInput';
import PaymentTextInput from 'atoms/PaymentTextInput';
import TileButtonGroup from 'atoms/TileButtongroup/TileButtonGroup';
import { TextInputMaskComp } from 'atoms/InputMask';
import ImageComponent from 'atoms/Image/index';
import { errorStyleForMaterialTextInput } from 'common/utilities/utils';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';

import DonationButtonGroup from 'molecules/DonationButtonGroup/DonationButtonGroup';
import {
  FORM_FIELD_TYPE,
  PHONE_NUMBER_NOTE,
  SEND_VERIFIATION_CODE_LABEL,
  VERIFY_PHONE_NUMBER,
} from 'common/constants/SharedConstants';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import { ContainedButton } from 'atoms/Buttons';
import { GroupTileContainer } from '../Container/GroupTileContainer';

/*********************************************************************
 * defaultElmentConfig
 *
 *********************************************************************/
export const defaultElmentConfig = {
  id: '',
  name: '',
  label: '',
  placeHolder: '',
  type: '',
  dataType: '',
  value: '',
  htmlString: null,
  additionalDescription: null,
  showAdditionalDescription: false,
  className: '',
  readonly: false,
  error: null,
  pattern: null,
  rules: {},
  autocomplete: 'off',
  phoneNumberMask: false,
  conditionalRender: false,
  isMandatory: false,
  maxLength: null,
  onChangeHandler: null,
  onBlurHandler: null,
  onClickHandler: null,
  invalid: false,
  displayErrorMessage: true,
  onClearButtonClickHandler: null,
  onEditButtonClickHandler: null,
};

/*********************************************************************
 * updateFormElementConfig
 *
 *********************************************************************/
export const updateFormElementConfig = (formConfig, fieldConfig) => {
  return (
    <Controller
      id={getFieldId(formConfig, fieldConfig)}
      name={fieldConfig?.name}
      control={formConfig?.control}
      rules={getUpdateRulesFieldConfig(formConfig, fieldConfig)}
      render={params => updateFormFields(params, formConfig, fieldConfig)}
      shouldUnregister='true'
    />
  );
};

/*********************************************************************
 * updateFormFields
 *
 *********************************************************************/
export const updateFormFields = (params, formConfig, fieldConfig) => {
  const {
    field: { onChange, value, onBlur, ref },
    fieldState: { error, invalid, isTouched, isDirty },
  } = params;
  const paramFormConfig = {
    ...formConfig,
    onControlerChangeHandler: event => onChange(event),
    onControlerBlurHandler: event => onBlur(event),
  };
  return renderFormFields(paramFormConfig, {
    ...fieldConfig,
    ref,
    value,
    error,
    isDirty,
    invalid,
    isTouched,
  });
};

/*********************************************************************
 * renderFormFields
 *
 *********************************************************************/
export const renderFormFields = (formConfig, fieldConfig) => {
  switch (fieldConfig?.type) {
    case FORM_FIELD_TYPE?.LABEL:
    case FORM_FIELD_TYPE?.TITLE:
    case FORM_FIELD_TYPE?.TEXT:
    case FORM_FIELD_TYPE?.NOTE:
      return noteFields(formConfig, fieldConfig);
    case FORM_FIELD_TYPE?.BUTTON:
      return buttonFields(formConfig, fieldConfig);
    case FORM_FIELD_TYPE?.INPUT:
      return getMaterialTextInputFields(formConfig, fieldConfig);
    case FORM_FIELD_TYPE?.PAYMENT_TEXT_INPUT:
      return getPaymentTextInputFields(formConfig, fieldConfig);
    case FORM_FIELD_TYPE?.MASK_INPUT:
      return getMaskTextInputFields(formConfig, fieldConfig);
    case FORM_FIELD_TYPE?.CHECKBOX:
      return checkBoxField(formConfig, fieldConfig);
    case FORM_FIELD_TYPE?.TILES_BUTTON_GROUP:
      return getTilesButtonGroup(formConfig, fieldConfig);
    case FORM_FIELD_TYPE?.DONATION_BUTTON_GROUP:
      return getDonationButtonGroup(formConfig, fieldConfig);
    case FORM_FIELD_TYPE?.ICON:
      return getIconField(formConfig, fieldConfig);
    default:
      return null;
  }
};

const getFieldValueCheck = (formConfig, fieldConfig, fieldName) =>
  isFunction(fieldConfig?.[fieldName]) ? fieldConfig?.[fieldName]?.(formConfig, fieldConfig) : fieldConfig?.[fieldName];

const getFieldValueCheckUsingGet = (formConfig, fieldConfig, fieldName) =>
  isFunction(get(fieldConfig, fieldName))
    ? get(fieldConfig, fieldName)?.(formConfig, fieldConfig)
    : get(fieldConfig, fieldName);

/*********************************************************************
 * get customize field values from parent component
 *
 *********************************************************************/
const getFieldValue = (formConfig, fieldConfig, fieldName) =>
  Array.isArray(fieldName)
    ? getFieldValueCheckUsingGet(formConfig, fieldConfig, fieldName)
    : getFieldValueCheck(formConfig, fieldConfig, fieldName);

/*********************************************************************
 * Field Conditional Render
 *
 *********************************************************************/
const isAllowToRender = (formConfig, fieldConfig) =>
  isFunction(fieldConfig?.conditionalRender)
    ? fieldConfig?.conditionalRender?.(formConfig, fieldConfig)
    : !fieldConfig?.conditionalRender;

/*********************************************************************
 * Element Validation Definition
 *
 *********************************************************************/
const isElementReadOnly = (formConfig, fieldConfig) =>
  formConfig?.isFormReadOnly || getFieldValue(formConfig, fieldConfig, 'readonly');

const onFormFieldClickHandler = (event, formConfig, fieldConfig) => {
  formConfig?.onControlerChangeHandler?.(event);
  fieldConfig?.onClickHandler?.(formConfig, fieldConfig, event);
};

const onFormFieldKeyDownHandler = (event, formConfig, fieldConfig) => {
  formConfig?.onControlerChangeHandler?.(event);
  fieldConfig?.onKeyDownHandler?.(formConfig, fieldConfig, event);
};

const onFormFieldChangeHandler = (event, formConfig, fieldConfig) => {
  formConfig?.onControlerChangeHandler?.(event);
  fieldConfig?.onChangeHandler?.(formConfig, fieldConfig, event);
};

const onFormFieldBlurHandler = (event, formConfig, fieldConfig) => {
  formConfig?.onControlerBlurHandler?.(event);
  fieldConfig?.onBlurHandler?.(formConfig, fieldConfig, event);
};

const onFormFieldFocusHandler = (event, formConfig, fieldConfig) => {
  formConfig?.onControlerFocusHandler?.(event);
  fieldConfig?.onFocusHandler?.(formConfig, fieldConfig, event);
};

/*********************************************************************
 * Element ID Definition
 *
 *********************************************************************/
const getFieldId = (formConfig, fieldConfig) =>
  `${formConfig?.name ? formConfig?.name + '__' : ''}${fieldConfig?.name}`;

/*********************************************************************
 * Element Style Class Name Definition
 *
 *********************************************************************/
const getReadOnlyElementClassName = (formConfig, fieldConfig) =>
  isElementReadOnly(formConfig, fieldConfig) ? 'read-only' : '';

const getErrorElementClassName = (formConfig, fieldConfig) =>
  (fieldConfig?.isTouched || fieldConfig?.isDirty) && fieldConfig?.error?.message
    ? `formelement-error ${getFieldValue(formConfig, fieldConfig, 'errorClassName')}`
    : '';

const getElmentClassName = (formConfig, fieldConfig, elementClassName = '') =>
  `formElement ${elementClassName} ${
    getFieldValue(formConfig, fieldConfig, 'className') ?? ''
  } ${getErrorElementClassName(formConfig, fieldConfig)} ${getReadOnlyElementClassName(formConfig, fieldConfig)}`;

/*********************************************************************
 * note / label / text - Field
 *
 *********************************************************************/
export const noteFields = (formConfig, fieldConfig) => {
  return (
    isAllowToRender(formConfig, fieldConfig) &&
    (fieldConfig?.htmlString ? (
      <div
        className={getElmentClassName(formConfig, fieldConfig, 'notefieldelement')}
        dangerouslySetInnerHTML={{ __html: documentToHtmlString(fieldConfig?.htmlString) }}
      ></div>
    ) : (
      <label className={getElmentClassName(formConfig, fieldConfig, 'notefieldelement')}>
        {getFieldValue(formConfig, fieldConfig, 'value') ?? getFieldValue(formConfig, fieldConfig, 'label')}
      </label>
    ))
  );
};

/*********************************************************************
 * Button - Field
 *
 *********************************************************************/
export const buttonFields = (formConfig, fieldConfig) => {
  return (
    isAllowToRender(formConfig, fieldConfig) && (
      <div className={`field ${getFieldValue(formConfig, fieldConfig, 'containerClassName')}`}>
        <ContainedButton
          className={getElmentClassName(formConfig, fieldConfig)}
          id={getFieldId(formConfig, fieldConfig)}
          name={getFieldId(formConfig, fieldConfig)}
          ariaLable={getFieldValue(formConfig, fieldConfig, 'ariaLable')}
          variant={getFieldValue(formConfig, fieldConfig, 'variant')}
          inactive={getFieldValue(formConfig, fieldConfig, 'disabled')}
          inverted={getFieldValue(formConfig, fieldConfig, 'inverted')}
          hasPrices={getFieldValue(formConfig, fieldConfig, 'hasPrices')}
          loading={getFieldValue(formConfig, fieldConfig, 'loading')}
          small={getFieldValue(formConfig, fieldConfig, 'small')}
          fullWidth={getFieldValue(formConfig, fieldConfig, 'fullWidth')}
          onClickHandler={event => onFormFieldClickHandler(event, formConfig, fieldConfig)}
          onKeyDownHandler={event => onFormFieldKeyDownHandler(event, formConfig, fieldConfig)}
        >
          {fieldConfig?.buttonText ?? noteFields(formConfig, fieldConfig)}
        </ContainedButton>
      </div>
    )
  );
};

/*********************************************************************
 * Icon - Field
 *
 *********************************************************************/
export const getIconField = (formConfig, fieldConfig) => {
  return (
    isAllowToRender(formConfig, fieldConfig) && (
      <div className={`field ${getFieldValue(formConfig, fieldConfig, 'containerClassName')}`}>
        <ImageComponent
          className={getElmentClassName(formConfig, fieldConfig)}
          srcFile={getFieldValue(formConfig, fieldConfig, 'image')}
          alt={getFieldValue(formConfig, fieldConfig, 'variant')}
        />
      </div>
    )
  );
};
/*********************************************************************
 * TextInputField
 *
 *********************************************************************/
export const TextInputField = ({ Component, formConfig, fieldConfig }) => {
  return (
    isAllowToRender(formConfig, fieldConfig) && (
      <>
        <Component
          className={getElmentClassName(
            formConfig,
            fieldConfig,
            fieldConfig?.value && fieldConfig?.type === FORM_FIELD_TYPE?.MASK_INPUT ? 'phnContainsval' : '',
          )}
          id={getFieldId(formConfig, fieldConfig)}
          name={getFieldId(formConfig, fieldConfig)}
          onChange={event => onFormFieldChangeHandler(event, formConfig, fieldConfig)}
          onFocus={event => onFormFieldFocusHandler(event, formConfig, fieldConfig)}
          onBlur={event => onFormFieldBlurHandler(event, formConfig, fieldConfig)}
          value={getFieldValue(formConfig, fieldConfig, 'updatedValue') ?? fieldConfig?.value}
          placeholder={
            getFieldValue(formConfig, fieldConfig, 'placeHolder') || getFieldValue(formConfig, fieldConfig, 'label')
          }
          autocomplete={fieldConfig?.autocomplete}
          disabled={isElementReadOnly(formConfig, fieldConfig)}
          inputTestId={`enter-${getFieldId(formConfig, fieldConfig)}-details`}
          isMandatory={getFieldValue(formConfig, fieldConfig, 'isMandatory')}
          maxLength={getFieldValue(formConfig, fieldConfig, 'maxLength')}
          dataType={getFieldValue(formConfig, fieldConfig, 'dataType')}
          isValid={(error, isTouched, params) =>
            customPaymentTextInputValidation({ formConfig, fieldConfig, error, isTouched, fieldProps: params })
          }
          mask={getFieldValue(formConfig, fieldConfig, 'mask') || fieldConfig?.phoneNumberMask}
          labelTestId={`${getFieldId(formConfig, fieldConfig)}-masktextlabel-id`}
          labelClassName={`${getFieldId(formConfig, fieldConfig)}-masktextlabel`}
          showClearTextInputBtn={getFieldValue(formConfig, fieldConfig, 'showClearTextInputBtn')}
          showEditTextInputBtn={getFieldValue(formConfig, fieldConfig, 'showEditTextInputBtn')}
          onClearButtonClickHandler={event => fieldConfig?.onClearButtonClickHandler?.(formConfig, fieldConfig, event)}
          onEditButtonClickHandler={event => fieldConfig?.onEditButtonClickHandler?.(formConfig, fieldConfig, event)}
          prefix={getFieldValue(formConfig, fieldConfig, 'prefix')}
          containerClassName={getFieldValue(formConfig, fieldConfig, 'containerClassName')}
        />
        {getErrorMessageTemplate(formConfig, fieldConfig)}
      </>
    )
  );
};

/*********************************************************************
 * getMaterialTextInputFields
 *
 *********************************************************************/
export const getMaterialTextInputFields = (formConfig, fieldConfig) => (
  <TextInputField Component={MaterialTextInput} formConfig={formConfig} fieldConfig={fieldConfig} />
);

/*********************************************************************
 * PaymentTextInputFields
 *
 *********************************************************************/
export const getPaymentTextInputFields = (formConfig, fieldConfig) => (
  <TextInputField Component={PaymentTextInput} formConfig={formConfig} fieldConfig={fieldConfig} />
);

/*********************************************************************
 * maskTextInputFields
 *
 *********************************************************************/
export const getMaskTextInputFields = (formConfig, fieldConfig) => (
  <TextInputField Component={TextInputMaskComp} formConfig={formConfig} fieldConfig={fieldConfig} />
);

/*********************************************************************
 * getErrorMessageTemplate
 *
 *********************************************************************/
export const getErrorMessageTemplate = (formConfig, fieldConfig) => {
  return (
    (fieldConfig?.isTouched || fieldConfig?.isDirty) &&
    fieldConfig?.error &&
    fieldConfig?.displayErrorMessage && (
      <div id={`user-${fieldConfig?.type}`} className={errorStyleForMaterialTextInput(fieldConfig?.value)}>
        {<img src={IMAGE_PATH?.Ellipse} alt='error' className={`mr-2`} />}
        <span role='alert' className='form-error'>
          {isObject(fieldConfig.error) && 'message' in fieldConfig?.error
            ? getFieldValue(formConfig, fieldConfig, ['error', 'message'])
            : getFieldValue(formConfig, fieldConfig, 'error')}
        </span>
      </div>
    )
  );
};

/*********************************************************************
 *
 *
 *********************************************************************/
export const checkBoxField = (formConfig, fieldConfig) => {
  return (
    <>
      {isAllowToRender(formConfig, fieldConfig) && (
        <div className={getElmentClassName(formConfig, fieldConfig, 'd-flex form-checkbox-container')}>
          <input
            type='checkbox'
            className={getElmentClassName(formConfig, fieldConfig, 'common_checkbox')}
            onChange={event => onFormFieldChangeHandler(event, formConfig, fieldConfig)}
            onBlur={event => onFormFieldBlurHandler(event, formConfig, fieldConfig)}
            checked={fieldConfig?.value}
            data-testid={`enter-${getFieldId(formConfig, fieldConfig)}-details`}
            disabled={isElementReadOnly(formConfig, fieldConfig)}
          />
          <label title='title' className='form-check-label'>
            {fieldConfig?.label !== '' && <span>{fieldConfig?.label}</span>}
            {fieldConfig?.htmlString && (
              <div
                className='form-checkbox-text'
                dangerouslySetInnerHTML={{
                  __html: documentToHtmlString(fieldConfig?.htmlString),
                }}
              ></div>
            )}
          </label>
        </div>
      )}
      {fieldConfig?.additionalDescription && fieldConfig?.showAdditionalDescription && (
        <div
          className='optional-description'
          dangerouslySetInnerHTML={{
            __html: documentToHtmlString(fieldConfig?.additionalDescription),
          }}
        ></div>
      )}
    </>
  );
};

/*********************************************************************
 * DonationButtonGroup
 *
 *********************************************************************/
export const getDonationButtonGroup = (formConfig, fieldConfig) => {
  return (
    <>
      <DonationButtonGroup
        className={getElmentClassName(formConfig, fieldConfig)}
        id={getFieldId(formConfig, fieldConfig)}
        name={fieldConfig?.name}
        value={fieldConfig?.value}
        disabled={isElementReadOnly(formConfig, fieldConfig)}
        {...fieldConfig}
        {...formConfig}
      />
      {getErrorMessageTemplate(formConfig, fieldConfig)}
    </>
  );
};

/*********************************************************************
 * tilesButtonGroup
 *
 *********************************************************************/
export const getTilesButtonGroup = (formConfig, fieldConfig) => {
  return (
    <>
      <TileButtonGroup
        className={getElmentClassName(formConfig, fieldConfig)}
        id={getFieldId(formConfig, fieldConfig)}
        name={fieldConfig?.name}
        selected={fieldConfig?.value}
        column={fieldConfig?.column}
        source={fieldConfig?.source}
        onClickHandler={event => onFormFieldClickHandler(event, formConfig, fieldConfig)}
      />
      {getErrorMessageTemplate(formConfig, fieldConfig)}
    </>
  );
};

/*********************************************************************
 * getUpdateRulesFieldConfig
 *
 *********************************************************************/
export const getUpdateRulesFieldConfig = (formConfig, fieldConfig) =>
  fieldConfig?.type === FORM_FIELD_TYPE?.PAYMENT_TEXT_INPUT
    ? {
        ...fieldConfig?.rules,
        validate: () => isEmpty(formConfig?.errors?.[fieldConfig?.name]?.message),
      }
    : { ...fieldConfig?.rules };

/*********************************************************************
 * customPaymentTextInputValidation
 *
 *********************************************************************/
const customPaymentTextInputValidation = params => {
  const { formConfig, fieldConfig, error } = params;
  isEmpty(error) &&
    !isEmpty(formConfig?.errors?.[fieldConfig?.name]) &&
    formConfig.clearErrorToFormElements(fieldConfig?.name);
  !isEmpty(error) &&
    (isEmpty(formConfig?.errors?.[fieldConfig?.name]) || isEmpty(formConfig?.errors?.[fieldConfig?.name]?.message)) &&
    formConfig.setErrorToFormElements(fieldConfig?.name, 'validate', error);
};

export const phoneTileComponent = (fieldsConfig, formConfig, getFormConfig) => {
  const group1 = fieldsConfig?.filter(element => element?.group === 1);
  const group2 = fieldsConfig?.filter(element => element?.group === 2);
  group2?.sort((eleA, eleB) => eleA?.displayOrder - eleB?.displayOrder);
  const group3 = fieldsConfig?.filter(element => element?.group === 3);

  return (
    <>
      {group1?.map?.(config =>
        updateFormElementConfig(getFormConfig(formConfig), {
          ...defaultElmentConfig,
          ...config,
        }),
      )}
      <GroupTileContainer>
        {group2?.map?.(config =>
          updateFormElementConfig(getFormConfig(formConfig), {
            ...defaultElmentConfig,
            ...config,
          }),
        )}
      </GroupTileContainer>
      {group3?.map?.(config =>
        updateFormElementConfig(getFormConfig(formConfig), {
          ...defaultElmentConfig,
          ...config,
        }),
      )}
    </>
  );
};
