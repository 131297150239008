import dropin from 'braintree-web-drop-in';

import { generateClientToken, transactionSale } from '@kfc-global/react-shared/redux/Actions/BraintreePaymentAction';
import { hideLoader, showLoader } from '@kfc-global/react-shared/redux/Actions/LoaderAction';
import { BRAINTREE_GPAY_MERCHANT_ID, BRAINTREE_MERCHANT_NAME } from '@kfc-global/react-shared/config/config.constants';
import { config } from '@kfc-global/react-shared/config/config.utils';

import { PAYMENT_TENDER_TYPES } from 'common/constants/SharedConstants';
import PAYMENT_CONSTANTS from 'organisms/Payment/constants/PaymentConstants';

import {
  clearTenderDetails,
  updateIsPaymentTypeSelected,
  updateTenderDetails,
  paymentTrancationSuccessHandler,
  paymentTrancationErrorHandler,
} from 'common/utilities/PaymentUtils';

const { BRAINTREE_PAYMENT_TYPE, BRAINTREE_EVENT } = PAYMENT_CONSTANTS;

export const mapBrainTreeKeysIntoOrderSerKeys = {
  [BRAINTREE_PAYMENT_TYPE.KEY_CARD]: PAYMENT_TENDER_TYPES.CREDIT_CARD,
  [BRAINTREE_PAYMENT_TYPE.KEY_PAY_PAL]: PAYMENT_TENDER_TYPES.PAY_PAL,
  [BRAINTREE_PAYMENT_TYPE.KEY_GOOGLE_PAY]: PAYMENT_TENDER_TYPES.GOOGLE_PAY,
  [BRAINTREE_PAYMENT_TYPE.KEY_APPLE_PAY]: PAYMENT_TENDER_TYPES.APPLE_PAY,
  [BRAINTREE_PAYMENT_TYPE.KEY_APPLE_PAY]: PAYMENT_TENDER_TYPES.VENMO,

  [BRAINTREE_PAYMENT_TYPE.KEY_CREDIT_CARD]: PAYMENT_TENDER_TYPES.CREDIT_CARD,
  [BRAINTREE_PAYMENT_TYPE.KEY_PAY_PAL_ACCOUNT]: PAYMENT_TENDER_TYPES.PAY_PAL,
  [BRAINTREE_PAYMENT_TYPE.KEY_ANDROID_PAY_CARD]: PAYMENT_TENDER_TYPES.GOOGLE_PAY,
  [BRAINTREE_PAYMENT_TYPE.KEY_APPLE_PAY_CARD]: PAYMENT_TENDER_TYPES.APPLE_PAY,
  [BRAINTREE_PAYMENT_TYPE.KEY_VENMO_ACCOUNT]: PAYMENT_TENDER_TYPES.VENMO,
};

export const mapOrderSerKeysIntoBrainTreeKeys = {
  [PAYMENT_TENDER_TYPES.CREDIT_CARD]: BRAINTREE_PAYMENT_TYPE.KEY_CARD,
  [PAYMENT_TENDER_TYPES.PAY_PAL]: BRAINTREE_PAYMENT_TYPE.KEY_PAY_PAL,
  [PAYMENT_TENDER_TYPES.GOOGLE_PAY]: BRAINTREE_PAYMENT_TYPE.KEY_GOOGLE_PAY,
  [PAYMENT_TENDER_TYPES.APPLE_PAY]: BRAINTREE_PAYMENT_TYPE.KEY_APPLE_PAY,
  [PAYMENT_TENDER_TYPES.VENMO]: BRAINTREE_PAYMENT_TYPE.KEY_APPLE_PAY,
};
/*********************************************************************
 *
 *
 *********************************************************************/
export const getPaymentOptions = allowedTenders =>
  allowedTenders?.map(tender => mapOrderSerKeysIntoBrainTreeKeys[tender]);

/*********************************************************************
 *
 *
 *********************************************************************/
export const subscribeBraintreeHandler = params => {
  if (params?.braintreeInstance) {
    const {
      braintreeInstance,
      changeActiveViewHandlerBind,
      paymentMethodRequestableHandlerBind,
      noPaymentMethodRequestableHandlerBind,
      paymentOptionSelectedHandlerBind,
    } = params;

    const isBraintreeEvent = eventName =>
      braintreeInstance?._events?.[eventName] === undefined || braintreeInstance?._events?.[eventName]?.length === 0;

    isBraintreeEvent(BRAINTREE_EVENT.CHANGE_ACTIVE_VIEW) &&
      braintreeInstance?.on(BRAINTREE_EVENT.CHANGE_ACTIVE_VIEW, changeActiveViewHandlerBind);
    isBraintreeEvent(BRAINTREE_EVENT.PAYMENT_METHOD_REQUESTABLE) &&
      braintreeInstance?.on(BRAINTREE_EVENT.PAYMENT_METHOD_REQUESTABLE, paymentMethodRequestableHandlerBind);
    isBraintreeEvent(BRAINTREE_EVENT.NO_PAYMENT_METHOD_REQUESTABLE) &&
      braintreeInstance?.on(BRAINTREE_EVENT.NO_PAYMENT_METHOD_REQUESTABLE, noPaymentMethodRequestableHandlerBind);
    isBraintreeEvent(BRAINTREE_EVENT.PAYMENT_OPTION_SELECTED) &&
      braintreeInstance?.on(BRAINTREE_EVENT.PAYMENT_OPTION_SELECTED, paymentOptionSelectedHandlerBind);
  }
};

/*********************************************************************
 *
 *
 *********************************************************************/
export const unSubscribeBraintreeHandler = params => {
  if (params?.braintreeInstance) {
    const {
      braintreeInstance,
      changeActiveViewHandlerBind,
      paymentMethodRequestableHandlerBind,
      noPaymentMethodRequestableHandlerBind,
      paymentOptionSelectedHandlerBind,
    } = params;
    braintreeInstance?.off(BRAINTREE_EVENT.CHANGE_ACTIVE_VIEW, changeActiveViewHandlerBind);
    braintreeInstance?.off(BRAINTREE_EVENT.PAYMENT_METHOD_REQUESTABLE, paymentMethodRequestableHandlerBind);
    braintreeInstance?.off(BRAINTREE_EVENT.NO_PAYMENT_METHOD_REQUESTABLE, noPaymentMethodRequestableHandlerBind);
    braintreeInstance?.off(BRAINTREE_EVENT.PAYMENT_OPTION_SELECTED, paymentOptionSelectedHandlerBind);
  }
};

/*********************************************************************
 *
 *  console.log('changeActiveView *****> ', event);
 *********************************************************************/
export const changeActiveViewHandler = (checkoutDetails, updateCheckoutDetails, event) => {
  event?.newViewId === 'delete-confirmation' &&
    updateIsPaymentTypeSelected(checkoutDetails, updateCheckoutDetails, false);

  event?.newViewId === 'options' &&
    event?.previousViewId === 'delete-confirmation' &&
    clearTenderDetails(updateCheckoutDetails);
};

/*********************************************************************
 *
 *  console.log('paymentMethodRequestableHandler *****> ', event);
 *********************************************************************/
export const paymentMethodRequestableHandler = (checkoutDetails, updateCheckoutDetails, braintreeInstance, event) => {
  if (event && Object?.prototype?.hasOwnProperty?.call?.(event, 'paymentMethodIsSelected')) {
    if (event?.paymentMethodIsSelected && mapBrainTreeKeysIntoOrderSerKeys[event?.type]) {
      // && mapBrainTreeKeysIntoOrderSerKeys[event?.type] !== checkoutDetails?.tender?.name
      triggerBraintreeRequestPaymentMethod(braintreeInstance, updateCheckoutDetails);
    } else {
      updateIsPaymentTypeSelected(checkoutDetails, updateCheckoutDetails, true);
    }
  }
};

/*********************************************************************
 *
 *  console.log('noPaymentMethodRequestableHandler *****> ', event);
 *********************************************************************/
export const noPaymentMethodRequestableHandler = (checkoutDetails, updateCheckoutDetails, event) => {
  clearTenderDetails(updateCheckoutDetails);
};

/*********************************************************************
 * triggerBraintreeRequestPaymentMethod
 * console.debug('requestPaymentMethodError ****====> ', requestPaymentMethodError);
 * console.debug('payload ****=====> ', payload);
 *********************************************************************/
export const triggerBraintreeRequestPaymentMethod = (
  braintreeInstance,
  updateCheckoutDetails,
  showErrorMessage = true,
) => {
  if (braintreeInstance) {
    braintreeInstance?.requestPaymentMethod((requestPaymentMethodError, payload) => {
      if (requestPaymentMethodError) {
        showErrorMessage && updateCheckoutDetails({ showPaymentPreValidationError: true });
        clearTenderDetails(updateCheckoutDetails);
        return;
      }

      if (payload) {
        updateCheckoutDetails({ showPaymentPreValidationError: false });

        updateTenderDetails(updateCheckoutDetails, mapBrainTreeKeysIntoOrderSerKeys[payload?.type], payload);
      } else {
        // transaction sale with selected payment method failed
        // clear the selected payment method and add a message
        // to the checkout page about the failure
        updateCheckoutDetails({ showPaymentPreValidationError: true });
        braintreeInstance?.clearSelectedPaymentMethod();
      }
    });
  }
};

/*********************************************************************
 * dispatchGenerateClientTokenAction
 * params - { props, dispatch, checkoutDetails, updateCheckoutDetails }
 *********************************************************************/
export const dispatchGenerateClientTokenAction = params => {
  const { props, checkoutDetails, updateCheckoutDetails } = params;
  const { dispatch, tenantId, customerId, userFirstName, userLastName, userPhone, userEmail, isClickAddPayment } =
    checkoutDetails;
  if (isClickAddPayment) {
    dispatch(
      generateClientToken(
        {
          tenantId,
          customerId,
          data: {
            id: customerId,
            firstName: userFirstName?.value,
            lastName: userLastName?.value || userFirstName?.value,
            phone: userPhone?.value,
            email: userEmail?.value,
          },
        },
        response => {
          if (response && response?.status >= 400) {
            updateCheckoutDetails({ showPaymentError: true });
          }
        },
        error => paymentTrancationErrorHandler(props, error),
      ),
    );
  }
};

/*********************************************************************
 *
 *
 *********************************************************************/
const getBraintreeCardCreateOptions = checkoutDetails => {
  const { allowedTenders, userParams } = checkoutDetails;
  return allowedTenders?.includes(PAYMENT_TENDER_TYPES.CREDIT_CARD)
    ? {
        vault: {
          allowVaultCardOverride: false,
          vaultCard: userParams?.loggedIn,
        },
      }
    : false;
};

/*********************************************************************
 *
 *
 *********************************************************************/
const getBraintreeGooglePayCreateOptions = checkoutDetails => {
  const { finalAmount, countryCode, currencyCode, allowedTenders } = checkoutDetails;
  return allowedTenders?.includes(PAYMENT_TENDER_TYPES.GOOGLE_PAY)
    ? {
        merchantId: config(BRAINTREE_GPAY_MERCHANT_ID),
        transactionInfo: {
          currencyCode: currencyCode,
          countryCode: countryCode,
          totalPriceStatus: PAYMENT_CONSTANTS?.BRAINTREE_FINAL?.toUpperCase?.(),
          totalPrice: finalAmount,
        },
      }
    : false;
};

/*********************************************************************
 *
 *
 *********************************************************************/
const getBraintreePaypalCreateOptions = checkoutDetails => {
  const { finalAmount, currencyCode, allowedTenders, userParams } = checkoutDetails;
  return allowedTenders?.includes(PAYMENT_TENDER_TYPES.PAY_PAL)
    ? {
        flow: userParams?.loggedIn ? PAYMENT_CONSTANTS?.BRAINTREE_VAULT : PAYMENT_CONSTANTS?.BRAINTREE_CHECKOUT,
        amount: finalAmount,
        currency: currencyCode,
        commit: false,
        vault: {
          vaultPayPal: userParams?.loggedIn,
        },
      }
    : false;
};

/*********************************************************************
 *
 *
 *********************************************************************/
const getBraintreePaypalCreditCreateOptions = checkoutDetails => {
  const { finalAmount, currencyCode, allowedTenders, userParams } = checkoutDetails;
  return allowedTenders?.includes(PAYMENT_TENDER_TYPES.PAY_PAL_CREDIT)
    ? {
        flow: userParams?.loggedIn ? PAYMENT_CONSTANTS?.BRAINTREE_VAULT : PAYMENT_CONSTANTS?.BRAINTREE_CHECKOUT,
        amount: finalAmount,
        currency: currencyCode,
        commit: false,
        vault: {
          vaultPayPal: userParams?.loggedIn,
        },
      }
    : false;
};

/*********************************************************************
 *
 *
 *********************************************************************/
const getBraintreeApplePayCreateOptions = checkoutDetails => {
  const { service, finalAmount, allowedTenders } = checkoutDetails;
  return allowedTenders?.includes(PAYMENT_TENDER_TYPES.APPLE_PAY)
    ? {
        displayName: config(BRAINTREE_MERCHANT_NAME),
        paymentRequest: {
          total: {
            label: service,
            amount: finalAmount,
          },
        },
      }
    : false;
};

/*********************************************************************
 *
 *
 *********************************************************************/
const getBraintreeVenmoCreateOptions = allowedTenders => {
  return allowedTenders?.includes(PAYMENT_TENDER_TYPES.VENMO)
    ? {
        allowNewBrowserTab: false,
      }
    : false;
};

/*********************************************************************
 *
 *
 *********************************************************************/
export const getDropInConfig = (checkoutDetails, braintreePaymentReducer) => {
  const { currencyFormat, allowedTenders } = checkoutDetails;
  return {
    authorization: braintreePaymentReducer?.token,
    container: '#braintree-drop-in-div',
    locale: currencyFormat,
    paymentOptionPriority: getPaymentOptions(allowedTenders),
    card: getBraintreeCardCreateOptions(checkoutDetails),
    paypal: getBraintreePaypalCreateOptions(checkoutDetails),
    paypalCredit: getBraintreePaypalCreditCreateOptions(checkoutDetails),
    googlePay: getBraintreeGooglePayCreateOptions(checkoutDetails),
    applePay: getBraintreeApplePayCreateOptions(checkoutDetails),
    venmo: getBraintreeVenmoCreateOptions(allowedTenders),
    dataCollector: true,
    threeDSecure: false,
    vaultManager: true,
    preselectVaultedPaymentMethod: true,
    showDefaultPaymentMethodFirst: true,
  };
};

/*********************************************************************
 *
 * console.debug('instance *****************> ', instance);
 * console.debug('Info - Teardown ****====> ');
 *********************************************************************/
export const createBraintreeInstance = params => {
  const { braintreeInstance, setBraintreeInstance, checkoutDetails, updateCheckoutDetails, braintreePaymentReducer } =
    params;
  const { dispatch, isClickAddPayment } = checkoutDetails;
  if (isClickAddPayment && braintreePaymentReducer?.token) {
    dispatch(showLoader());

    const initializeBraintree = () => {
      if (!braintreeInstance) {
        dropin.create(getDropInConfig(checkoutDetails, braintreePaymentReducer), (error, instance) => {
          dispatch(hideLoader());
          if (error) {
            console.debug('Error - dropin Create ****====> ', error);
            updateIsPaymentTypeSelected(checkoutDetails, updateCheckoutDetails, false);
          } else {
            setBraintreeInstance(instance);
          }
        });
      }
    };

    if (braintreeInstance) {
      braintreeInstance?.teardown()?.then(() => {
        setBraintreeInstance(undefined);
      });
    } else {
      initializeBraintree();
    }
  }
};

export const brainTreeInstanceHandler = params => {
  if (params?.braintreeInstance) {
    subscribeBraintreeHandler(params);
    triggerBraintreeRequestPaymentMethod(params?.braintreeInstance, params?.updateCheckoutDetails, false);
  } else if (
    !params?.braintreeInstance &&
    params?.checkoutDetails?.isClickAddPayment &&
    params?.braintreePaymentReducer?.token
  ) {
    createBraintreeInstance(params);
  }
};

/*********************************************************************
 * dispatchTransactionSaleAction
 * params - { props, dispatch, orderReducer, checkoutDetails, updateCheckoutDetails }
 * console.debug('transactionSale Response ****=====> ', response);
 *********************************************************************/
export const dispatchTransactionSaleAction = params => {
  const { props, checkoutDetails } = params;
  const { dispatch, tenantId, customerId, tender, orderId } = checkoutDetails;
  if (orderId && tender?.name) {
    dispatch(
      transactionSale(
        {
          tenantId,
          customerId,
          postBody: {
            orderId,
            paymentMethodNonce: tender?.payload?.nonce,
            deviceData: tender?.payload?.deviceData,
            tenderType: tender?.name,
          },
        },
        response => paymentTrancationSuccessHandler(props, response),
        error => paymentTrancationErrorHandler(props, error),
      ),
    );
  }
};
