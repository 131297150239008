import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '@kfc-global/react-shared/redux/Store/hooks';
import { setPWAstorage } from '@kfc-global/react-shared/redux/Slices/PwaStorageSlice';

const BrazeHandler = () => {
  const sliceDispatch = useAppDispatch();
  const [brazeEvent, setBrazeEvent] = useState(false);
  const brazeCardCount = brazeEvent && window?.appboy?.getCachedContentCards()?.cards?.length;

  const brazeSession = (brazeEvent, sliceDispatch) => {
    brazeEvent && window?.appboy?.openSession();
    sliceDispatch(setPWAstorage({ key: 'brazeInitialized', value: brazeEvent }));
  };

  useEffect(() => {
    if (window.braze) {
      window.appboy = window?.braze;
      setBrazeEvent(window?.appboy?.initialize());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window?.braze]);

  useEffect(() => {
    brazeEvent && window?.appboy?.requestContentCardsRefresh();
    brazeSession(brazeEvent, sliceDispatch);
  }, [brazeEvent, sliceDispatch, brazeCardCount]);

  return <></>;
};

export default BrazeHandler;
