import React, { useEffect } from 'react';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import PAYMENT_CONSTANTS from 'organisms/Payment/constants/PaymentConstants';
import FormComponent from 'atoms/FormComponent';
import { PAYMENT_TENDER_TYPES, FORM_FIELD_TYPE, FORM_FIELD_DATA_TYPE } from 'common/constants/SharedConstants';

const { DGFT_PAYMENT_CREDIT_DEBIT_CARD_VIEW_TEMPLATES } = PAYMENT_CONSTANTS;
export const onFormElementChangeHandler = (formConfig, fieldConfig) => {
  const fieldValues = formConfig?.getValueFromElements?.() ?? null;
  const paymentCardDetails = { ...formConfig?.checkoutDetails?.paymentCardDetails };
  if (fieldValues) {
    paymentCardDetails[fieldConfig?.name] = fieldValues?.[fieldConfig?.name];
  }
  paymentCardDetails.isFormValid = formConfig?.isFormValid;

  formConfig?.updateCheckoutDetails({
    paymentCardDetails,
    creditCardPaymentFormConfig: {
      ...formConfig?.checkoutDetails?.creditCardPaymentFormConfig,
      showSaveCardDetails: !fieldValues?.showSaveCardDetails,
    },
  });
};

const DgftCreditCardTemplate = props => {
  const { checkoutDetails, updateCheckoutDetails } = props;
  useEffect(() => {
    if (
      checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.DGFT_CREDIT_CARD &&
      checkoutDetails?.isPaymentTypeSelected &&
      !checkoutDetails?.paymentCardDetails?.isFormValid
    ) {
      updateCheckoutDetails({
        placeOrderButtonEnabled: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isPaymentTypeSelected, checkoutDetails?.paymentCardDetails?.isFormValid]);

  useEffect(() => {
    updateCheckoutDetails({
      creditCardPaymentFormConfig: {
        fieldsConfig: getFormConfigDetails(),
        isFormValid: false,
        isFormReadOnly: false,
        onFormChangeHandler: onFormElementChangeHandler,
        showSaveCardDetails: false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.DGFT_CREDIT_CARD &&
      checkoutDetails?.paymentCardDetails?.isFormValid &&
      checkoutDetails?.isPaymentTypeSelected &&
      !checkoutDetails?.cardid
    ) {
      updateCheckoutDetails({
        enableContinuePayment: false,
        isClickContinuePayment: true,
        placeOrderButtonEnabled: true,
        showPlaceOrderLoading: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.paymentCardDetails?.isFormValid, checkoutDetails?.cardid]);

  useEffect(() => {
    if (
      checkoutDetails?.isCreateOrderValidateCompleted &&
      checkoutDetails?.isPaymentTypeSelected &&
      checkoutDetails?.paymentCardDetails?.isFormValid &&
      checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.DGFT_CREDIT_CARD
    ) {
      updateCheckoutDetails({
        showPlaceOrderLoading: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkoutDetails?.isCreateOrderValidateCompleted,
    checkoutDetails?.isPaymentTypeSelected,
    checkoutDetails?.paymentCardDetails?.isFormValid,
  ]);

  return (
    <>
      <div className='col-12'>
        <div className='labelContent labelContentAddNewCard'>
          <span>{translateWithI18Next(TRANSLATE_MAPPING_KEY?.CREDIT_CARD_DETAILS)}</span>
        </div>
      </div>
      <div className='creditFormWrapper' data-test-id='creditFormWrapper'>
        <FormComponent
          formConfig={{
            ...checkoutDetails?.creditCardPaymentFormConfig,
            checkoutDetails, // optional field belogs to checkout page
            updateCheckoutDetails, // optional field belogs to checkout page
          }}
        />
      </div>
    </>
  );
};

export default React.memo(DgftCreditCardTemplate);

export const getFormConfigDetails = () => {
  const cardNumerConfig = {
    name: DGFT_PAYMENT_CREDIT_DEBIT_CARD_VIEW_TEMPLATES?.CARD_NUMBER,
    label: () => translateWithI18Next(TRANSLATE_MAPPING_KEY?.CARD_NUMBER),
    type: FORM_FIELD_TYPE?.PAYMENT_TEXT_INPUT,
    dataType: FORM_FIELD_DATA_TYPE?.CARD_NUMBER,
    rules: {
      required: { value: true, message: () => translateWithI18Next(TRANSLATE_MAPPING_KEY?.ENTER_VALID_CARD_NUMBER) },
    },
    isMandatory: false,
    onChangeHandler: onFormElementChangeHandler,
    onBlurHandler: onFormElementChangeHandler,
  };

  const cardCVVConfig = {
    name: DGFT_PAYMENT_CREDIT_DEBIT_CARD_VIEW_TEMPLATES?.CVV,
    label: () => translateWithI18Next(TRANSLATE_MAPPING_KEY?.CVV),
    type: FORM_FIELD_TYPE?.PAYMENT_TEXT_INPUT,
    dataType: FORM_FIELD_DATA_TYPE?.CARD_CVV,
    rules: {
      required: { value: true, message: () => translateWithI18Next(TRANSLATE_MAPPING_KEY?.ENTER_CVV_NUMBER) },
    },
    isMandatory: false,
    onChangeHandler: onFormElementChangeHandler,
    onBlurHandler: onFormElementChangeHandler,
  };

  const cardExpiryDateConfig = {
    name: DGFT_PAYMENT_CREDIT_DEBIT_CARD_VIEW_TEMPLATES?.EXPIRY_DATE,
    label: () => translateWithI18Next(TRANSLATE_MAPPING_KEY?.EXPIRY_DATE_YY),
    type: FORM_FIELD_TYPE?.PAYMENT_TEXT_INPUT,
    dataType: FORM_FIELD_DATA_TYPE?.CARD_EXPIRY_DATE,
    rules: {
      required: {
        value: true,
        message: () => translateWithI18Next(TRANSLATE_MAPPING_KEY?.ENTER_VALID_CARD_EXPIRY_DATE),
      },
    },
    isMandatory: false,
    onChangeHandler: onFormElementChangeHandler,
    onBlurHandler: onFormElementChangeHandler,
  };

  const cardHolderNameConfig = {
    name: DGFT_PAYMENT_CREDIT_DEBIT_CARD_VIEW_TEMPLATES?.NAME_ON_CARD,
    label: () => translateWithI18Next(TRANSLATE_MAPPING_KEY?.NAME_ON_CARD),
    type: FORM_FIELD_TYPE?.INPUT,
    pattern: DGFT_PAYMENT_CREDIT_DEBIT_CARD_VIEW_TEMPLATES?.NAME_ON_CARD_INPUT_PATTERN,
    rules: {
      required: { value: true, message: () => translateWithI18Next(TRANSLATE_MAPPING_KEY?.ENTER_YOUR_NAME_ON_CARD) },
      pattern: {
        value: DGFT_PAYMENT_CREDIT_DEBIT_CARD_VIEW_TEMPLATES?.NAME_ON_CARD_VALIDATION_REGEX,
        message: () => translateWithI18Next(TRANSLATE_MAPPING_KEY?.INVALID_NAME),
      },
    },
    isMandatory: false,
    onChangeHandler: onFormElementChangeHandler,
    onBlurHandler: onFormElementChangeHandler,
  };
  return [{ ...cardHolderNameConfig }, { ...cardNumerConfig }, { ...cardExpiryDateConfig }, { ...cardCVVConfig }];
};
