import React, { useMemo, useState } from 'react';
import ModalComponent from 'molecules/Modal';
import WelcomeBackComponent from '../Presentation/WelcomeBackComponent';
import { getTernaryValue, isMobileDevice, isValidObject } from 'common/utilities/utils';
import WELCOME_BACK_COSTANTS from '../Constants/Constants';
import { keepOrderAnalyticFn } from 'common/utilities/analyticsUtils';
import { getPWAstorage } from '@kfc-global/react-shared/redux/Slices/PwaStorageSlice';
import { useAppSelector } from '@kfc-global/react-shared/redux/Store/hooks';
import { DISPOSITIONNEW } from 'common/constants/SharedConstants';

const WelcomeBackContainer = props => {
  const { setIsReturningUser, isReturningUser, isLocalized } = props;
  const [isModalOpen, setIsModalOpen] = useState(true);

  const getPwaStorageData = useAppSelector(getPWAstorage);

  const dispositionNew = isValidObject(getPwaStorageData?.dispositionNew)
    ? getPwaStorageData?.dispositionNew
    : JSON.parse(localStorage.getItem(DISPOSITIONNEW));
  const { type: dispositionType = '', store } = dispositionNew || {};

  const analyticsStoreData = useMemo(() => {
    return {
      storeID: store?.id,
      storeName: store?.name?.[0]?.value || store?.summary?.name,
      postCode: store?.addresses?.[0]?.address?.pinCode,
      state: store?.addresses?.[0]?.address?.state,
      city: store?.addresses?.[0]?.address?.city,
      disposition: dispositionType.toLowerCase(),
    };
  }, [store, dispositionNew]);

  const closeModel = () => {
    setIsModalOpen(false);
    setIsReturningUser(false);
    keepOrderAnalyticFn({
      keepOrder: WELCOME_BACK_COSTANTS.KEEP_IT,
      analyticsData: analyticsStoreData,
    });
  };

  const modalProps = {
    id: 'welcomeBackModal',
    role: 'dialog',
    modalTitleId: 'welcomeBackModal',
    show: true,
    classNames: `Base-modal ${getTernaryValue(
      isMobileDevice(window.innerWidth),
      'welcome-back-mobile-modal',
      'welcome-back-modal',
    )}`,
    showCloseBtn: true,
    showBackBtn: false,
    closeButtonHandler: closeModel,
    modalTitle: '',
    modalTitleRequired: false,
    // backButtonHandler: handleClose,
    closeDataTestId: 'close-modal',
    autoFocus: true,
  };

  return isModalOpen && isReturningUser && isLocalized ? (
    <ModalComponent {...modalProps}>
      <WelcomeBackComponent onCancel={closeModel} setIsModalOpen={setIsModalOpen} analyticsData={analyticsStoreData} />
    </ModalComponent>
  ) : null;
};

export default WelcomeBackContainer;
