import React, { useEffect, useState } from 'react';

import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { getTernaryValue, getValueOrEmptyString, isMobileDevice } from 'common/utilities/utils';
import { loadBilldeskScript } from 'components/checkout/CheckoutUtils/CheckoutUtils';
import { MaterialTextInput } from 'atoms/MaterialInput';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { useSelector } from 'react-redux';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import { FULL_NAME_REGEX } from './Constants/checkoutConstants';
import { embeddedErrorMessageAnalyticFn } from './CheckoutFn';
import { GIFTCARD_CONSTANTS } from 'organisms/GiftCard/Constants/giftcardConstants';

const { Ellipse } = IMAGE_PATH;
const { SELECT_YOUR_PAYMENT_SCREEN_HEADER } = GIFTCARD_CONSTANTS;

export const styleForMaterialTextInput = error => {
  return error ? 'error' : '';
};

export const errorStyleForMaterialTextInput = error => {
  return error ? 'error' : ' ';
};

export const getUserData = (name, callBack) => {
  name && callBack();
};

export const isValidFullName = fullName => {
  const pattern = FULL_NAME_REGEX;
  return pattern.test(fullName);
};

export const getCardNameData = (cardNameObject, inputCardName) => {
  let cardName = { ...cardNameObject };
  cardName.value = inputCardName;
  if (!inputCardName || inputCardName.length < 2) {
    cardName.error = true;
    cardName.errorMessage = translateWithI18Next('emptyName');
  } else if (!isValidFullName(inputCardName) || inputCardName.length > 26) {
    cardName.error = true;
    cardName.errorMessage = translateWithI18Next('invalidName');
  } else {
    cardName.error = false;
    cardName.errorMessage = '';
  }
  return cardName;
};
export default function PaymentFormCreditCard({ setFormPayload, setIsValid, updateCheckoutDetails, checkoutDetails }) {
  const { formPayload } = checkoutDetails || {};
  const [cardNameState, setCardNameState] = useState({
    value: '',
    errorMessage: '',
    error: false,
  });
  const [isMobile, setIsMobile] = useState(isMobileDevice(window.innerWidth));

  useEffect(() => {
    setCardNameState({
      value: '',
      errorMessage: '',
      error: false,
    });
  }, []);

  const ERROR_MESSAGES = {
    emptyCardNumber: translateWithI18Next('emptyCardNumber'),
    invalidCardNumber: translateWithI18Next('invalidCard'),
    emptyExpiryDate: translateWithI18Next('emptyExpiry'),
    invalidExpiryDate: translateWithI18Next('invalidExpiry'),
    emptyCVC: translateWithI18Next('emptyCvv'),
    invalidCVC: translateWithI18Next('invalidCvv'),
  };
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getCardImageProps, getZIPProps } =
    usePaymentInputs({
      errorMessages: ERROR_MESSAGES,
    });
  const { erroredInputs, touchedInputs } = meta;

  const inputChangeHandler = event => {
    const { name, value } = event.target;

    getUserData(name === 'name', () =>
      setCardNameState({
        ...getCardNameData(cardNameState, value),
      }),
    );
    updateCheckoutDetails?.({
      formPayload: {
        ...formPayload,
        [name]: getValueOrEmptyString(value).trim(),
        svgSource: getCardImageProps({ images }),
      },
    });
  };

  const windowResized = () => {
    const isMobileView = isMobileDevice(window.innerWidth);
    setIsMobile(isMobileView);
  };

  let { countryCode } = useSelector(data => data.tenantReducer);

  useEffect(() => {
    loadBilldeskScript(() => {
      console.log('billdesk script has been loaded');
    });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', windowResized);
  }, [isMobile]);

  useEffect(() => {
    updateCheckoutDetails?.({
      isValid: getTernaryValue(!Object.values(erroredInputs).filter(Boolean).length, true, false),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [erroredInputs]);

  useEffect(() => {
    embeddedErrorMessageAnalyticFn(
      translateWithI18Next(SELECT_YOUR_PAYMENT_SCREEN_HEADER),
      cardNameState.error,
      cardNameState.errorMessage,
    );
  }, [cardNameState.errorMessage, cardNameState.error]);

  useEffect(() => {
    const hasCardError = touchedInputs.cardNumber && erroredInputs.cardNumber;
    embeddedErrorMessageAnalyticFn(
      translateWithI18Next(SELECT_YOUR_PAYMENT_SCREEN_HEADER),
      hasCardError,
      erroredInputs.cardNumber,
    );
  }, [touchedInputs.cardNumber, erroredInputs.cardNumber]);

  useEffect(() => {
    const hasExpiryDateError = touchedInputs.expiryDate && erroredInputs.expiryDate;
    embeddedErrorMessageAnalyticFn(
      translateWithI18Next(SELECT_YOUR_PAYMENT_SCREEN_HEADER),
      hasExpiryDateError,
      erroredInputs.expiryDate,
    );
  }, [touchedInputs.expiryDate, erroredInputs.expiryDate]);

  useEffect(() => {
    const hasCvcError = touchedInputs.cvc && erroredInputs.cvc;
    embeddedErrorMessageAnalyticFn(
      translateWithI18Next(SELECT_YOUR_PAYMENT_SCREEN_HEADER),
      hasCvcError,
      erroredInputs.cvc,
    );
  }, [touchedInputs.cvc, erroredInputs.cvc]);

  return (
    <React.Fragment>
      <div className='col-12 payment-form-credit-card-container'>
        {/* Name */}
        {!isMobile && <p className='payment-mandatory-note'>{translateWithI18Next('mandatorynote')}</p>}
        <div className='row creditCardRow'>
          <div className='col-12'>
            <MaterialTextInput
              type='text'
              required
              data-testid='material-input'
              name='name'
              placeholder={translateWithI18Next('nameOnCardText')}
              onChange={event => inputChangeHandler(event)}
              inputTestId='cc-name-handler'
              autoComplete='cc-name'
              onBlur={event => inputChangeHandler(event)}
              className={styleForMaterialTextInput(cardNameState.error)}
              labelClassName={styleForMaterialTextInput(cardNameState.error)}
              maxLength='26'
            />

            <label className={errorStyleForMaterialTextInput(cardNameState.error)}>
              {cardNameState.error && <img src={Ellipse} alt='' className={`mr-2`} />}
              <span role='alert'>{cardNameState.errorMessage}</span>
            </label>
          </div>
        </div>

        {/* Credit Card Number */}
        <div className='row creditCardRow'>
          <div className='col-12'>
            <div className='field material-input'>
              <input
                {...getCardNumberProps()}
                required
                data-testid='card-number-handler'
                className={`${getTernaryValue(erroredInputs.cardNumber && touchedInputs.cardNumber, 'error', '')}`}
                onInput={event => inputChangeHandler(event)}
                autoComplete='cc-number'
              />
              <label
                className={`${getTernaryValue(erroredInputs.cardNumber && touchedInputs.cardNumber, 'error', '')}`}
                data-testid='card-number-handler'
                htmlFor='cardNumber'
              >
                {translateWithI18Next('cardNumberText')}
              </label>
            </div>
            <label className={`${getTernaryValue(erroredInputs.cardNumber && touchedInputs.cardNumber, 'error', '')}`}>
              {touchedInputs.cardNumber && erroredInputs.cardNumber && <img src={Ellipse} alt='' className={`mr-2`} />}
              <span role='alert'>{touchedInputs.cardNumber && erroredInputs.cardNumber}</span>
            </label>
            <div className='card-number-image'>
              <svg {...getCardImageProps({ images })} />
            </div>
          </div>
        </div>

        {/* Exp Month and Year & CVV */}
        <div className='row creditCardRow'>
          <div className='col-6'>
            <div className='field material-input'>
              <input
                {...getExpiryDateProps()}
                id='expiryDate'
                required
                onInput={event => inputChangeHandler(event)}
                data-testid='expiry-date-handler'
                className={`${getTernaryValue(erroredInputs.expiryDate && touchedInputs.expiryDate, 'error', '')}`}
                autoComplete='cc-exp'
              />
              <label
                className={`${getTernaryValue(erroredInputs.expiryDate && touchedInputs.expiryDate, 'error', '')}`}
                data-testid='expiry-date-handler'
                htmlFor='expiryDate'
              >
                {translateWithI18Next('mmddText')}
              </label>
            </div>
            <label className={`${getTernaryValue(erroredInputs.expiryDate && touchedInputs.expiryDate, 'error', '')}`}>
              {touchedInputs.expiryDate && erroredInputs.expiryDate && <img src={Ellipse} alt='' className={`mr-2`} />}
              <span role='alert'>{touchedInputs.expiryDate && erroredInputs.expiryDate}</span>
            </label>
          </div>
          <div className='col-6'>
            <div className='field material-input'>
              <input
                {...getCVCProps()}
                id='cvvData'
                required
                data-testid='cvv-handler'
                className={`${erroredInputs.cvc && touchedInputs.cvc ? 'error' : ''}`}
                onInput={event => inputChangeHandler(event)}
                autoComplete='cc-csc'
                type='password'
              />
              <label
                className={`${erroredInputs.cvc && touchedInputs.cvc ? 'error' : ''}`}
                data-testid='cvv-handler'
                htmlFor='cvvData'
              >
                {translateWithI18Next('cvvText')}
              </label>
            </div>
            <label className={`${erroredInputs.cvc && touchedInputs.cvc ? 'error' : ''}`}>
              {touchedInputs.cvc && erroredInputs.cvc && <img src={Ellipse} alt='' className={`mr-2`} />}
              <span role='alert'>{touchedInputs.cvc && erroredInputs.cvc}</span>
            </label>
          </div>
        </div>

        {/* Billing Postal Code */}
        {countryCode[0] !== 'in' && (
          <div className='row'>
            <div className='col-6'>
              <div className='field material-input'>
                <input
                  {...getZIPProps()}
                  id='zip'
                  required
                  onInput={event => inputChangeHandler(event)}
                  data-testid='zip-handler'
                  className={`${erroredInputs.zip && touchedInputs.zip ? 'error' : ''}`}
                  autoComplete='off'
                />
                <label
                  className={`${erroredInputs.zip && touchedInputs.zip ? 'error' : ''}`}
                  data-testid='zip-handler'
                  htmlFor='zip'
                >
                  {translateWithI18Next('billingPostalCodeText')}
                </label>
              </div>
              <label className={`${erroredInputs.zip && touchedInputs.zip ? 'error' : ''}`}>
                {touchedInputs.zip && erroredInputs.zip && <img src={Ellipse} alt='' className={`mr-2`} />}
                <span role='alert'>{touchedInputs.zip && erroredInputs.zip}</span>
              </label>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
