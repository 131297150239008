import { BILLDESK_SCRIPT_ID, BILLDESK_SDK_URL, JQUERY_URL, JQUERY_SCRIPT_ID } from 'common/constants/SharedConstants';
import { config } from '@kfc-global/react-shared/config/config.utils';
import { BILLDESK_BASE_URL } from '@kfc-global/react-shared/config/config.constants';
import { loadScript } from 'common/utilities/utils';

export const onClickContinuePayment = ({
  isClickContinuePayment,
  subscription,
  isLoggedIn,
  GuestContactDetailsClick,
  updatePaymentAnalytics,
  cartData,
  currencyConversionFactor,
  tender,
  productMapById,
  updateCheckoutDetails,
  dispatchCreateOrderAction,
  dispatch,
  createOrderRequest,
}) => {
  if (isClickContinuePayment) {
    if (subscription && !isLoggedIn) {
      GuestContactDetailsClick();
    }
    updateCheckoutDetails?.({ isValid: false });
    dispatchCreateOrderAction(dispatch, tender, cartData, createOrderRequest);
  }
};

export const loadBilldeskScript = callback => {
  const existingScript = document?.getElementById(BILLDESK_SCRIPT_ID);
  if (!existingScript) {
    const billdeskSdkUrl = config(BILLDESK_BASE_URL) + BILLDESK_SDK_URL;
    if (!window?.jQuery) {
      loadScript(JQUERY_URL, JQUERY_SCRIPT_ID, () => {
        loadScript(billdeskSdkUrl, BILLDESK_SCRIPT_ID, callback);
      });
    } else {
      loadScript(billdeskSdkUrl, BILLDESK_SCRIPT_ID, callback);
    }
  }
  existingScript && callback?.();
};
