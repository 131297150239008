import React, { useEffect } from 'react';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import PAYMENT_CONSTANTS from 'organisms/Payment/constants/PaymentConstants';
import FormComponent from 'atoms/FormComponent';
import isEmpty from 'lodash/isEmpty';
import { PAYMENT_TENDER_TYPES, FORM_FIELD_TYPE } from 'common/constants/SharedConstants';

const { TRUE_MONEY_WALLET_CONSTANTS } = PAYMENT_CONSTANTS;
const { MOBILE_NUMBER } = TRUE_MONEY_WALLET_CONSTANTS;

export const onFormElementChangeHandler = (formConfig, fieldConfig) => {
  const fieldValues = formConfig?.getValueFromElements?.() ?? null;
  const validPhoneNumber = { ...formConfig?.checkoutDetails?.validPhoneNumber };
  if (fieldValues) {
    validPhoneNumber[fieldConfig?.name] = fieldValues?.[fieldConfig?.name];
  }
  validPhoneNumber.isFormValid = formConfig?.isFormValid;

  formConfig?.updateCheckoutDetails({
    validPhoneNumber,
    phoneNumberFormConfig: {
      ...formConfig?.checkoutDetails?.phoneNumberFormConfig,
    },
  });
};

export const OnformClearIcon = formConfig => {
  return !isEmpty(formConfig?.getValueFromElements?.()?.[MOBILE_NUMBER]);
};

export const OnformClearText = formConfig => formConfig?.setValueToFormElements(MOBILE_NUMBER, '');

const TrueMoneyWalletTemplate = props => {
  const { checkoutDetails, updateCheckoutDetails } = props;
  useEffect(() => {
    if (
      checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.TRUE_MONEY_WALLET &&
      checkoutDetails?.isPaymentTypeSelected &&
      !checkoutDetails?.validPhoneNumber?.isFormValid
    ) {
      updateCheckoutDetails({
        placeOrderButtonEnabled: false,
      });
    } else if (checkoutDetails?.validPhoneNumber?.isFormValid) {
      updateCheckoutDetails({
        placeOrderButtonEnabled: true,
        paymentEnabled: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isPaymentTypeSelected, checkoutDetails?.validPhoneNumber?.isFormValid]);

  useEffect(() => {
    updateCheckoutDetails({
      phoneNumberFormConfig: {
        fieldsConfig: getFormConfigDetails(),
        isFormValid: true,
        isFormReadOnly: false,
        onFormChangeHandler: onFormElementChangeHandler,
        placeOrderButtonEnabled: false,
        // defaultValues: { mobileNumber: checkoutDetails?.userPhone?.value },
      },
      validPhoneNumber: {
        mobileNumber: checkoutDetails?.userPhone?.value,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='truemoneywrapper' data-test-id='truemoneywrapper-id'>
        <p className='truemoney-wallet-subtitle'>{translateWithI18Next(TRANSLATE_MAPPING_KEY?.TMW_PHONE_SUBTITLE)}</p>
        <FormComponent
          formConfig={{
            ...checkoutDetails?.phoneNumberFormConfig,
            checkoutDetails,
            updateCheckoutDetails,
            defaultValues: { mobileNumber: checkoutDetails?.userPhone?.value },
          }}
        />
      </div>
    </>
  );
};

export default React.memo(TrueMoneyWalletTemplate);

export const getFormConfigDetails = () => {
  const mobileNumnerConfig = {
    name: MOBILE_NUMBER,
    label: () => translateWithI18Next(TRANSLATE_MAPPING_KEY?.PHONE_NUMBER),
    type: FORM_FIELD_TYPE?.MASK_INPUT,
    pattern: TRUE_MONEY_WALLET_CONSTANTS?.MOBILE_NUMBER_REGX,
    rules: {
      required: { value: true, message: () => translateWithI18Next(TRANSLATE_MAPPING_KEY?.EMPTY_PHONE_NUMBER_ERR_MSG) },
      pattern: {
        value: TRUE_MONEY_WALLET_CONSTANTS?.MOBILE_NUMBER_REGX,
        message: () => translateWithI18Next(TRANSLATE_MAPPING_KEY?.INVALID_PHN_NUMBER),
      },
    },
    phoneNumberMask: TRUE_MONEY_WALLET_CONSTANTS?.PHONE_NUMBER_MASK,
    isMandatory: false,
    onChangeHandler: onFormElementChangeHandler,
    onBlurHandler: onFormElementChangeHandler,
    showClearTextInputBtn: OnformClearIcon,
    onClearButtonClickHandler: OnformClearText,
    maxLength: TRUE_MONEY_WALLET_CONSTANTS?.MOBILE_NUMBER_MAX_LENGTH,
  };
  return [{ ...mobileNumnerConfig }];
};
