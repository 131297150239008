import React, { useEffect, useRef } from 'react';
import { TooltipView } from 'atoms/Tooltip/index';
import { CHECKOUT_CONSTANTS } from 'common/constants/SharedConstants';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';

const inputFieldType = (dataType, type) => {
  return dataType === '' ? type : dataType;
};

const inputMode = (dataType, type) => {
  return (dataType || type) === 'number' ? 'numeric' : 'text';
};

export const MaterialTextInput = props => {
  const {
    id = null,
    placeholder = '',
    labelClassName = '',
    inputClassName = '',
    helperTextClassName = '',
    containerClassName = '',
    inputTestId = '',
    labelTestId = '',
    onKeyPressHandler = null,
    error = false,
    helperText = '',
    ariaLabel,
    ariaRequired = '',
    autoComplete = '',
    toolTipText = '',
    toolTipSrc = '',
    errorIcon = '',
    errorIconClassName,
    isMandatory = false,
    setFocus = false,
    showCurrencySymbol = false,
    prefix = '',
    currencySymbol = '',
    readOnly = false,
    innerRef = null,
    dispalyErrorMessage = true,
    dataType = '',
    showImageField = false,
    ImageSelectedForInput,
    ...rest
  } = props;
  const inputRef = useRef(null);

  useEffect(() => {
    setFocus && inputRef?.current?.focus();
  }, [setFocus]);

  return (
    <div
      className={`field material-input ${containerClassName}`}
      name={`mt-cont-${props?.id || props?.name || placeholder}`}
    >
      {dispalyErrorMessage && error && (
        <span className={helperTextClassName} role='alert'>
          {errorIcon ? (
            <div className={errorIconClassName}>
              <div>
                <img src={errorIcon} alt='Error visible' />
              </div>
              <div>{helperText}</div>
            </div>
          ) : (
            helperText
          )}
        </span>
      )}

      {prefix && <span className='currency-field'>{prefix}</span>}

      {showImageField && <img src={ImageSelectedForInput} alt='red arrow' className='currency-field' />}

      {showCurrencySymbol && <span className='currency-field'>{currencySymbol}</span>}

      <input
        ref={innerRef || inputRef}
        className={inputClassName}
        id={`mt-input-${props?.id || props?.name || placeholder}`}
        placeholder={placeholder}
        onKeyDown={onKeyPressHandler}
        data-testid={inputTestId}
        aria-required={ariaRequired}
        autoComplete={autoComplete}
        {...rest}
        name={`${props?.id || props?.name || placeholder}`}
        readOnly={readOnly}
        type={inputFieldType(props?.type, dataType)}
        inputMode={inputMode(props?.type, dataType)}
      ></input>

      <label data-testid={labelTestId} className={labelClassName} htmlFor={`mt-input-${props?.name || placeholder}`}>
        {placeholder}
        {isMandatory && CHECKOUT_CONSTANTS.STAR}
      </label>

      {toolTipText && (
        <span className='tool-tip-position'>
          <TooltipView placement='top' title={toolTipText}>
            <img className='tooltip-view' src={toolTipSrc} alt='tooltip' />
          </TooltipView>
        </span>
      )}

      {props?.showClearTextInputBtn && (
        <img
          src={IMAGE_PATH?.clearTextIcon}
          name={`${props?.name}-clear-text-btn`}
          alt='clear-text-btn'
          data-testid='clear-text-btn'
          className='cleariconsection'
          onClick={event => props?.onClearButtonClickHandler?.({ event, name: `${props?.name}-clear-text-btn` })}
        />
      )}
      {props?.showEditTextInputBtn && (
        <div data-testid='edit-btn' className='editsection' onClick={() => props?.onEditButtonClickHandler()}>
          {translateWithI18Next(TRANSLATE_MAPPING_KEY?.EDIT)}
        </div>
      )}
    </div>
  );
};
