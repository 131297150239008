import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/**
 * On Key Press Handler
 * @param {*} children      - children content to show
 * @param {*} id            - unique identifier and key for multiple item
 * @param {*} title         - title to show on hover and tap
 * @param {*} placement     - placement of title i.e. top/bottom/left/right
 */
export const TooltipView = ({ children, id, title, placement = 'top', defaultShow = false }) => {
  return (
    <>
      <OverlayTrigger
        key={id}
        placement={placement}
        defaultShow={defaultShow}
        overlay={<Tooltip id={`tooltip-${id}`}>{title}</Tooltip>}
      >
        {children}
      </OverlayTrigger>
    </>
  );
};
