import { useEffect } from 'react';

function PaymentFormNetBankingOtherWalletUPIBilldesk({ requestParameters, postUrl }) {
  useEffect(() => {
    postUrl && document?.bankForm?.submit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document && document?.bankForm, postUrl]);

  const dynamicRequestParameters = () => {
    const returnParam = [];
    for (let key in requestParameters) {
      returnParam.push(<input type='hidden' name={key} value={requestParameters[key]}></input>);
    }
    return returnParam;
  };

  return (
    <div>
      {postUrl && (
        <form name='bankForm' method='POST' action={postUrl}>
          {requestParameters && dynamicRequestParameters()}
        </form>
      )}
    </div>
  );
}

export default PaymentFormNetBankingOtherWalletUPIBilldesk;
