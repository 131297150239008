export const ACCOUNT_SETTINGS = {
  ACCOUNT_SETTINGS_TEXT: 'accoundSetting',
  DELETE_ACCOUNT_TEXT: 'deleteAccount',
  EDIT_PERSONAL_INFO: 'editPersonalInfo',
  FIRST_NAME_TEXT: 'firstNamePlaceholder',
  LAST_NAME_TEXT: 'lastNamePlaceHolder',
  EMAIL_TEXT: 'emailPlaceholder',
  PHONE_NUMBER_TEXT: 'phonePlaceholder',
  CANCEL: 'cancelButtonText',
  SAVE: 'save',
  PERSONAL_INFO_TEXT: 'personalInformation',
  EDIT: 'edit',
  CONFIRM: 'confirm',
  emailHelpText: 'emailHelpText',
  phoneHelpText: 'phoneHelpText',
  firstNameHelpText: 'firstNameHelpText',
  lastNameHelpText: 'lastNameHelpText',

  DELETE_ACCOUNT_BTN: 'deleteAccount', //'Delete Account',
  DELETE_ACCOUNT_MESSAGE: 'areYouSureToDeleteAccountText', //'Are You sure you want to delete your Account?',
  SUBSCRIBTION_MSG_TEXT: 'subscribedToastMessage', // 'You have successfully subscribed',
  UNSUBSCRIBTION_MSG_TEXT: 'unsubscribedToastMessage', //You have successfully unsubscribed',
  SIGN_OUT: 'signOut',
  SIGN_OUT_ACCOUNT_MESSAGE: 'accountSignOutMessage', //'ARE You SUre you WANT TO Sign oUt of your Account?',
  EDIT_PERSONAL_INFO_TEXT: 'editPersonalInfo', //'Edit Personal Info',
  EXCEEDED_VERIFICATION_ATTEMPTS: 'exceededVerificationAttempts', // 'You’ve exceeded the maximum verification code attempts (3/3).'

  NAME: 'labelName', //NAME
  EMAIL_ADDRESS: 'optionalEmailAddress', //Email Address',
  MOBILE_NUMBER: 'mobileNumber', //'Mobile Number',
  DATE_OF_BIRTH: 'dateOfBirth', //'Date of Birth',
  POSTAL_CODE: 'Postcode', //'Postcode',
  DATE_OF_BIRTH_PERSONALINFOCARD: 'dateOfBirthPersonalInfoCard',
  FIRST_NAME: 'firstNameLabel', //'First Name',
  LAST_NAME: 'lastNameLabel', //'Last Name',
  FIRST_NAME_ERROR_TEXT: 'firstNameErrorMessage', //'Please enter a valid first name.',
  LAST_NAME_ERROR_TEXT: 'lastNameErrorMessage', //Please enter a valid last name.',
  EMAIL_ERROR_TEXT: 'emailHelpText', //'Please enter a valid email',
  MOBILE_NUMBER_ERROR_TEXT: 'mobileErrorMessage', //'Please enter a valid mobile number',
  POSTAL_CODE_ERROR_TEXT: 'postalcodeErrorMessage', //'Please enter a valid Australian postcode',
  PHONE_TOOLTIP_TITLE: 'mobileToolTipMessage', //'Phone number required for service based messaging around your order.',
  DOB_TOOLTIP_TITLE: 'dobToolTipMessage', //'For future birthday rewards on us! Once saved this cannot be changed.',
  DATE_MONTH_ERROR_TEXT: 'valideDayAndMonthRequired', //'Valid Day, Month are required to submit',
  DATE_MONTH_YEAR_ERROR_TEXT: 'valideDayMonthAndYearRequired', //'Valid Day, Month and Year are required to submit',
  DAY_MONTH_YEAR_ERROR_MESSAGE: 'valideDayMonthAndYearRequired',
  YOUR_INFO_SAVED: 'savedMessage',
  EDIT_WITH_OLD_PHONE: 'fromEditWithOldPhone',
  FROM_OTP_SCREEN: 'fromOtpScreen',
  ACCESS_TOKEN: 'access_token',
  EVENT_NAME_PHONE: 'phone',
  EVENT_NAME_POSTAL_CODE: 'postalCode',
  KATAKANA_NAME: 'katakanaName',
  ANNIVERSARY_DETAILS: 'anniversaryDetails',
  ANNIVERSARY_NAME: 'anniversaryName',
  ANNIVERSARY_DATE: 'anniversaryDate',
  ANNIVERSARY_NAME_ERROR_TEXT: 'anniversaryNameErrMessage', //'Please enter a valid anniversary name.'
  ADD_ANNIVERSARY_BTN: 'addAnotherAnniversary',
  ANNIVERSARY_DAY: 'anniversaryDay',
  ANNIVERSARY_MONTH: 'anniversaryMonth',
};

export const preferencesData = [
  {
    mainText: 'Keep me up to date with marketing emails from KFC',
    subText: '',
    checked: true,
  },
  {
    mainText: 'Editor’s Pick & Customer Favorite ',
    subText: 'Receive Editor updates on new and upcoming releases and the best reviews from customers like you',
    checked: true,
  },
  {
    mainText: 'KFC news and announcements ',
    subText: 'Get occasional emails about site improvements or changes ',
    checked: true,
  },
  {
    mainText: 'Do not sell my personal information',
    subText: '',
    checked: true,
  },
];

export const COMMUNICATION_PREFERENCES_CONSTANTS = {
  SAVE: 'save',
  SUBSCRIBE: 'subscribe',
  UNSUBSCRIBE: 'unsubscribe',
  MARKETING_SMS: 'sms',
  MARKETING_EMAIL: 'email',
};

export const HTTPS_URL = 'https://';

export const FORM_HEADING = {
  FIELD_NAME: {
    NAME: 'name',
    FLOOR: 'floor',
    CHECKBOX: 'checkbox',
    DELIVERY_OPTION: 'deliveryOptions',
  },
  ADDRESS: 'addressLabel',
  ADDRESS_NICK_NAME: 'Address Nickname',
  APT_SUITE_FLOOR: 'aditionalAddress',
  POSTAL_CODE: 'postalCode',
  CITY_TOWN: 'city_town',
  STATE: 'state',
  MAKE_THIS_DEFAULT_ADD: 'makeDefaultAddress',
  DELIVERY_OPTION: 'savedDeliveryOptions',
  DELIVERY_INSTRUCTION: 'deliveryInstructionAddress',
  PIN_CODE: 'pinCode',
};

export const ADDRESS_STORE_CONSTANTS = {
  DELIVERY_MAX_LENGTH: 100,
  TH_DELIVERY_MAX_LENGTH: 200,
  ADD_NEW_ADDRESS: 'addNewAddress',
  EDIT_DELIVERY_ADDRESS: 'editAddress',
  HIDDEN: 'hidden',
  OK: 'OK',
  KM: 'km',
  DEFAULT_DISPOSITION_PICKUP: 'Pickup',
  DISPOSITION_DELIVERY: 'Delivery',
  mapText: 'map',
  ZERO_RESULTS: 'ZERO_RESULTS',
  NO_ADDRESS_FOUND: 'No address found for the provided input',
  DELIVERY_SERVICE: 'delivery',
  CHANNEL: 'web',
  AUSTRALIA: 'Australia',
  UNDEFINED: 'undefined',
  NOT_SERVICEABLE: 'deliveryLocationUnavailable',
  INITIAL_STATE: {
    addressId: '',
    city: '',
    pinCode: '',
    name: '',
    state: '',
    country: '',
    isPrimary: false,
    location: {
      latitude: '',
      longitude: '',
    },
    addressLines: ['', ''],
    isSelectedAddress: false,
    isFlatNumberSelected: false,
    deliveryInstructions: '',
    isLocationButtonClicked: false,
    results: [],
    isConfirmButtonClicked: false,
  },
  MYDELIVERYADDRESSES: 'MyDeliveryAddresses',
  ADDRESS: 'address',
  MY_FAV_STORES: 'myFavStores',
  DELETE: 'delete',
  EDIT: 'edit',
  ADDRESS_UPDATED: 'addressUpdated',
  ADDRESS_SAVED: 'addressSaved',
  DELIVERY_ADD_DELETE: 'deliveryAddressDeleted',
  SPECIAL_CHAR_ERROR: 'nameIsInvalid',
  NAME_EXIST_ERROR: 'nameExistError',
  ADDRESS_ERROR_MSG: 'addressErrorMsg',
  ADD: 'add',
  DEFAULT: 'default',
  MAX_DELIVERY_DISTANCE: 3600,
  NO_OF_STORE_FOR_DELIVERY: 2,
};

export const OTP_SCREEN = {
  SUBMIT: 'submit',
  SUBMIT_BUTTON_TEXT: 'submitButtonText',
  SENT_AN_SMS: 'sms', //'WE JUST SENT YOU AN SMS',
  OTP_SENT_TEXT: 'verificationMessage', //'Please enter the verification code we just sent to',
  CODE_EXPIRED_TEXT: 'otpCodeExpiredMessage', //'Your code has expired!',
  RESEND_CODE: 'resendCode', //'Resend code'
  INVALID_OTP: 'otpInvalidMessage',
  PAYMENT_TAKEN: 'paymentTaken',
  PAYMENT_SUBMITTED: 'paymentSubmitted',
  PASSCODE: 'passCode',
  CODE_INVALID: 'codeInvalid',
  USE_A_DIFFERENT_NUMBER: 'useDifferentNo',
  INVALID_CODE: 'codeInvalid',
  VERIFICATION_CODE_EXPIRED: 'verificationCodeExpired',
  CODE_TIMER_EXPIRED: 'codeExpiredTimer',
  TIMER_EXPIRED_TEXT: 'codeExpireMessage', // Code expires in
};
export const USER_FORM_FIELDS = ['firstName', 'lastName', 'phone', 'dateOfBirth', 'email', 'postalCode'];
