import React from 'react';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import DeleteCardInsideModel from 'molecules/Alert/Container/AlertFormInsideModel';
import AlertForm from 'molecules/Alert/Presentation/Alert/AlertForm';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import { redirectToAddNewCard } from 'common/utilities/utils';
import { ContainedButton, ButtonType } from 'atoms/Buttons/Presentation/ContainedButton';
import ImageComponent from 'atoms/Image';
import { getCardIconFromContentFul } from '../../utils/myPaymentMethodsUtils';
import { PAYMENT_GATEWAYS } from 'common/constants/SharedConstants';

const { Error } = IMAGE_PATH;

export const displayAllCardsTemplateJSX = (savedCards, deleteCardPopup, allCardsTypesList, paymentProvider = null) => {
  return (
    <div className='payment-methods-allCardsContainer' data-testid='all-cards-template'>
      {savedCards?.map(card => {
        return singleCardTemplateJSX(card, deleteCardPopup, allCardsTypesList, paymentProvider);
      })}
    </div>
  );
};

const getPaymentSingleCardContainer = cardDetails => {
  return `${cardDetails?.expiryDate.substring(0, 2)}/${cardDetails?.expiryDate.substring(
    cardDetails?.expiryDate?.length - 2,
    cardDetails?.expiryDate?.length,
  )}`;
};

const getFormattedCardNumber = (paymentProvider, card) => {
  return paymentProvider === PAYMENT_GATEWAYS?.THAILAND_PAYMENTS && card?.noFormattedMaskedCardNumber
    ? `${card?.noFormattedMaskedCardNumber?.substring(card?.noFormattedMaskedCardNumber.length - 4)}`
    : `${card?.maskedCardNumber?.replace(/x/gi, '')}`;
};

export const singleCardTemplateJSX = (card, deleteCardPopup, allCardsTypesList, paymentProvider = null) => {
  return (
    <div key={card?.id} className='payment-methods-singleCardContainer'>
      <div
        className='payment-methods-singleCardContainer-firstRow'
        data-testid='payment-methods-singleCardContainer-firstRow'
      >
        <img className='card_icon' src={getCardIconFromContentFul(allCardsTypesList, card?.type)} alt='card-imgage' />
        <span>{getFormattedCardNumber(paymentProvider, card)}</span>
        <span data-testid={card?.id} onClick={() => deleteCardPopup(card?.id)}>
          {translateWithI18Next(TRANSLATE_MAPPING_KEY?.DELETE)}
        </span>
      </div>
      {/* Below empty div with className payment-methods-singleCardContainer-secondRow is to display param Nickname on card which is not available in card response  */}
      <div className='payment-methods-singleCardContainer-secondRow'>
        <span>{card?.cardNickName}</span>
      </div>
      {/* Below empty span with className payment-methods-singleCardContainer-thirdRow is to display param Fullname on card which is not available in card response  */}
      <div
        className={`payment-methods-singleCardContainer-thirdRow ${
          PAYMENT_GATEWAYS?.THAILAND_PAYMENTS ? 'nameOnCardTH' : ''
        }`}
      >
        <span>{card?.nameOnCard}</span>
      </div>
      <div className='payment-methods-singleCardContainer-fourthRow'>
        <span>
          {card?.expiryDate &&
            `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.EXP)} ${
              card?.expiryDate?.length > 0 ? getPaymentSingleCardContainer(card) : 'NA'
            }`}
        </span>
      </div>
    </div>
  );
};

export const noSavedCardsTextJSX = () => {
  return (
    <div data-testid='no-cards-template' className='no-cards-template'>
      <span>{translateWithI18Next(TRANSLATE_MAPPING_KEY?.NO_SAVED_CARDS)}</span>
    </div>
  );
};

export const DeleteCardConfirmModal = props => {
  const { handleClose, deleteCardHandler } = props;
  const { DELETE_CARD, CANCEL, CARD_DELETE_CONFIRMATION } = TRANSLATE_MAPPING_KEY;
  const CARD_DELETE_CONSTANTS = {
    CONFIRM_CARD_DELETE_TEXT: {
      MSGTXT1: translateWithI18Next(CARD_DELETE_CONFIRMATION),
    },
  };

  return (
    <React.Fragment>
      <DeleteCardInsideModel
        handleClose={handleClose}
        classNames='delete-account-modal my-payment-methods-container'
        CartAlertForm={
          <AlertForm
            splitTitle={CARD_DELETE_CONSTANTS.CONFIRM_CARD_DELETE_TEXT}
            imageSrc={IMAGE_PATH.PictogramAlertIcon}
            altText={'delete-card'}
            cancelButtonText={translateWithI18Next(CANCEL)}
            confirmButtonText={translateWithI18Next(DELETE_CARD)}
            confirmHandler={deleteCardHandler}
            cancelHandler={handleClose}
          />
        }
      />
    </React.Fragment>
  );
};

export const getAddNewCreditAndDebitCard = props => {
  return (
    <>
      {props?.saveCardsList?.length >= props?.maxSavedCardLimit && reachedMaxmiumSavedCardWarning()}
      <div class='add-newcard'>
        <ContainedButton
          className='mt-10 button font-bold whiteButton blackBorder'
          variant={ButtonType.tertiary}
          inactive={props?.saveCardsList?.length >= props?.maxSavedCardLimit && true}
          aria-label='Close'
          data-testid='add-new-card-btn'
          onClick={() => redirectToAddNewCard(props)}
        >
          {translateWithI18Next(TRANSLATE_MAPPING_KEY?.ADD_NEW_CARD)}
        </ContainedButton>
      </div>
    </>
  );
};

export const reachedMaxmiumSavedCardWarning = props => {
  return (
    <div className='maximunCardWarning-container'>
      <div className='maximunCardWarning'>
        <ImageComponent srcFile={Error} className='location-alert-icon' />
        <span>{translateWithI18Next(TRANSLATE_MAPPING_KEY?.MAX_SAVED_CARD_LIMIT)}</span>
      </div>
    </div>
  );
};
