import React, { useEffect } from 'react';
import { dispatchConfigDynamicApiRequest, dispatchLoadScriptForKbankPayment } from '../utils/ThailandPaymentUtils';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';

const KBankPaymentCreditDebitCardForm = props => {
  const { checkoutDetails } = props;

  useEffect(() => {
    dispatchConfigDynamicApiRequest(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (checkoutDetails?.publicTokenApiKey) {
      dispatchLoadScriptForKbankPayment(checkoutDetails?.publicTokenApiKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.publicTokenApiKey]);

  const getKBankPaymentCreditCardForm = () => (
    <div class='kbank-content'>
      <div class='payment-block'>
        <p id='error-summary'>{TRANSLATE_MAPPING_KEY?.K_INLINE_PAYMENT_ERROR}</p>
        <div class='flied'>
          <label class='label'>
            <label class='asterisk'></label>
            {translateWithI18Next(TRANSLATE_MAPPING_KEY?.NAME_ON_CARD)}
          </label>
          <div id='card-name'></div>
          <label>{translateWithI18Next(TRANSLATE_MAPPING_KEY?.INVALID_NAME)}</label>
        </div>

        <div class='flied'>
          <label class='label'>{translateWithI18Next(TRANSLATE_MAPPING_KEY?.CARD_NUMBER)}</label>
          <div id='card-number'></div>
          <label>{translateWithI18Next(TRANSLATE_MAPPING_KEY?.INVALID_CARD_NUMBER)}</label>
        </div>

        <div class='flied'>
          <label class='label'>{translateWithI18Next(TRANSLATE_MAPPING_KEY?.EXPIRY_DATE)}</label>
          <div id='card-expiry'></div>
          <label>{translateWithI18Next(TRANSLATE_MAPPING_KEY?.ENTER_VALID_CARD_EXPIRY_DATE)}</label>
        </div>

        <div class='flied'>
          <label class='label'>{translateWithI18Next(TRANSLATE_MAPPING_KEY?.CVV)}</label>
          <div id='card-cvv'></div>
          <label>{translateWithI18Next(TRANSLATE_MAPPING_KEY?.INVALID_CVV_NUMBER)}</label>
        </div>
        {checkoutDetails?.userParams?.loggedIn && (
          <div class='flied'>
            <div id='remember-card'></div>
          </div>
        )}
      </div>
    </div>
  );

  return <>{getKBankPaymentCreditCardForm()}</>;
};

export default React.memo(KBankPaymentCreditDebitCardForm);
