export const GIFTCARD_CONSTANTS = {
  INSUFFICIENT_BALANCE: 'insufficientBalance',
  INVALID_CARD_NUMBER: 'invalidcardNumber',
  GIFTCARD_ERROR: 'emptyCardNumber',
  PINNUMBER_ERROR: 'emptyPin',
  INVALID_PIN_NUMBER: 'invalidPin',
  BALANCEBUTTON_TEXT: 'Check Balance',
  RECP_EMAIL_PLACEHOLDER: 'recipientemailplaceholder',
  NAME_PLACEHOLDER: 'usernameplaceholder',
  YOUR_EMAIL_PLACEHOLDER: 'useremailplaceholder',
  PHONE_PLACEHOLDER: 'userphoneplaceholder',
  MESSAGE_PLACEHOLDER: 'messageplaceholder',
  CANCELBUTTON_TEXT: 'Cancel',
  PAYMENTBUTTON_TEXT: 'Continue to Payment',
  CARD_NUMBER_PLACEHOLDER: 'cardNumberText',
  PIN_NUMBER_PLACEHOLDER: 'pinNumber',
  BALANCE_PLACEHOLDER: 'balance',
  MANDATORY_NOTE: 'mandatorynote',
  CUSTOM: 'custom',
  ACTIVE: 'Active',
  INACTIVE: 'InActive',
  FORM_VALUES: {
    RECP_EMAIL: 'recpt_mail',
    USER_FULL_NAME: 'user_fullName',
    USER_EMAIL: 'user_email',
    USER_PHONE: 'user_phone',
    MESSAGE: 'message',
  },
  MESSAGE_HEADING: 'MESSAGE',
  INFOMATION_HEADING: 'INFORMATION',
  EMAIL_REGEX: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
  CARD_NUMBER_REGEX: /[0-9]/,
  NAME_REGX: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/,
  CHECKBALANCE_MODAL: {
    GIFTCARD_HEADER: 'giftCardInformation',
    GIFTCARDNUM_INPUT: 'giftcardNumber',
    GIFTCARDPN_INPUT: 'pinNumber',
    //EMAIL_ADDR: 'emailAddress',
    EMAIL_ADDR: 'Email Address',
    CANCEL_BUTTONT: 'cancel',
    SUBMIT_BUTTON: 'Submit',
    BALANCE_TEXT: 'Balance:',
    CHECKBALANCE_BUTTON: 'checkBalance',
    RESET_BUTTON: 'Reset',
    INVALID_MODALTITLE: 'ecard',
    INVALID_MODALCONTENT: 'invalidcardNumber',
    INVALID_OKBUTTON: 'OK',
  },
  SHOWBALANCE_MODAL: {
    GIFTCARDNUM_SHOW: 'giftCardnum',
    GIFTCARDNUM_SHOWSHOW: 'giftpinNum',
    //EMAIL_SHOW: 'emailAddress'
    EMAIL_SHOW: 'Email Address',
  },
  PURCHASESUCCESS_MODAL: {
    CONGRATS_TEXT: 'Congratulation',
    FEEDBACK_MESSAGE: 'giftCardPurchageMsg',
    BUY_NEWGIFTCARD: 'buyGiftCard',
  },
  PURCHASEFAILURE_MODAL: {
    FAILURE_TEXT: 'purchasefailureheader',
    FAILURE_MESSAGE: 'purchasefailuremsg',
    RETRY: 'failureretry',
  },
  PAYMENT_FAILED_MESSAGE: 'paymentdeclined',
  SELECT_YOUR_PAYMENT_SCREEN_HEADER: 'selectYourPaymentScreenHeader',
  GIFT_CARD_AMOUNT: 'giftCardAmount',
  GIFT_CARD_AMOUNT_OTHER: 'giftCardAmountOther',
  CONFIRM_AMOUNT_BUTTON: 'confirmAmountButton',
  ADD_ANOTHER_GIFT_CARD: 'addanotherGiftCard',
};
