import React from 'react';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { FORM_HEADING } from 'organisms/AccountPage/AU/Constants/Constants';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import PAYMENT_CONSTANTS from 'organisms/Payment/constants/PaymentConstants';
import { validateBillingAddress, validateBillingPostalCode } from 'common/utilities/PaymentUtils';

export const BillingAddressTemplate = parms => {
  const { handleInputBilling, addresses, checkoutDetails } = parms;
  const { ADDRESS, POSTAL_CODE } = FORM_HEADING;
  const { GLOBAL_PAYMENTS_ADDRESS_VERIFICATION_FIELDS } = PAYMENT_CONSTANTS;

  return (
    <>
      <div className='address-form-section'>
        <div className='form-container savebilling-address'>
          <div
            className={`input-container ${
              checkoutDetails?.showBillAddressValidationError && !validateBillingAddress(checkoutDetails)
                ? 'inputError'
                : ''
            }`}
          >
            <input
              data-testid='search-input-address'
              value={addresses?.addressLine}
              onChange={handleInputBilling}
              name={GLOBAL_PAYMENTS_ADDRESS_VERIFICATION_FIELDS?.ADDRESS_LINE}
              required
              type='text'
              title={translateWithI18Next(TRANSLATE_MAPPING_KEY.PLEASE_FILL_OUT_THIS_FIELD)}
              maxlength={checkoutDetails?.billingAddressLength}
            />
            <label className='address-label'>{translateWithI18Next(ADDRESS)}*</label>
            {checkoutDetails?.showBillAddressValidationError && !validateBillingAddress(checkoutDetails) && (
              <div className={'text-danger'} data-testid='address-error'>
                {<img src={IMAGE_PATH?.Ellipse} alt='error' className={`mr-2`} />}
                <span role='alert' className='form-error'>
                  {translateWithI18Next(TRANSLATE_MAPPING_KEY.ENTER_VALID_ADDRESS)}
                </span>
              </div>
            )}
          </div>
          <div
            className={`input-container ml5 ${
              checkoutDetails?.showBillAddressValidationError && !validateBillingPostalCode(checkoutDetails)
                ? 'inputError'
                : ''
            }`}
          >
            <input
              required
              type='text'
              name={GLOBAL_PAYMENTS_ADDRESS_VERIFICATION_FIELDS?.PINCODE}
              value={addresses?.pincode}
              tabIndex='-1'
              data-testid='pincode-id'
              onChange={handleInputBilling}
            />
            <label className='address-label'>{translateWithI18Next(POSTAL_CODE)}*</label>
            {checkoutDetails?.showBillAddressValidationError && !validateBillingPostalCode(checkoutDetails) && (
              <div className={'text-danger'} data-testid='pincode-error'>
                {<img src={IMAGE_PATH?.Ellipse} alt='error' className={`mr-2`} />}
                <span role='alert' className='form-error'>
                  {translateWithI18Next(TRANSLATE_MAPPING_KEY.ENTER_POSTAL_CODE)}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
