import { createClient } from 'contentful';
import { config } from '@kfc-global/react-shared/config/config.utils';
import {
  CONTENTFUL_SPACE,
  CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_ACCESS_TOKEN,
} from '@kfc-global/react-shared/config/config.constants';

let cmsClient;
let authorized;

export function initCmsClient(previewParam) {
  //## PROD ##
  const spaceData = config(CONTENTFUL_SPACE);
  const environment = config(CONTENTFUL_ENVIRONMENT);

  const cmsPreview = new URLSearchParams(previewParam).get('cmsPreview');

  const accessToken = cmsPreview || config(CONTENTFUL_ACCESS_TOKEN);

  cmsClient = createClient({
    space: spaceData,
    environment,
    accessToken,
    removeUnresolved: true,
    ...(cmsPreview ? { host: 'preview.contentful.com' } : {}),
  });
  return cmsClient?.getSpace()?.then(space => {
    authorized = true;
    return space;
  });
}

export function getCmsClient() {
  return cmsClient;
}
