import { AnalyticsService } from '@kfc-global/react-shared/analytics';
import isEmpty from 'lodash/isEmpty';
import {
  DISPOSITION_KEYS,
  DISPOSITION_TYPES_TEXT,
  LOCAL_STORAGE_KEYS,
  STORE_CLOSE,
  DATE_FORMATS,
  PDP_PAGE_URL_LENGTH,
  MENU,
  CATERING,
  ROUTE_URL,
  DAYS,
  ASAP,
  SESSION_STORAGE_KEYS,
  HOLIDAYS,
  SHUTDOWNS,
} from 'common/constants/SharedConstants';
import {
  formatDateTime,
  getDayFromDate,
  getEpochTime,
  getFormatFromUnix,
  parseInvalidDateAndFormat,
  checkIsToday,
  getCurrentEpochTimeonTimeZone,
  getEpochTimeonTimeZone,
  dateIsBetween,
  getFormattedFromUnixWithTimeZone,
  getFormattedTimeWithTimeZone,
  compareDateTime,
} from 'common/utilities/dayJsUtils';
import events from '@kfc-global/react-shared/analytics/Register';
import SEARCH_STORE_CONSTANTS from 'organisms/SearchStore/AU/Constants/SearchStoreConstants';
import {
  getDataLayer,
  getDispositionTypeOnAPIFormat,
  isCateringDisposition,
  ParseJsonSafely,
  redirectToCateringPage,
  setSearchStoreByAtrribute,
  getDeliveryAddressData,
  getFullAddressDetails,
  isEmptyObject,
  getcurrentEpochTime,
  isEventPage,
  isEventDisposition,
} from 'common/utilities/utils';
import { daysOrEverydayCheck } from 'organisms/SearchStore/AU/SearchStoreUtilities/SearchStoreUtils';
import { setUserParams } from '@kfc-global/react-shared/redux/Actions/AppStateAction';
import START_ORDER_CONSTANTS from 'organisms/StartOrderComponent/Constants/startOrderConstants';
import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { getPWAstorage, setPWAstorage } from '@kfc-global/react-shared/redux/Slices/PwaStorageSlice';

const { DISPOSITION_NEW } = LOCAL_STORAGE_KEYS;
const {
  MMMM_D,
  YYYY_MM_DD,
  YYYYMMDD,
  M_D_YYYY_h_mm_ss_A,
  h_mm_A,
  YYYY_MM_DD_hh_mm,
  YYYY_MM_DD_h_mm_a,
  YYYY_MM_DD_h_mm_A,
  dddd_MMMM_D,
  HHmm,
} = DATE_FORMATS;
const checkUnavailablesSlots = (time, unavailibilitiesList) => {
  return unavailibilitiesList?.find(slot => {
    return time >= slot.from && time <= slot.to;
  });
};

export const getTimeInMillisec = date => {
  const getDay = new Date();
  return getDay.setDate(date.slice(-2));
};

export const getList = (st, et, interval, isASAP, isPastOrderAvailable, unavailibilities) => {
  if (!st || !et) {
    return false;
  }
  let unavailibilitiesList = [];
  if (unavailibilities?.length > 0) {
    for (let i = 0; i < unavailibilities?.length; i++) {
      const { from, to } = unavailibilities[i];
      unavailibilitiesList.push({
        from: new Date().setHours(Number(from?.split(':')[0]), Number(from?.split(':')[1]), 0, 0),
        to: new Date().setHours(Number(to?.split(':')[0]), Number(to?.split(':')[1]), 0, 0),
      });
    }
  }
  let timeSlots = [];
  const currentLocalMachineTime = new Date().getTime();
  let startTimeDate = new Date().setHours(Number(st?.split(':')[0]), Number(st?.split(':')[1]), 0, 0);
  let endTimeDate = new Date().setHours(Number(et?.split(':')[0]), Number(et?.split(':')[1]), 0, 0);
  if (isPastOrderAvailable) {
    endTimeDate += 86400000;
  }
  let nextDayMidnight = new Date().setHours(0, 0, 1) + (86400000 - 60000 * interval);
  let temp = startTimeDate;
  while (temp < endTimeDate) {
    if (unavailibilitiesList?.length > 0 && checkUnavailablesSlots(temp, unavailibilitiesList)) {
      temp += 60000 * interval;
      continue;
    }
    if (temp > nextDayMidnight) {
      timeSlots.push({
        timestr: new Date(temp).getHours() + ':' + new Date(temp).getMinutes(),
        selectedDay: 'Tomorrow',
      });
    } else {
      timeSlots.push({
        timestr: new Date(temp).getHours() + ':' + new Date(temp).getMinutes(),
        selectedDay: 'Today',
      });
    }
    temp += 60000 * interval;
  }

  timeSlots = timeSlots.map(item => {
    return { ...item, timestr: parseInvalidDateAndFormat(item.timestr, 'hh:mm', 'LT') };
  });
  if (currentLocalMachineTime > startTimeDate) {
    const splitIndex = timeSlots.findIndex(timeSlot => {
      const timeIn24HrsFormat = parseInvalidDateAndFormat(timeSlot.timestr, 'hh:mm A', 'HH:mm');
      let epochTime;
      if (timeSlot.selectedDay === 'Today') {
        epochTime = new Date().setHours(
          Number(timeIn24HrsFormat?.split(':')[0]),
          Number(timeIn24HrsFormat?.split(':')[1]),
          0,
          0,
        );
      } else {
        epochTime =
          new Date().setHours(
            Number(timeIn24HrsFormat?.split(':')[0]),
            Number(timeIn24HrsFormat?.split(':')[1]),
            0,
            0,
          ) + 86400000;
      }
      return epochTime >= currentLocalMachineTime;
    });
    timeSlots = timeSlots.splice(splitIndex);
  }
  if (isASAP) {
    timeSlots.unshift({ timestr: 'ASAP', selectedDay: 'Today' });
  }
  return timeSlots;
};

export const getDateFromTime = (st, timeSelected) => {
  if (timeSelected === 'ASAP' || timeSelected?.selectedDay === 'Today') {
    return new Date();
  }
  return new Date(new Date().getTime() + 86400000);
};

/* return disposition value based on different conditions*/
export const getDispositionObj = (changeFlow, isAddressFlow, dispositionType) => {
  const { DISPOSITION_TYPE } = LOCAL_STORAGE_KEYS;
  const { PICKUP } = DISPOSITION_KEYS;
  const orderType = dispositionType
    ? dispositionType?.toUpperCase()
    : localStorage.getItem(DISPOSITION_TYPE)?.toUpperCase();
  const dispositionLocal = localStorage.getItem(DISPOSITION_TYPE);
  if (changeFlow && dispositionLocal) {
    return {
      dispositionValue: dispositionLocal?.toUpperCase(),
      dispositionName: DISPOSITION_TYPES_TEXT?.[dispositionLocal?.toUpperCase()],
    };
  } else if (isAddressFlow) {
    return {
      dispositionValue: PICKUP,
      dispositionName: DISPOSITION_TYPES_TEXT?.[PICKUP],
    };
  } else {
    return {
      dispositionValue: orderType,
      dispositionName: DISPOSITION_TYPES_TEXT?.[orderType],
    };
  }
};

export const parseDateList = dateArr => {
  let formattedDateArr = dateArr;
  return formattedDateArr?.length > 0 && formattedDateArr?.map(item => item?.date);
};

export const setScheduleDataOnChangeFlow = (
  changeFlow,
  userOrderStateDispatch,
  dispositionNew,
  SCHEDULE_ORDER_DATA,
) => {
  if (changeFlow) {
    userOrderStateDispatch({
      type: SCHEDULE_ORDER_DATA,
      value: {
        showScheduleOrderCatering: true,
        selectedStoreInfo: dispositionNew?.store,
        deliveryAddress: dispositionNew?.deliveryAddress,
        deliveryAddressLine: dispositionNew?.deliveryAddressLine,
        changeFlow: changeFlow,
      },
    });
  }
};

export const storeUnavailableErrorAnalytic = async (disposition = 'NA') => {
  await AnalyticsService.registerEvent(
    events?.storeClose?.name,
    {
      data: {
        event: STORE_CLOSE,
        disposition,
      },
    },
    getDataLayer,
  );
};

export const getStoreUnavailableErrorAnalytic = (
  storeUnavailableError,
  disposition,
  setStoreUnavailableAnalyticFlag,
) => {
  if (storeUnavailableError) {
    setStoreUnavailableAnalyticFlag(true);
    storeUnavailableErrorAnalytic(disposition?.toLowerCase());
  }
};
export const getOpenLaterStatus = ({ storeSummary, currentEpochTime, date, timeFormat, timeZone }) => {
  const scheduled = storeSummary?.timings?.scheduled || {};
  const futureDates = storeSummary?.timings?.futureDates || {};
  const message = storeSummary?.status?.message || '';
  const availableTimeSlots = finalTimeSlots({
    scheduledData: scheduled,
    currentEpochTime,
    date,
    timeFormat,
    timeZone,
  });
  const openToday = availableTimeSlots?.timeSlots?.length > 0;
  return SHUTDOWNS.includes(message?.split('||')[0]?.trim()) ? futureDates?.length > 0 || openToday : false;
};
export const isStoreClosed = (startTime, endTime) =>
  startTime === DATE_FORMATS?.START_TIME && endTime === DATE_FORMATS?.END_TIME;
export const multiTimeSlot = multiTimeSlotsPayload => {
  const {
    element,
    startTime,
    endTime,
    currentEpochTime,
    interval,
    unavailibilities = [{ from: '', to: '' }],
    unavailibilityType = '',
    timeFormat = h_mm_A,
  } = multiTimeSlotsPayload;
  let from = '';
  let to = '';
  let endTimeTZ = endTime;
  if (!isEmpty(unavailibilities)) {
    ({ from = '', to = '' } = unavailibilities[0]);
  }
  if (from !== '' && to !== '') {
    from = new Date(from)?.toISOString()?.substring(11, 16);
    to = new Date(to)?.toISOString()?.substring(11, 16);
  }
  if (unavailibilityType === HOLIDAYS) {
    if (endTime.includes('000Z')) {
      endTimeTZ = new Date(endTime)?.toISOString()?.substring(11, 16);
    }
  }

  if (!startTime || !endTime) {
    return { date: { key: element, value: formatDateTime(element, MMMM_D), disabled: true }, timeSlots: [] };
  }
  const isHoliday = unavailibilityType === HOLIDAYS;
  const startEpochTime = getEpochTime(element + ' ' + startTime, YYYY_MM_DD_hh_mm);
  const endEpochTime = getEpochTime(element + ' ' + endTimeTZ, YYYY_MM_DD_hh_mm);
  const unavailabeStartEpochTime = getEpochTime(element + ' ' + from, YYYY_MM_DD_hh_mm);
  const unavailabeEndEpochTime = getEpochTime(element + ' ' + to, YYYY_MM_DD_hh_mm);
  let timeSlots = [];
  let unavailibilitySlots = [];
  const addOneDay = 24 * 60 * 60;
  let temp = startEpochTime;
  while (temp <= endEpochTime) {
    const isUnavailableTimeslots = dateIsBetween(temp, unavailabeStartEpochTime, unavailabeEndEpochTime);
    if (from !== '' && to !== '') {
      if (temp > currentEpochTime + addOneDay) {
        if (temp < unavailabeStartEpochTime || temp > unavailabeEndEpochTime) {
          timeSlots.push({ key: temp, value: getFormatFromUnix(temp, timeFormat) });
        } else {
          unavailibilitySlots.push({ key: temp, value: getFormatFromUnix(temp, timeFormat) });
        }
      }
    } else {
      if (temp > currentEpochTime + addOneDay) {
        timeSlots.push({ key: temp, value: getFormatFromUnix(temp, timeFormat) });
      }
    }
    temp += getTimeSlotsWithInterval(isUnavailableTimeslots, interval, isHoliday);
  }
  if (isHoliday) {
    return {
      date: { key: element, value: formatDateTime(element, MMMM_D), disabled: unavailibilitySlots?.length === 0 },
      timeSlots: unavailibilitySlots,
    };
  }
  return {
    date: {
      key: element,
      value: formatDateTime(element, MMMM_D),
      disabled: timeSlots?.length === 0,
      storeClosed: isStoreClosed(startTime, endTime),
    },
    timeSlots,
  };
};
export const getDateAndSelectedTime = (selectedDate, selectedTime, scheduleOrderDateFormat) => {
  const value = scheduleOrderDateFormat
    ? parseInvalidDateAndFormat(selectedTime?.value, DATE_FORMATS?.hh_mm, DATE_FORMATS?.h_mm_A)
    : selectedTime?.value;
  const time = !selectedTime?.key || selectedTime?.key === ASAP ? ASAP : value;
  const epochTime = !selectedTime?.key || selectedTime?.key === ASAP ? ASAP : selectedTime?.key;
  return {
    date: selectedDate?.date?.key ?? formatDateTime(new Date(), DATE_FORMATS.YYYY_MM_DD),
    time: time ?? ASAP,
    selectedTime: time ?? ASAP,
    epochTime: epochTime ?? ASAP,
  };
};
export const getDateTimeSlots = (
  selectedStoreInfo = {},
  enableScheduleOrderFlag = false,
  enableClickCollectScheduleOrderFlag = false,
  reOrderFlag = false,
  scheduleModeClicked = false,
  timeFormat,
  changeDateFormatOrder,
  isAsapDisabledForTenant,
) => {
  const dateTimeSlots = enableScheduleOrderFlag
    ? getDateTimeListScheduleOrder({
        displayStoreInfo: selectedStoreInfo,
        enableScheduleOrder: enableScheduleOrderFlag,
        fromReorder: reOrderFlag,
        enableClickCollectScheduleOrderFlag,
        scheduleModeClicked,
        timeFormat,
        changeDateFormatOrder,
        isAsapDisabledForTenant,
      })
    : getDateTimeList(selectedStoreInfo, 30, timeFormat);

  return {
    enabledDate: dateTimeSlots?.find(item => !item?.date?.disabled),
    dateTimeSlotsArray: dateTimeSlots?.filter(item => !item?.date?.disabled),
  };
};
export const getTimeSlotsWithInterval = (isUnavailableTimeslots, interval, isHoliday, defaultInterval = 1) => {
  const regularInterval = !isUnavailableTimeslots ? 60 * interval : 60 * defaultInterval;
  const holidayInterval = isUnavailableTimeslots ? 60 * interval : 60 * defaultInterval;
  return isHoliday ? holidayInterval : regularInterval;
};
export const addASAPOption = setASAPFlagPayloadData => {
  const { enableScheduleOrder, scheduleModeClicked, isAsapOrder, ifTodayOrNot, timeSlots, isAsapDisabledForTenant } =
    setASAPFlagPayloadData;
  const isAsapOrderAvailable = Boolean(
    enableScheduleOrder && isAsapOrder && !scheduleModeClicked && ifTodayOrNot && timeSlots?.length,
  );
  !isAsapDisabledForTenant &&
    isAsapOrderAvailable &&
    timeSlots.shift() &&
    timeSlots.unshift({ key: ASAP, value: translateWithI18Next(TRANSLATE_MAPPING_KEY.ASAP) });
};
export const customizer = (objValue, srcValue) => {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
};
/* returns a list of date & time slots for selected store */
export const getDateTimeList = (displayStoreInfo, interval = 30, timeFormat) => {
  const { summary = {} } = displayStoreInfo ?? {};
  const { timings = {}, timeZoneIANA: timeZone = '' } = summary;
  const { operatingHours = {}, futureDates = [] } = timings;
  let options = {
      timeZone: timeZone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    },
    formatter = new Intl.DateTimeFormat('en-US', options);
  const formatedFullDate = formatter?.format(new Date());
  const formatedDateTime = formatedFullDate?.split(',').join(' ');
  const currentEpochTime = getEpochTime(formatedDateTime, M_D_YYYY_h_mm_ss_A);
  return futureDates.map(element => {
    let parsedFutureDay = getDayFromDate(element, YYYY_MM_DD);
    let finalArr = daysOrEverydayCheck(operatingHours, parsedFutureDay);
    let startTime = '';
    let endTime = '';
    if (finalArr.length > 1) {
      const result = [];
      finalArr.forEach(item => {
        startTime = item?.availableHours?.from;
        endTime = item?.availableHours?.to;
        const multiTimeSlotsPayload = {
          element,
          startTime,
          endTime,
          currentEpochTime,
          interval,
          timeFormat,
        };
        result.push(multiTimeSlot(multiTimeSlotsPayload));
      });
      const [obj, src] = result;
      return mergeWith(obj, src, customizer);
    } else {
      startTime = finalArr?.[0]?.availableHours?.from;
      endTime = finalArr?.[0]?.availableHours?.to;
      const multiTimeSlotsPayloadData = {
        element,
        startTime,
        endTime,
        currentEpochTime,
        interval,
        timeFormat,
      };
      return multiTimeSlot(multiTimeSlotsPayloadData);
    }
  });
};
export const setDateTime = dateTimePayloadData => {
  const {
    fromReorder,
    updateReorderDetails,
    dateTimeSlotsArray,
    setDateTimeArr,
    selectedDate,
    enabledDate,
    setSelectedDate,
    selectedTime,
    setSelectedTime,
    enableScheduleOrder,
    isSpecialEvent,
    enableOrderCapacityManagement,
  } = dateTimePayloadData;
  const enableTimeObj = enableScheduleOrder || isSpecialEvent ? {} : { ...enabledDate?.timeSlots?.[0] };
  fromReorder ? updateReorderDetails({ dateTimeArr: dateTimeSlotsArray }) : setDateTimeArr(dateTimeSlotsArray);
  fromReorder
    ? isEmpty(selectedDate) && updateReorderDetails({ selectedDate: enabledDate })
    : setSelectedDate(selectedDate || enabledDate);

  const timeObj = enableOrderCapacityManagement ? enableTimeObj.key : enableTimeObj;

  fromReorder
    ? isEmpty(selectedTime) && updateReorderDetails({ selectedTime: enableTimeObj })
    : setSelectedTime(timeObj);
};

export const dateTimeManipulation = (dateTimeManupulationObj, scheduleOrderDateFormat, timeFormat) => {
  const {
    shouldShowScheduleOrder,
    store,
    setDateTimeArr,
    setSelectedDate,
    setSelectedTime,
    selectedDate,
    selectedTime,
    changeFlow,
    showScheduleOrder = false,
    enableScheduleOrder = false,
    updateReorderDetails,
    fromReorder = false,
    enableClickCollectScheduleOrderFlag = false,
    scheduleModeClicked = false,
    orderServDateFormat = '',
    isSpecialEvent = false,
    enableOrderCapacityManagement,
    changeDateFormatOrder,
    isAsapDisabledForTenant,
  } = dateTimeManupulationObj;

  const storeDetails = store?.[0];
  const storeInterval = storeDetails?.summary?.timings?.scheduled?.periods?.[0]?.interval;
  const dateTimeScheduleOrderObj = {
    displayStoreInfo: storeDetails,
    enableScheduleOrder,
    fromReorder,
    enableClickCollectScheduleOrderFlag,
    scheduleModeClicked,
    orderServDateFormat,
    timeFormat,
    changeDateFormatOrder,
    isAsapDisabledForTenant,
  };

  if (shouldShowScheduleOrder) {
    const dateTimeSlots = enableScheduleOrder
      ? getDateTimeListScheduleOrder(dateTimeScheduleOrderObj)
      : getDateTimeList(storeDetails, storeInterval, timeFormat);

    const enabledDate = dateTimeSlots?.find(item => !item?.date?.disabled);

    const dateTimeSlotsArray = dateTimeSlots?.filter(item => !item?.date?.disabled);
    const dateTimePayload = {
      fromReorder,
      updateReorderDetails,
      dateTimeSlotsArray,
      setDateTimeArr,
      selectedDate,
      enabledDate,
      setSelectedDate,
      selectedTime,
      setSelectedTime,
      enableScheduleOrder,
      isSpecialEvent,
      enableOrderCapacityManagement,
    };
    setDateTime(dateTimePayload);

    const epochTimeFormat = YYYY_MM_DD_h_mm_A;

    if (changeFlow) {
      const alreadySelectedDate = ParseJsonSafely(localStorage.getItem(DISPOSITION_NEW))?.date;
      const alreadySelectedTime = ParseJsonSafely(localStorage.getItem(DISPOSITION_NEW))?.time;
      const alreadySelectedEpochTime = getEpochTime(`${alreadySelectedDate} ${alreadySelectedTime}`, epochTimeFormat);
      const defaultSlotEpochTime =
        enabledDate?.timeSlots?.[0]?.key === ASAP ? enabledDate?.timeSlots?.[1]?.key : enabledDate?.timeSlots?.[0]?.key;
      const showAlreadySelectedDateTime = enableScheduleOrder
        ? !fromReorder && showScheduleOrder && !selectedDate
        : true;

      const alreadySelectedDateTimeObj = {
        enableScheduleOrder,
        alreadySelectedEpochTime,
        defaultSlotEpochTime,
        alreadySelectedDate,
        alreadySelectedTime,
        showAlreadySelectedDateTime,
        setSelectedDate,
        setSelectedTime,
        dateTimeSlotsArray,
        scheduleModeClicked,
      };
      setDateTimeFlag(alreadySelectedDateTimeObj, scheduleOrderDateFormat);
    }
  }
};
export const setDateTimeFlag = (alreadySelectedDateTimeData, scheduleOrderDateFormat) => {
  const {
    enableScheduleOrder,
    alreadySelectedEpochTime,
    defaultSlotEpochTime,
    showAlreadySelectedDateTime,
    alreadySelectedDate,
    alreadySelectedTime,
    setSelectedDate,
    setSelectedTime,
    dateTimeSlotsArray,
    scheduleModeClicked,
  } = alreadySelectedDateTimeData;

  if (!enableScheduleOrder && showAlreadySelectedDateTime && alreadySelectedEpochTime > defaultSlotEpochTime) {
    const updatedSelectedTimeFormat = scheduleOrderDateFormat
      ? parseInvalidDateAndFormat(alreadySelectedTime, DATE_FORMATS?.h_mm_A, DATE_FORMATS?.HH_mm)
      : alreadySelectedTime;

    setSelectedDate(dateTimeSlotsArray?.find(item => item?.date?.key === alreadySelectedDate));
    setSelectedTime({ key: alreadySelectedEpochTime, value: updatedSelectedTimeFormat });
  }

  // Schedule order feature, timeslot selection
  if (enableScheduleOrder && showAlreadySelectedDateTime && !scheduleModeClicked) {
    const updatedSelectedTimeFormat = scheduleOrderDateFormat
      ? parseInvalidDateAndFormat(alreadySelectedTime, DATE_FORMATS?.h_mm_A, DATE_FORMATS?.HH_mm)
      : translateWithI18Next(alreadySelectedTime?.toLowerCase());

    const scheduledDate = dateTimeSlotsArray?.find(item => item?.date?.key === alreadySelectedDate);

    if (scheduledDate) {
      setSelectedDate(scheduledDate);
    }

    // If selected epoch time is not valid, set an empty selectedTime to avoid errors in time dropdown
    if (alreadySelectedEpochTime) {
      setSelectedTime({ key: alreadySelectedEpochTime, value: updatedSelectedTimeFormat });
    }
  }
};
export const setServiceTypeForPDP = (history, selectedDispositionValue) => {
  const params = history?.location?.pathname.split('/');
  if (params?.length === PDP_PAGE_URL_LENGTH && (params.includes(MENU) || params.includes(CATERING))) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.SERVICE_TYPE, getDispositionTypeOnAPIFormat(selectedDispositionValue));
  }
};

export const successCallbackHandler = async params => {
  const {
    dispatch,
    userOrderStateDispatch,
    dispositionNew,
    selectedDispositionValue,
    currentStoreInfoData,
    dateTimeObj,
    scheduleOrderData,
    history,
    redirectionOrder,
    userParams,
    dispositionType,
    fromflag,
    updatedBasketId,
    isFromDeliveryAddressForm,
    sliceDispatch,
  } = params;
  let deliveryAddressTxt = scheduleOrderData?.deliveryAddress;
  if (!isFromDeliveryAddressForm) {
    const deliveryAddr = await getDeliveryAddressData(dispositionType, scheduleOrderData?.deliveryAddress);
    const fullDeliveryAddressTxt = isEmptyObject(deliveryAddr) && getFullAddressDetails(deliveryAddr);
    deliveryAddressTxt = fullDeliveryAddressTxt ? fullDeliveryAddressTxt : scheduleOrderData?.deliveryAddress;
  }
  const {
    USER_LOCALIZED,
    SET_DISPOSITION,
    SCHEDULE_ORDER_TIME_DATA,
    SCHEDULE_ORDER_TIME_PRESERVE_TIME,
    SET_REDIRECTION_PATH,
    SET_FORCERELOAD,
  } = START_ORDER_CONSTANTS;
  const {
    DISPOSITION_NEW: DISPOSITION_NEW_DATA,
    DISPOSITION_OLD,
    DATE_TIME_ORDER,
    SAVED_TIME_ORDER,
    SET_LOCATION,
  } = LOCAL_STORAGE_KEYS;
  userOrderStateDispatch({
    type: SET_DISPOSITION,
    value: {
      oldvalue: dispositionNew,
      newValue: {
        type: selectedDispositionValue,
        store: currentStoreInfoData,
        date: dateTimeObj.date,
        time: dateTimeObj.time,
        epochTime: dateTimeObj?.epochTime,
        interval: dateTimeObj.interval,
        selectedTime: dateTimeObj.selectedTime,
        deliveryAddress: deliveryAddressTxt,
        deliveryAddressLine: scheduleOrderData?.deliveryAddressLine,
        basketId: updatedBasketId,
      },
    },
  });
  localStorage.setItem(DISPOSITION_OLD, JSON.stringify(dispositionNew));
  localStorage.removeItem(DISPOSITION_NEW_DATA);
  localStorage.setItem(
    DISPOSITION_NEW_DATA,
    JSON.stringify({
      type: selectedDispositionValue,
      store: currentStoreInfoData,
      date: dateTimeObj.date,
      time: dateTimeObj.time,
      epochTime: dateTimeObj?.epochTime,
      interval: dateTimeObj.interval,
      selectedTime: dateTimeObj.selectedTime,
      deliveryAddress: deliveryAddressTxt,
      deliveryAddressLine: scheduleOrderData?.deliveryAddressLine,
      basketId: updatedBasketId,
    }),
  );
  sliceDispatch &&
    sliceDispatch(
      setPWAstorage({
        key: LOCAL_STORAGE_KEYS.DISPOSITION_NEW,
        value: {
          type: selectedDispositionValue,
          store: currentStoreInfoData,
          date: dateTimeObj.date,
          time: dateTimeObj.time,
          epochTime: dateTimeObj?.epochTime,
          interval: dateTimeObj.interval,
          selectedTime: dateTimeObj.selectedTime,
          deliveryAddress: deliveryAddressTxt,
          deliveryAddressLine: scheduleOrderData?.deliveryAddressLine,
          basketId: updatedBasketId,
        },
      }),
    );
  setServiceTypeForPDP(history, selectedDispositionValue);
  setSearchStoreByAtrribute();
  userOrderStateDispatch({
    type: SCHEDULE_ORDER_TIME_DATA,
    value: { selectedDateTime: dateTimeObj },
  });
  userOrderStateDispatch({
    type: SCHEDULE_ORDER_TIME_PRESERVE_TIME,
    value: {
      selectContentTime: !isCateringDisposition(dispositionType) || !fromflag ? ASAP : dateTimeObj?.selectedTime,
    },
  });
  localStorage.setItem(DATE_TIME_ORDER, JSON.stringify(dateTimeObj));
  localStorage.setItem(
    SAVED_TIME_ORDER,
    !isCateringDisposition(dispositionType) || !fromflag ? ASAP : dateTimeObj?.selectedTime,
  );
  userOrderStateDispatch({
    type: SET_FORCERELOAD,
    value: { forceReload: true },
  });
  dispatch(
    setUserParams({
      ...userParams,
      localized: true,
    }),
  );
  userOrderStateDispatch({
    type: USER_LOCALIZED,
    value: { isLocalized: true },
  });
  if (!fromflag) {
    if (isEventPage(history?.location?.pathname) && isEventDisposition(dispositionType.toUpperCase())) {
      return;
    } else if (localStorage.getItem(SET_LOCATION)) {
      localStorage.removeItem(SET_LOCATION);
    } else if (isCateringDisposition(dispositionType)) {
      if (history?.location?.pathname?.indexOf(ROUTE_URL.CATERING_PAGE) === -1) {
        redirectToCateringPage({ history });
      }
    } else if (redirectionOrder) {
      history.push({
        pathname: `${redirectionOrder}`,
      });
      userOrderStateDispatch({ type: SET_REDIRECTION_PATH, value: { redirectionOrder: '' } });
    } else if (history?.location?.pathname?.indexOf(ROUTE_URL.MENU_PAGE) === -1) {
      history.push({
        pathname: `${ROUTE_URL.MENU_PAGE}`,
      });
    }
  }
};

export const changedDeliveryAddress = (
  isAddressFlow,
  storeInfo,
  chooseConfig,
  changeFlow,
  searchedLocations,
  recentLocation,
  userOrderStateDispatch,
) => {
  const { SEARCH_ORDER_DATA, SCHEDULE_ORDER_DATA } = START_ORDER_CONSTANTS;
  if (isAddressFlow) {
    userOrderStateDispatch({
      type: SEARCH_ORDER_DATA,
      value: { showSearchStore: true, searchedPlaceNonLocalized: storeInfo.name[0].value },
    });
  } else if (searchedLocations) {
    userOrderStateDispatch({
      type: SEARCH_ORDER_DATA,
      value: { showSearchStore: true, searchedPlaceNonLocalized: recentLocation, configSearchScreen: chooseConfig },
    });
    localStorage.setItem(LOCAL_STORAGE_KEYS.CONFIG_SEARCH_SCREEN, chooseConfig);
    userOrderStateDispatch({
      type: SCHEDULE_ORDER_DATA,
      value: {
        showScheduleOrderCatering: false,
        selectedStoreInfo: storeInfo,
        changeFlow: Boolean(changeFlow),
      },
    });
  }
};

const getArrayOrObjectAsArray = ({ arr = [], obj, shouldReturnObjAsArr }) => {
  if (shouldReturnObjAsArr) {
    return obj ? [obj] : [];
  }

  return arr || [];
};

// Returns the list of dates and their available slots
export const getDateTimeListScheduleOrder = dateTimeScheduleOrderObj => {
  const {
    displayStoreInfo = {},
    enableScheduleOrder = false,
    enableClickCollectScheduleOrderFlag = false,
    scheduleModeClicked = false,
    timeFormat,
    changeDateFormatOrder,
    isAsapDisabledForTenant,
  } = dateTimeScheduleOrderObj;

  const { summary = {} } = displayStoreInfo || {};
  const { timings = {}, timeZoneIANA: timeZone = '', orderingAttribute = {} } = summary;
  const { futureScheduled = [], asap, scheduled = {} } = timings;
  const { isAsapOrder = false } = orderingAttribute || {};
  const isStoreClosedData = !asap?.isAvailable;
  const asapOrderFlag = !isStoreClosedData && isAsapOrder;

  const currentEpochTime = getcurrentEpochTime(timeZone);

  // If enableClickCollectScheduleOrderFlag is true, get schedule data from futureScheduled list. Otherwise, use scheduled obj as an array.
  const scheduledData = getArrayOrObjectAsArray({
    arr: futureScheduled,
    obj: scheduled,
    shouldReturnObjAsArr: enableClickCollectScheduleOrderFlag,
  }).filter(item => item?.periods?.length);
  return scheduledData.map(element => {
    const { date } = element;
    const parsedFutureDay = getDayFromDate(date, YYYY_MM_DD);
    const result = [];
    const timeStamp = getTimeInMillisec(date);
    const isToday = checkIsToday(timeStamp, timeZone);
    const finalTimeSlotsPayload = {
      date,
      scheduledData: element,
      currentEpochTime,
      enableScheduleOrder,
      isAsapOrder: asapOrderFlag,
      ifTodayOrNot: isToday,
      scheduleModeClicked,
      timeFormat,
      timeZone,
      isAsapDisabledForTenant,
    };

    result.push(finalTimeSlots(finalTimeSlotsPayload));

    const futureDay = isToday
      ? translateWithI18Next(TRANSLATE_MAPPING_KEY.TODAY)
      : translateWithI18Next(DAYS?.[parsedFutureDay]);
    const parsedMonth = result[0]?.date?.value?.split(' ');
    result[0].date.value = changeDateFormatOrder
      ? translateWithI18Next(parsedMonth[0]) +
        ' ' +
        parsedMonth[1] +
        translateWithI18Next(TRANSLATE_MAPPING_KEY.DAY) +
        ' ' +
        futureDay
      : futureDay + ' ' + translateWithI18Next(parsedMonth[0]) + ' ' + parsedMonth[1];
    const [obj, src] = result;
    return mergeWith(obj, src, customizer);
  });
};

export const getTimefromTimeStamp = param => param?.split('T')[1]?.slice(0, 5);
export const getDatefromTimeStamp = param => param?.split('T')[0];

// Converts a date string into an iso string and then extracts HH:mm
const mapISOStrToTime = (str = '') => {
  return str ? new Date(str)?.toISOString()?.substring(11, 16) : '';
};

// Returns time in HH:mm format
const getValidTimeFormat = str => {
  // If str is an ISO string, map str to HH:mm format
  if (str.includes('000Z')) {
    return mapISOStrToTime(str);
  }

  return str;
};

export const finalTimeSlots = multiTimeSlotsPayload => {
  const {
    date,
    currentEpochTime,
    enableScheduleOrder = false,
    isAsapOrder = false,
    ifTodayOrNot = false,
    scheduleModeClicked,
    scheduledData,
    timeFormat,
    timeZone,
    isAsapDisabledForTenant,
  } = multiTimeSlotsPayload;

  const { periods = [], unavailibilities = [], unavailibilityType } = scheduledData;

  const interval = periods[0]?.interval || 15;

  // All timeslots from openening to closing time
  const timeSlotsArray = periods?.reduce((acc, curr) => {
    const { interval = 15, startEndTime } = curr || {};
    const { from = '', to = '' } = startEndTime || {};

    // Make sure that startTime and endTime will always have HH:mm respectively H:m format (format returned by setToNearestInterval() )
    const startTime = setToNearestInterval(getValidTimeFormat(from), interval, true);
    const endTime = getValidTimeFormat(to);

    return [
      ...acc,
      ...multiSchedulingTimeSlots(
        {
          currentEpochTime,
          date,
          endDateTime: `${date} ${endTime}`,
          interval,
          startDateTime: `${date} ${startTime}`,
          timeZone,
        },
        timeFormat,
      ),
    ];
  }, []);

  // All available hours of a store during a Holiday and unavailable hours during Shutdown
  const unavailableTimeSlotsArray = unavailibilities?.reduce((acc, curr) => {
    const { from = '', to = '' } = curr || {};

    // The format of from and to may be HH:mm or an iso string, depending of the unavailability type.
    // Make sure that startTime and endTime will always have HH:mm format
    const startTime = setToNearestInterval(getValidTimeFormat(from), interval, true);
    const endTime = getValidTimeFormat(to);

    return [
      ...acc,
      ...multiSchedulingTimeSlots(
        {
          currentEpochTime,
          date,
          endDateTime: `${date} ${endTime}`,
          interval,
          startDateTime: `${date} ${startTime}`,
          timeZone,
        },
        timeFormat,
      ),
    ];
  }, []);

  // Final timeslots will include unavailibilities list for Holiday and exclude unavailabilities for Shutdown
  const finalTimeSlotsInfo =
    unavailibilityType === HOLIDAYS
      ? unavailableTimeSlotsArray
      : timeSlotsArray.filter(timeSlot => {
          return !unavailableTimeSlotsArray.find(unavailableTimeSlots => {
            return timeSlot.value === unavailableTimeSlots.value;
          });
        });

  const setASAPFlagPayload = {
    enableScheduleOrder,
    scheduleModeClicked,
    isAsapOrder,
    ifTodayOrNot,
    timeSlots: finalTimeSlotsInfo,
    isAsapDisabledForTenant,
  };
  addASAPOption(setASAPFlagPayload);

  return {
    date: { key: date, value: formatDateTime(date, MMMM_D), disabled: finalTimeSlotsInfo?.length === 0 },
    timeSlots: finalTimeSlotsInfo,
  };
};

// Returns all the time slots in a time interval
export const multiSchedulingTimeSlots = (multiTimeSlotsPayload, timeFormat = h_mm_A) => {
  const { currentEpochTime, endDateTime = '', interval, startDateTime = '', timeZone } = multiTimeSlotsPayload;
  const startEpochTime = getEpochTimeonTimeZone(startDateTime, timeZone);
  const endEpochTime = getEpochTimeonTimeZone(endDateTime, timeZone);

  let timeSlots = [];
  let temp = startEpochTime;

  while (temp <= endEpochTime) {
    if (temp > currentEpochTime) {
      const timeSlotObj = {
        key: temp,
        value: getFormattedFromUnixWithTimeZone(temp, timeZone, timeFormat),
      };
      timeSlots.push(timeSlotObj);
    }
    temp += 60 * interval;
  }

  return timeSlots;
};

const setToNearestInterval = (time, interval, setToLater) => {
  let minutes = parseInt(time?.split(':')[1], 10);
  let hours = parseInt(time?.split(':')[0], 10);
  const numOfIntervals = 60 / interval;
  const setTimeInitial = [];
  for (let i = 0; i < numOfIntervals; i += 1) {
    setTimeInitial.push({
      from: i * interval + 1,
      to: (i + 1) * interval - 1,
      max: (i + 1) * interval === 60 ? 0 : (i + 1) * interval,
      min: i * interval,
    });
  }
  setTimeInitial?.forEach((initial, index) => {
    if (minutes >= initial?.from && minutes <= initial?.to) {
      minutes = setToLater ? initial?.max : initial?.min;
      if (index === numOfIntervals - 1 && minutes === 0) {
        hours += 1;
      }
    }
  });
  return `${hours}:${minutes}`;
};
export const scheduledTimeError = (setHasError, hasError, errorMsg) => {
  setHasError({
    ...hasError,
    error: errorMsg,
    errormessage: translateWithI18Next(TRANSLATE_MAPPING_KEY.SCHEDULE_ORDER_ERROR),
  });
};

export const scheduledOrderCapacityTimeSlotError = (setHasError, hasError, errorMsg) => {
  setHasError({
    ...hasError,
    error: errorMsg,
    errormessage: translateWithI18Next(TRANSLATE_MAPPING_KEY.SCHEDULE_ORDER_CAPACITY_ERROR),
    errorType: START_ORDER_CONSTANTS.ORDER_CAPACITY_TIME_ERROR,
    warningMessage: translateWithI18Next(TRANSLATE_MAPPING_KEY.SCHEDULE_ORDER_TIME_SELECT),
  });
};

export const scheduledOrderCapacityNoOrdersError = (setHasError, hasError, errorMsg) => {
  setHasError({
    ...hasError,
    error: errorMsg,
    errormessage: translateWithI18Next(TRANSLATE_MAPPING_KEY.NO_TIME_SLOTS_SELECT_ANOTHER),
    errorType: START_ORDER_CONSTANTS.ORDER_CAPACITY_DAY_ERROR,
    warningMessage: translateWithI18Next(TRANSLATE_MAPPING_KEY.NO_TIME_SLOTS_AVAILABLE),
  });
};

export const checkForScheduledTime = ({
  enableScheduleOrder,
  enableClickCollectScheduleOrder,
  timeZone,
  storeSelectedTime,
  promiseTime,
  storeSelectedDate,
}) => {
  if (
    (enableScheduleOrder || enableClickCollectScheduleOrder) &&
    storeSelectedTime !== ASAP &&
    storeSelectedTime !== ''
  ) {
    const currentEpochTime = getCurrentEpochTimeonTimeZone(timeZone);
    const currentEpochDate = timeZone && getFormattedTimeWithTimeZone(timeZone, DATE_FORMATS.YYYY_MM_DD);
    const isPastDate =
      storeSelectedDate && compareDateTime(currentEpochDate, storeSelectedDate, DATE_FORMATS.YYYY_MM_DD);
    if (storeSelectedTime - promiseTime * 60 <= currentEpochTime || isPastDate) {
      return true;
    }
    return false;
  }
  return false;
};

export const enableASAPTiming = (setOrderNowFlag, setScheduleOrdeFlag) => {
  setOrderNowFlag(false);
  setScheduleOrdeFlag(true);
  sessionStorage.setItem(SESSION_STORAGE_KEYS.SHOW_ORDER_NOW, false);
  sessionStorage.setItem(SESSION_STORAGE_KEYS.SHOW_SCHEDULE_REORDER, true);
  sessionStorage.setItem(LOCAL_STORAGE_KEYS.CHANGE_SCHEDULE_MODE, true);
  sessionStorage.setItem(SESSION_STORAGE_KEYS.SHOW_SCHEDULE_DISPOSITION, true);
};
export const storeOrderSchedulingUnavailable = (
  store,
  enableScheduleOrderFlag,
  enableClickCollectScheduleOrderFlag,
  fromReorderFlag,
  timeFormat,
  changeDateFormatOrder,
  isAsapDisabledForTenant,
) => {
  const scheduleArray = [];

  if (store?.summary) {
    const isOpen = store?.summary?.status?.currentStatus === SEARCH_STORE_CONSTANTS.STORE_STATUS_OPEN;
    const { timings = {} } = store?.summary;
    const { futureScheduled = [], scheduled = {} } = timings;
    enableClickCollectScheduleOrderFlag && !isEmpty(scheduled) && scheduleArray.push(scheduled);
    const scheduledData = enableClickCollectScheduleOrderFlag ? scheduleArray : futureScheduled;
    const isUnavailableTimeslots =
      scheduledData?.length <= 1 && scheduledData?.find(element => !element?.periods?.length);
    const getDateTimeSlotsData = getDateTimeSlots(
      store,
      enableScheduleOrderFlag,
      enableClickCollectScheduleOrderFlag,
      fromReorderFlag,
      null,
      timeFormat,
      changeDateFormatOrder,
      isAsapDisabledForTenant,
    );
    const openStoreUnavailableScheduling = Boolean(isOpen && isUnavailableTimeslots);
    const closedStoreUnavailableScheduling = Boolean(!isOpen && isUnavailableTimeslots);
    return Boolean(
      openStoreUnavailableScheduling ||
        closedStoreUnavailableScheduling ||
        !getDateTimeSlotsData?.dateTimeSlotsArray?.length,
    );
  } else {
    return true;
  }
};

/**
 * getTimeSlotsWithOrderCapacity - checks for order capacity available for selected date's timeslots
 * @param orderCapacity - order capacity time slot object
 * @param storeId - selected store id
 * @param selectedDate - selected date object with timeslots
 * @param dateTimeArr - date time array object set directly from store data timings future scheduled
 * @return {Array} - new time slot array with isOrderCapacityAvailable added
 */
export const getTimeSlotsWithOrderCapacity = (orderCapacity, storeId, selectedDate, dateTimeArr) => {
  const dateTimeFormatted = formatDateTime(selectedDate?.date?.key, YYYYMMDD);
  const orderCapacityTimeSlots = orderCapacity?.[storeId]?.[dateTimeFormatted];
  const checkIfOrderCapacityAvailable = dateTimeFormatted && orderCapacity?.[storeId]?.[dateTimeFormatted];
  const dateTimeSlots = dateTimeArr
    ? dateTimeArr.find(schedule => schedule?.date?.key === selectedDate?.date?.key)
    : {};
  const selectedTimeSlots = dateTimeSlots ? dateTimeSlots?.timeSlots : selectedDate?.timeSlots || [];
  const newTimeSlotsArray = [];
  if (checkIfOrderCapacityAvailable && !isEmpty(selectedTimeSlots)) {
    for (const timeSlot of selectedTimeSlots) {
      const currentTimeSlot = timeSlot.value.replace(':', '');
      const currentOrderAvailableTimeSlot = orderCapacityTimeSlots[currentTimeSlot];
      // Explicitly check for 0 value so that empty values are set to true
      const currentTimeSlotOrdersEmpty = Number(currentOrderAvailableTimeSlot) === 0;
      if (orderCapacityTimeSlots[currentTimeSlot]) {
        const newTimeSlot = {
          ...timeSlot,
          isOrderCapacityAvailable: !currentTimeSlotOrdersEmpty,
        };
        newTimeSlotsArray.push(newTimeSlot);
      }
    }
  }
  return newTimeSlotsArray.length > 0 ? newTimeSlotsArray : selectedTimeSlots;
};

export const checkSelectedDateInStoreDates = ({ selectedDate, store }) => {
  const { summary } = store?.[0] || {};
  const { timings: { futureScheduled = [] } = {} } = summary || {};

  const dateInList = futureScheduled.find(({ date }) => date === selectedDate);

  return dateInList ? true : false;
};
