import React from 'react';
import PaymentBillingAndSavingCardTemplate from './PaymentBillingAndSavingCardTemplate';
const GlobalPaymentBillingandSaveTemplate = props => {
  return (
    <>
      <PaymentBillingAndSavingCardTemplate {...props} />
    </>
  );
};

export default GlobalPaymentBillingandSaveTemplate;
