import {
  addChangedListener,
  addErrorListener,
  addReadyListener,
  initializeFeatureFlags,
  closeHarnessSDK,
} from '@kfc-global/react-shared/config/Harness';
import {
  tenantConfigHarnessSuccess,
  tenantConfigUpdateError,
} from '@kfc-global/react-shared/redux/Actions/TenantAction';
import { HARNESS_KEY } from '@kfc-global/react-shared/config/config.constants';
import { config } from '@kfc-global/react-shared/config/config.utils';

import { GUEST, LOCAL_STORAGE_KEYS } from 'common/constants/SharedConstants';

export async function getFixedRandomNumber() {
  try {
    let fixedNumber = localStorage.getItem(LOCAL_STORAGE_KEYS.GUEST_USER_BUCKET);
    if (fixedNumber === undefined || fixedNumber === null || isNaN(fixedNumber)) {
      fixedNumber = Math.floor(Math.random() * 100) + 1;
      localStorage.setItem(LOCAL_STORAGE_KEYS.GUEST_USER_BUCKET, fixedNumber);
    }
    return Number(fixedNumber);
  } catch (error) {
    throw new Error(`getFixedRandomNumber exception ${error}`);
  }
}
export default async function subscribeToHarnessFeatureFlags(dispatch, customerId) {
  const harnessConfig = {
    featureFlagSDKKey: config(HARNESS_KEY),
    target: {
      identifier: customerId || `${GUEST.toUpperCase()}${await getFixedRandomNumber()}`,
      attributes: {
        lastUpdated: new Date().toLocaleString('en-GB', { timeZone: 'UTC' }),
        pwaVersion: process.env.REACT_APP_PWA_VERSION_STRING || '',
      },
    },
  };
  closeHarnessSDK();
  initializeFeatureFlags(harnessConfig);
  addReadyListener(flags => {
    addChangedListener(flagInfo => {
      dispatch(
        tenantConfigHarnessSuccess(flagInfo.deleted ? { [flagInfo.flag]: null } : { [flagInfo.flag]: flagInfo.value }),
      );
    });
    dispatch(tenantConfigHarnessSuccess(flags || {}));
  });
  addErrorListener(err => {
    dispatch(tenantConfigUpdateError(err));
  });
}
