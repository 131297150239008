import { toast } from 'react-toastify';

export const getToastNotification = (toastText, dismissTimeOut = 4000) => {
  toast.dark(
    <div className='img-text-div'>
      <span className={`notifyText notifyText-in`}>{toastText}</span>
    </div>,
    {
      bodyClassName: 'toast-for-favorite',
      hideProgressBar: true,
      position: toast.POSITION.BOTTOM_CENTER,
    },
  );
  setTimeout(() => {
    toast.dismiss();
  }, dismissTimeOut);
};
