import React, { useEffect } from 'react';
import { loadScript, redirectToPaymentFailurePage, redirectToOrderProcessingPage } from 'common/utilities/utils';
import { dgftApplePayGenerateToken, dgftAuthorizePayment } from '@kfc-global/react-shared/redux/Actions/PaymentsAction';
import { PAYMENT_TENDER_TYPES } from 'common/constants/SharedConstants';
import PAYMENT_CONSTANTS from 'organisms/Payment/constants/PaymentConstants';
import { config } from '@kfc-global/react-shared/config/config.utils';
import { DGFT_APPLE_PAY_SDK_URL } from '@kfc-global/react-shared/config/config.constants';
import { isEmpty } from 'lodash';
import { updateClickPlaceOrderState } from 'common/utilities/PaymentUtils';

const dgftApplePayGenerateTokenAction = ({ event, session, updateCheckoutDetails }) => {
  updateCheckoutDetails({
    dgftApplePaySession: session,
    dgftApplePayValidationURL: event.validationURL,
    dgftApplePayTriggerTokenAction: true,
  });
};

const dgftApplePayGenerateTokenActionDispatch = checkoutDetails => {
  checkoutDetails?.dispatch(
    dgftApplePayGenerateToken(
      {
        validationurl: { validationURL: checkoutDetails?.dgftApplePayValidationURL },
      },
      merchantSession => {
        checkoutDetails?.dgftApplePaySession.completeMerchantValidation(merchantSession);
      },
      error => {
        checkoutDetails?.dgftApplePaySession.abort();
      },
    ),
  );
};

const dgftAuthorizePaymentAction = ({ event, session, updateCheckoutDetails }) => {
  updateCheckoutDetails({
    dgftApplePaySession: session,
    dgftApplePaymentData: JSON.stringify(event.payment.token.paymentData),
    dgftApplePaymentInstrumentType: event?.payment?.token?.paymentMethod?.network,
    dgftApplePayTriggerAuthorizeAction: true,
  });
};
const dgftAuthorizePaymentActionDispatch = ({ checkoutDetails, props }) => {
  checkoutDetails?.dispatch(
    dgftAuthorizePayment(
      {
        paymentType: PAYMENT_TENDER_TYPES?.APPLE_PAY,
        data: {
          paymentreferencenumber: checkoutDetails?.DgftPaymentId,
          tenderType: checkoutDetails?.tender?.name,
          orderId: checkoutDetails?.orderId,
          amount: checkoutDetails?.finalAmount,
          transactionId: checkoutDetails?.DgftTransactionId,
          paymentData: checkoutDetails?.dgftApplePaymentData,
          PaymentInstrumentType: checkoutDetails?.dgftApplePaymentInstrumentType,
        },
      },
      param => {
        checkoutDetails?.dgftApplePaySession?.completePayment(
          PAYMENT_CONSTANTS?.DGFT_APPLE_PAY_CONSTANTS?.STATUS_SUCCESS,
        );
        redirectToOrderProcessingPage(props);
      },
      error => {
        checkoutDetails?.dgftApplePaySession?.completePayment(
          PAYMENT_CONSTANTS?.DGFT_APPLE_PAY_CONSTANTS?.STATUS_FAILURE,
        );
        redirectToPaymentFailurePage(props);
        checkoutDetails?.dgftApplePaySession?.abort();
      },
    ),
  );
};

const createApplePaySession = props => {
  const { checkoutDetails, updateCheckoutDetails } = props;
  if (!window?.ApplePaySession?.canMakePayments()) {
    console.log('Not capable browser');
    return;
  }

  const request = {
    countryCode: checkoutDetails?.countryCode,
    currencyCode: checkoutDetails?.currencyCode,
    supportedNetworks: PAYMENT_CONSTANTS?.DGFT_APPLE_PAY_CONSTANTS?.ALLOWED_CARD_NETWORKS,
    merchantCapabilities: PAYMENT_CONSTANTS?.DGFT_APPLE_PAY_CONSTANTS?.MERCHANT_CAPABILITIES,
    total: {
      label: PAYMENT_CONSTANTS?.DGFT_APPLE_PAY_CONSTANTS?.APPLEPAY,
      amount: checkoutDetails?.finalAmount?.toString(),
    },
  };
  const session = new window.ApplePaySession(
    PAYMENT_CONSTANTS?.DGFT_APPLE_PAY_CONSTANTS?.APPLE_PAY_VERSION_NUMBER,
    request,
  );
  session.onvalidatemerchant = event => dgftApplePayGenerateTokenAction({ event, session, updateCheckoutDetails });

  session.onpaymentauthorized = event => dgftAuthorizePaymentAction({ event, session, updateCheckoutDetails });

  session.begin();
};

const DgftApplepay = props => {
  const { checkoutDetails, updateCheckoutDetails } = props;

  useEffect(() => {
    loadScript(config(DGFT_APPLE_PAY_SDK_URL), 'dgft-applepay-script-id', () => {
      updateCheckoutDetails({ dgftApplePayScriptLoaded: true });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkoutDetails?.dgftApplePayTriggerTokenAction &&
      updateClickPlaceOrderState(checkoutDetails, updateCheckoutDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.dgftApplePayTriggerTokenAction]);

  useEffect(() => {
    !isEmpty(checkoutDetails?.paymentRequestedResponse) &&
      checkoutDetails?.DgftTransactionId &&
      dgftApplePayGenerateTokenActionDispatch(checkoutDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.paymentRequestedResponse, checkoutDetails?.DgftTransactionId]);

  useEffect(() => {
    checkoutDetails?.dgftApplePayTriggerAuthorizeAction &&
      dgftAuthorizePaymentActionDispatch({ checkoutDetails, props });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.dgftApplePayTriggerAuthorizeAction]);

  return (
    checkoutDetails?.dgftApplePayScriptLoaded && (
      <>
        <div
          id='dgftapplepayButton'
          style={{
            display: 'inline-block',
            '-webkit-appearance': '-apple-pay-button',
            '-apple-pay-button-type': 'buy',
            '-apple-pay-button-style': 'black',
          }}
          onClick={() => createApplePaySession(props)}
          data-testid='dgft-apple-pay'
          className='apple-pay'
        ></div>
      </>
    )
  );
};

export default DgftApplepay;
