import React from 'react';
import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { MaterialTextInput } from 'atoms/MaterialInput';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import { FORM_FIELD_DATA_TYPE } from 'common/constants/SharedConstants';
import isEmpty from 'lodash/isEmpty';

const cardNumberValidatorHandler = ({ cardNumber, cardType, errorMessages }) => {
  return;
};

const cvcValidatorHandler = ({ cvc, cardType, errorMessages }) => {
  return;
};

const ERROR_MESSAGES = () => ({
  emptyCardNumber: translateWithI18Next('enterValidCardNumber'),
  invalidCardNumber: translateWithI18Next('invalidCardNumber'),
  emptyExpiryDate: translateWithI18Next('enterValidCardExpiryDate'),
  invalidExpiryDate: translateWithI18Next('invalidExpiryDateFormat'),
  yearOutOfRange: translateWithI18Next('pastExpiryDate'),
  monthOutOfRange: translateWithI18Next('pastExpiryDate'),
  dateOutOfRange: translateWithI18Next('pastExpiryDate'),
  emptyCVC: translateWithI18Next('emptyCvv'),
  invalidCVC: translateWithI18Next('invalidCvv'),
});

const PaymentTextInput = props => {
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getCardImageProps } = usePaymentInputs({
    cardNumberValidator: cardNumberValidatorHandler,
    cvcValidator: cvcValidatorHandler,
    errorMessages: ERROR_MESSAGES(),
  });
  const { error, isTouched } = meta;
  const getPaymentInputName = params => {
    const onChangeHandler = event => {
      props?.isValid?.(error, isTouched, params);
      params?.onChange(event, params);
    };
    const onBlurHandler = event => {
      props?.isValid?.(error, isTouched, params);
      params?.onBlur(event, params);
    };
    const onFocusHandler = event => {
      props?.isValid?.(error, isTouched, params);
      params?.onFocus(event, params);
    };
    const onEventHandler = {
      onChange: onChangeHandler,
      onBlur: onBlurHandler,
      onTouch: onFocusHandler,
    };

    switch (params.dataType) {
      case FORM_FIELD_DATA_TYPE?.CARD_NUMBER:
        return {
          ...getCardNumberProps(onEventHandler),
        };
      case FORM_FIELD_DATA_TYPE?.CARD_CVV:
        return {
          ...getCVCProps(onEventHandler),
        };
      case FORM_FIELD_DATA_TYPE?.CARD_EXPIRY_DATE:
        return {
          ...getExpiryDateProps(onEventHandler),
        };
      default:
        return null;
    }
  };

  const refInputDetails = innerProps => {
    switch (innerProps?.dataType) {
      case FORM_FIELD_DATA_TYPE?.CARD_NUMBER:
        return getCardNumberProps();

      case FORM_FIELD_DATA_TYPE?.CARD_CVV:
        return getCVCProps();

      case FORM_FIELD_DATA_TYPE?.CARD_EXPIRY_DATE:
        return getExpiryDateProps();

      default:
        return null;
    }
  };

  const errorCheck = params => {
    props?.isValid?.(error, isTouched, props);
    return isEmpty(error) && isTouched;
  };

  return (
    <div className={`field material-input creditOrDbtDiv`}>
      <MaterialTextInput
        {...props}
        {...getPaymentInputName(props)}
        error={errorCheck(props)}
        helperText={meta?.error}
        helperTextClassName={'form-error mt-3 mb-3 form-error-validation-img'}
        errorIcon={IMAGE_PATH?.Ellipse}
        errorIconClassName={'d-flex '}
        innerRef={refInputDetails(props)?.ref}
        placeholder={props?.placeholder}
        dispalyErrorMessage={false}
      />

      {props.dataType === FORM_FIELD_DATA_TYPE?.CARD_NUMBER && (
        <svg {...getCardImageProps({ images })} className='creditOrDbtSvg' />
      )}
    </div>
  );
};

export default PaymentTextInput;
