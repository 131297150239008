import { AnalyticsService } from '@kfc-global/react-shared/analytics';
import events from '@kfc-global/react-shared/analytics/Register';

import {
  NOT_AVAILABLE,
  ANALYTICS_SHARED_CONSTANTS,
  DAYS,
  PICK_UP,
  PICKSPACEUP,
} from 'common/constants/SharedConstants';
import WELCOME_BACK_COSTANTS from 'organisms/WelcomeBackModal/Constants/Constants';
import { ChannelManager } from 'common/manager';
import {
  calculatePrice,
  getDataLayer,
  getUserType,
  getValueOrDefaultObject,
  getCouponDetails,
  isSupportedTenantCode,
  getTipSelection,
  getFirstLetterUpperCase,
  customizedProductParams,
  itemFilter,
  foodlineProducts,
} from 'common/utilities/utils';
import { displayTipAmount } from 'common/utilities/amountCalcUtils';
import { getDayFromDate, getFormatFromUnix } from 'common/utilities/dayJsUtils';

const getItemObj = ({
  transactionId,
  total,
  currencyConversionFactor,
  inclusiveTaxTotal,
  additionalChargeLines,
  currencyShortName,
  promoCode,
  orderItemsList,
  discountLines,
}) => {
  return {
    affiliation: ANALYTICS_SHARED_CONSTANTS.ONLINE_STORE,
    transaction_id: transactionId,
    value: total ? total / currencyConversionFactor : NOT_AVAILABLE,
    tax: inclusiveTaxTotal ? inclusiveTaxTotal / currencyConversionFactor : NOT_AVAILABLE,
    shipping:
      additionalChargeLines?.[0]?.chargeType === 'delivery-charge'
        ? additionalChargeLines?.[0]?.amount / currencyConversionFactor
        : NOT_AVAILABLE,
    currency: currencyShortName,
    coupon: promoCode || NOT_AVAILABLE,
    items: orderItemsList || NOT_AVAILABLE,
    promo_name: discountLines?.[0]?.promoCode || '',
  };
};

/*********************************************************************
 * updatePaymentAnalytics
 * Params - cartData, currencyConversionFactor, tender, categories
 *********************************************************************/
export const updatePaymentAnalytics = (cartData, currencyConversionFactor, tender, categories) => {
  if (!isSupportedTenantCode()) {
    const itemsArray = [];
    cartData?.foodLines?.forEach((itemDetails, index) => {
      const { id = NOT_AVAILABLE, name = NOT_AVAILABLE } =
        categories?.find(cat => cat.products.find(p => p?.name === itemDetails.item?.name)) || {};
      itemsArray.push({
        item_name: itemDetails?.item?.name || NOT_AVAILABLE,
        item_id: itemDetails?.item?.id || NOT_AVAILABLE,
        price: calculatePrice(itemDetails?.unitPrice, currencyConversionFactor),
        item_category: name,
        item_list_name: name,
        item_list_id: id,
        index: index + 1,
        quantity: itemDetails?.quantity || 0,
      });
    });
    paymentInfoClickAnalytics(tender?.name, itemsArray);
  }
};

export const logEvent = (name, content) => {
  AnalyticsService.registerEvent(name, content)?.then(() => console.log(`logEvent '${name}' successful`));
};

/*********************************************************************
 * guestContactDetailsClickAnalytics
 * Params - none
 *********************************************************************/
export const guestContactDetailsClickAnalytics = async () => {
  await AnalyticsService.registerEvent(
    events?.guestContactDetails?.name,
    {
      '%event%': 'guestContactDetails',
    },
    getDataLayer,
  );
};

/*********************************************************************
 * addContactInfoClickAnalytics
 * params - itemsArray
 *********************************************************************/
export const addContactInfoClickAnalytics = async itemsArray => {
  await AnalyticsService.registerEvent(
    events?.addContactInfo?.name,
    {
      data: {
        event: 'add_shipping_info',
        ecommerce: {
          items: itemsArray,
        },
      },
    },
    getDataLayer,
  );
};

/*********************************************************************
 * beginCheckoutClickAnalytics
 * params - itemsArray
 *********************************************************************/
export const beginCheckoutClickAnalytics = async itemsArray => {
  await AnalyticsService.registerEvent(
    events?.beginCheckoutPwa?.name,
    {
      data: {
        event: 'begin_checkout',
        ecommerce: {
          // checkout_cart: {
          items: itemsArray,
          // },
        },
      },
    },
    getDataLayer,
  );
};

export const beginCheckoutClickAnalyticsStep1 = async (itemsArray, userType, analyticsObject) => {
  await AnalyticsService.registerEvent(
    events?.beginCheckoutPwa?.name,
    {
      data: {
        event: ANALYTICS_SHARED_CONSTANTS.BEGIN_CHECKOUT,
        userType: userType,
        disposition: analyticsObject?.disposition,
        store_id: analyticsObject?.store_id,
        ecommerce: {
          items: itemsArray,
          coupon: analyticsObject?.coupon,
          value: analyticsObject?.total,
          currency: analyticsObject?.currency,
        },
      },
    },
    getDataLayer,
  );
};

export const RecommendedItemAnalytic = async (
  userType = '',
  itemsList,
  totalAmount = '',
  couponCode = '',
  currencyShortName = '',
) => {
  await AnalyticsService.registerEvent(
    events?.add_to_cart?.name,
    {
      data: {
        event: ANALYTICS_SHARED_CONSTANTS.ADD_TO_CART,
        userType,
        ecommerce: {
          currency: currencyShortName,
          value: totalAmount,
          items: itemsList,
          coupon: couponCode,
        },
      },
    },
    getDataLayer,
  );
};

export const RemoveItemAnalytic = async (userType, itemsList, totalAmount) => {
  await AnalyticsService.registerEvent(
    events?.remove_from_cart?.name,
    {
      data: {
        event: ANALYTICS_SHARED_CONSTANTS.REMOVE_TO_CART,
        userType,
        ecommerce: {
          currency: itemsList?.[0]?.currency,
          value: totalAmount,
          items: itemsList,
        },
      },
    },
    getDataLayer,
  );
};

/*********************************************************************
 * paymentInfoClickAnalytics
 * param - paymentType, itemsArray
 *********************************************************************/
export const paymentInfoClickAnalytics = async ({
  tender,
  itemsArray,
  couponCode,
  currencyShortName,
  value,
  userType,
}) => {
  await AnalyticsService.registerEvent(
    events?.paymentInfoClick?.name,
    {
      data: {
        event: 'add_payment_info',
        userType: userType,
        ecommerce: {
          coupon: couponCode,
          value: value,
          currency: currencyShortName,
          payment_type: tender,
          items: itemsArray,
        },
      },
    },
    getDataLayer,
  );
};

/*********************************************************************
 * checkoutDetailsClickAnalytics
 * params - none
 *********************************************************************/
export const checkoutDetailsClickAnalytics = async () => {
  await AnalyticsService.registerEvent(
    events?.checkoutClick?.name,
    {
      data: {
        event: 'checkoutClick',
      },
    },
    getDataLayer,
  );
};
/*********************************************************************
 * purchaseOrderDetailsInfo
 * params - itemObj
 *********************************************************************/
export const purchaseOrderDetailsInfo = async params => {
  const {
    userType,
    itemObj,
    getBottleDepositChargeTotal,
    tipChargeType,
    tipCharge,
    currencySymbol,
    currencyConversionFactor,
    driverTippingChargeOption,
    orderingDateTime,
    storeId,
    storeName,
    state,
    payment_type,
    service,
  } = params;
  await AnalyticsService.registerEvent(
    events?.purchaseOrder?.name,
    {
      data: {
        event: 'purchase',
        userType: userType,
        tip_selection: tipChargeType
          ? getTipSelection({
              tipChargeType,
              tipCharge,
              currencySymbol,
              currencyConversionFactor,
              driverTippingChargeOption,
            })
          : '',
        tip_amount: tipChargeType ? displayTipAmount(tipCharge, currencySymbol, currencyConversionFactor) : '',
        bottle_deposit: getBottleDepositChargeTotal || NOT_AVAILABLE,
        storeID: storeId,
        storeName,
        payment_type,
        state,
        orderTime: getFormatFromUnix(orderingDateTime, 'HH:mm'),
        orderHour: parseInt(getFormatFromUnix(orderingDateTime, 'H')),
        disposition: service?.toLowerCase(),
        purchase_day: DAYS[getDayFromDate(orderingDateTime, 'X')]?.toLowerCase(),
        ecommerce: itemObj || {},
      },
    },
    getDataLayer,
  );
};

/*********************************************************************
 * updatePurchaseOrderDetailsAnalytics
 * param - additionalChargeLines,    currencyCode,    currencyConversionFactor,    discountLines,    foodLines,    paymentReferences,    total,    taxTotal,
 *********************************************************************/
export const updatePurchaseOrderDetailsAnalytics = params => {
  const {
    additionalChargeLines,
    currencyConversionFactor,
    foodLines,
    paymentReferences,
    total,
    inclusiveTaxTotal,
    userStatus,
    bottleDepositChargeTotal,
    tipCharge,
    tipChargeType,
    subTotal,
    currencySymbol,
    currencyShortName,
    driverTippingChargeOption,
    store,
    storeName,
    tenders,
    postOrderDetails,
    orderingDateTime,
    discountLines,
  } = params?.orderDetails || {};
  const userOrderState = params?.userOrderState || {};
  const { promoCode = '', discountTotal = '' } = getCouponDetails(params?.orderDetails);
  const { localized = false, loggedIn = false } = getValueOrDefaultObject(userStatus);
  const { storeId = '', address = {} } = store;
  const { state = '' } = address || {};
  const payment_type = tenders?.[0]?.type || NOT_AVAILABLE;
  const { service = '' } = postOrderDetails || {};
  const userType = getUserType(localized, loggedIn);
  const orderItemsList = [];
  const getBottleDepositChargeTotal = (bottleDepositChargeTotal / currencyConversionFactor).toFixed(2);
  foodLines?.forEach((foodLineItem, idx) => {
    let { baseItemsCstmz, saucesCstmz, sidesCstmz, drinksCstmz } = foodlineProducts(foodLineItem);
    baseItemsCstmz = itemFilter(baseItemsCstmz);
    saucesCstmz = itemFilter(saucesCstmz);
    sidesCstmz = itemFilter(sidesCstmz);
    drinksCstmz = itemFilter(drinksCstmz);
    const itemVariant = [];
    foodLineItem?.item?.modGrps?.forEach(group => {
      group?.modifiers.forEach(modifiers => {
        itemVariant.push(modifiers?.name);
      });
    });
    orderItemsList?.push({
      currency: currencyShortName,
      discount: discountTotal / currencyConversionFactor,
      item_name: foodLineItem?.item?.dname?.[0]?.value || NOT_AVAILABLE,
      item_id: foodLineItem?.item?.id || NOT_AVAILABLE,
      price: foodLineItem?.item?.unitPrice / currencyConversionFactor || NOT_AVAILABLE,
      item_variant: itemVariant.join(', ') || NOT_AVAILABLE,
      quantity: foodLineItem?.quantity || NOT_AVAILABLE,
      item_category: foodLineItem?.item?.categoryName || NOT_AVAILABLE,
      item_list_name: foodLineItem?.item?.categoryName || NOT_AVAILABLE,
      item_list_id: foodLineItem?.item?.categoryId || NOT_AVAILABLE,
      product_tags: foodLineItem?.item?.content?.allergenList || NOT_AVAILABLE,
      index: idx,
      originalPrice: (foodLineItem?.item?.unitPrice / currencyConversionFactor).toString() || NOT_AVAILABLE,
      coupon: promoCode || NOT_AVAILABLE,
      baseItemsCstmz: baseItemsCstmz || '',
      saucesCstmz: saucesCstmz || '',
      sidesCstmz: sidesCstmz || '',
      drinksCstmz: drinksCstmz || '',
    });
  });

  const transactionId =
    ChannelManager?.shouldGoogleSpotIgnoreOloFailure && ChannelManager?.transactionId
      ? ChannelManager?.transactionId
      : paymentReferences?.[0]?.transactionId ?? NOT_AVAILABLE;

  const paramsObj = {
    userType,
    itemObj: getItemObj({
      transactionId,
      total,
      currencyConversionFactor,
      inclusiveTaxTotal,
      additionalChargeLines,
      currencyShortName,
      promoCode,
      orderItemsList,
      discountLines,
    }),
    getBottleDepositChargeTotal,
    tipChargeType,
    tipCharge,
    subTotal,
    currencySymbol,
    currencyConversionFactor,
    driverTippingChargeOption,
    storeId,
    storeName,
    state,
    payment_type,
    service,
    orderingDateTime,
  };
  purchaseOrderDetailsInfo(paramsObj);
};

/**
 * Method to set analytics items in the data layer object
 */
export const setLocalisationFinishAnalyticsData = async payload => {
  const despositionStatus = getFirstLetterUpperCase(payload?.disposition);
  await AnalyticsService.registerEvent(
    events?.localisationFinish?.name,
    {
      '%event%': ANALYTICS_SHARED_CONSTANTS.LOCALISATION_FINISH,
      '%disposition%': despositionStatus === getFirstLetterUpperCase(PICK_UP) ? PICKSPACEUP : despositionStatus,
      '%postLocalisation%': 'true',
      '%storeID%': payload?.storeID,
      '%postCode%': payload?.postCode,
      '%localisationType%': ANALYTICS_SHARED_CONSTANTS.LOCALISATION_TYPE.MANUAL,
      '%city%': payload?.city,
      '%storeName%': payload?.storeName,
      '%state%': payload?.state,
    },
    getDataLayer,
  );
};

/**
 * Method to set localisationStart items in the data layer object
 */
export const localisationStartAnalytics = async () => {
  await AnalyticsService.registerEvent(
    events?.localisationStart?.name,
    {
      '%event%': 'localisationStart',
    },
    getDataLayer,
  );
};

export const GuestContactDetailsClick = async () => {
  return guestContactDetailsClickAnalytics();
};

export const AddContactInfoClick = async itemsArray => {
  return addContactInfoClickAnalytics(itemsArray);
};

export const BeginCheckoutClick = async (itemsArray, analyticsObject) => {
  await AnalyticsService.registerEvent(
    events?.beginCheckoutPwa?.name,
    {
      data: {
        event: 'begin_checkout',
        userType: analyticsObject?.userType,
        disposition: analyticsObject?.disposition,
        store_id: analyticsObject.store_id,
        ecommerce: {
          items: itemsArray,
          coupon: analyticsObject?.coupon,
          value: analyticsObject?.total,
          currency: analyticsObject?.currency,
        },
      },
    },
    getDataLayer,
  );
};

export const PaymentInfoClick = async ({ tender, itemsArray, couponCode, currencyShortName, value, userType }) => {
  return paymentInfoClickAnalytics({
    tender: tender?.name,
    itemsArray,
    couponCode,
    currencyShortName,
    value,
    userType,
  });
};

export const CheckoutDetailsClick = async () => {
  await AnalyticsService.registerEvent(
    events?.checkoutClick?.name,
    {
      data: {
        event: 'checkoutClick',
      },
    },
    getDataLayer,
  );
};
export const onCheckoutAnalytics = async (userType, itemsArray) => {
  await AnalyticsService.registerEvent(
    events?.paymentInfoClick?.name,
    {
      data: {
        event: ANALYTICS_SHARED_CONSTANTS.ADD_PAYMENT,
        userType: userType,
        ecommerce: {
          // checkout_cart: {
          items: itemsArray,
          // },
        },
      },
    },
    getDataLayer,
  );
};

export const paymentAnalytics = async (paymentType, userType, itemsArray) => {
  await AnalyticsService.registerEvent(
    events?.paymentInfoClick?.name,
    {
      data: {
        event: ANALYTICS_SHARED_CONSTANTS.ADD_PAYMENT,
        userType: userType,
        payment_type: paymentType,
        ecommerce: {
          // checkout_cart: {
          items: itemsArray,
          // },
        },
      },
    },
    getDataLayer,
  );
};

export const loadContentCardAnalytics = async () => {
  await AnalyticsService.registerEvent(
    'messageWrapperLoaded',
    {
      '%event%': 'messageWrapperLoaded',
    },
    getDataLayer,
  );
};

export const viewCartAalytics = async data => {
  await AnalyticsService.registerEvent(
    events?.viewCart?.name,
    {
      data: {
        event: 'view_cart',
        ecommerce: {
          items: data,
        },
      },
    },
    getDataLayer,
  );
};

export const setAnalyticsEvent = async ({
  collectionPoint = 'NA',
  disposition = 'NA',
  loggedIn = false,
  postLocalisation = false,
  storeID = 'NA',
  UIDNO = '',
  language = 'NA',
  userType = 'NA',
  postCode = 'NA',
}) => {
  await AnalyticsService.registerEvent(
    events?.setAttributes?.name,
    {
      data: {
        event: 'setAttributes',
        collectionPoint: collectionPoint,
        disposition: disposition,
        loggedIn: loggedIn,
        postLocalisation: postLocalisation,
        storeID: storeID,
        user_id: UIDNO,
        platform: 'web',
        language: language,
        userType: userType,
        postCode: postCode,
      },
    },
    getDataLayer,
  );
};

export const updateCheckInEventAnalytics = async ({
  userType = '',
  tip_selection = '',
  tip_amount = '',
  bottle_deposit = '',
  storeID = '',
  payment_type = '',
  state = '',
  orderTime = '',
  CheckInTime = '',
  checkInlocation = '',
  eventType = 2,
  disposition = '',
  purchase_day = '',
  transaction_id = '',
}) => {
  await AnalyticsService.registerEvent(
    events?.checkIn?.name,
    {
      data: {
        event: 'checkIn',
        userType: userType,
        tip_selection: tip_selection,
        tip_amount: tip_amount,
        bottle_deposit: bottle_deposit,
        storeID: storeID,
        payment_type: payment_type,
        state: state,
        orderTime: orderTime,
        CheckInTime: CheckInTime,
        checkInlocation: checkInlocation,
        eventType: eventType,
        disposition: disposition,
        purchase_day: purchase_day,
        transaction_id: transaction_id,
      },
    },
    getDataLayer,
  );
};

export const getkeepOrderAnalytic = async payload => {
  const { keepOrder, analyticsData } = payload;
  await AnalyticsService.registerEvent(
    events?.keepOrderSettings?.name,
    {
      data: {
        event: WELCOME_BACK_COSTANTS.KEEP_ORDER_SETTINGS,
        keepOrder,
        ...analyticsData,
      },
    },
    getDataLayer,
  );
};

export const keepOrderAnalyticFn = keepOrder => isSupportedTenantCode() && getkeepOrderAnalytic(keepOrder);

export const navigationLinkClickEvent = async name => {
  await AnalyticsService.registerEvent(
    events?.navigationLinkClick?.name,
    {
      data: {
        event: 'navigationLinkClick',
        navigationLink: name,
      },
    },
    getDataLayer,
  );
};

export const guestContactClickAnalytics = async (subsCheckbox, marketingEmails, receiveSMS, showtnC = true) => {
  if (showtnC && marketingEmails !== undefined && receiveSMS !== undefined) {
    await AnalyticsService.registerEvent(
      events?.guestContactDetails?.name,
      {
        '%event%': 'guestContactDetails',
        '%marketingEmails%': subsCheckbox ? marketingEmails : false,
        '%receiveSMS%': subsCheckbox ? receiveSMS : false,
      },
      getDataLayer,
    );
  }
};

export const viewMenuAnalytics = async () => {
  await AnalyticsService.registerEvent(
    events?.viewMenu?.name,
    {
      data: {
        event: 'viewMenu',
      },
    },
    getDataLayer,
  );
};

/*********************************************************************
 * menuNavigationClickAnalytics
 * Params - name, index
 *********************************************************************/
export const menuNavigationClickAnalytics = async (name, index) => {
  await AnalyticsService.registerEvent(
    events?.menuNavigation?.name,
    {
      '%event%': 'menuNavigation',
      '%menuNavigationLink%': name,
      '%menuIndex%': index,
    },
    getDataLayer,
  );
};

/*********************************************************************
 * addToFavoriteAnalytics
 * Params - itemsArray, analyticsObject
 *********************************************************************/

export const addToFavoriteAnalytics = async (itemsArray, analyticsObject) => {
  await AnalyticsService.registerEvent(
    events?.navigationLinkClick?.name,
    {
      data: {
        event: ANALYTICS_SHARED_CONSTANTS?.ADD_TO_FAVORITE,
        userType: analyticsObject?.userType,
        ecommerce: {
          items: itemsArray,
          coupon: analyticsObject?.coupon,
          value: analyticsObject?.value,
          currency: analyticsObject?.currency,
        },
      },
    },
    getDataLayer,
  );
};

/*********************************************************************
 * orderlaterClickAnalytics
 * Params - dispositionType
 *********************************************************************/
export const orderlaterClickAnalytics = async dispositionType => {
  await AnalyticsService.registerEvent(
    events?.orderLater?.name,
    {
      '%event%': 'orderLater',
      '%disposition%': dispositionType,
    },
    getDataLayer,
  );
};
