export const MARKETING_HERO_CONSTANTS = {
  YES_CONSTANT: 'YES',
  NO_CONSTANT: 'NO',
  SLIDE_CHANGE_INTERVAL: 3,
  LIGHT: 'LIGHT',
  BLACK_COLOR: '#202124',
  WHITE_COLOR: '#ffffff',
  CAROUSELIMAGECLICK: 'carouselImgClick',
  daysOfTheWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  localizedUserSampleData: { type: '', store: { id: '', name: [{ value: '' }] } },
  DISPOSITION_DATA: 'dispositionData',
  VIDEO: 'Video',
  VIDEO_COMP: 'videoComp',
  IMAGE: 'Image',
  TRANSPARENT: 'Transparent',
  FULLBLEED: 'Full Bleed',
  SELECTED_TITLE: 'selectedTile',
  TENANT_REDUCER: 'tenantReducer',
  WHITE_COLOR_PROPERTY: 'WHITE',
  SELECTED_TILE: 'selectedTile',
};
