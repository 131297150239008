import React, { useSelector } from 'react-redux';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import CartAlertFormInsideModel from 'molecules/Alert/Container/AlertFormInsideModel';
import CartAlertForm from 'molecules/Alert/Presentation/Alert/AlertForm';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { OPEN } from 'common/constants/SharedConstants';
import {
  closeModalHandler,
  scheduleOrderHandler,
  orderHandler,
} from 'molecules/ScheduleAlert/AU/utils/ScheduleAlertUtils';
export const ScheduleAlert = props => {
  const {
    storeInfo,
    storeStatus,
    history,
    userOrderStateDispatch,
    dispatch,
    tenantId,
    cartDataId,
    isAsapFlagAvailable,
    enableClickCollectScheduleOrderFlag = false,
    isTemproryClosed = false,
    scheduleTimeCheck = false,
  } = props;
  const { componentOptions = {}, enableOrderCapacityManagement } = useSelector(data => data?.tenantReducer) || {};
  const { ORDER_TIME_PASSED_TITLE, STORE_NOT_SERVING_CURRENTLY } = TRANSLATE_MAPPING_KEY;
  const { showOrderCapacityOrderAsapAlertCta = true } = componentOptions?.web?.cartPage || {};
  return (
    <>
      <CartAlertFormInsideModel
        handleClose={() => closeModalHandler(userOrderStateDispatch)}
        classNames='clear-cart-modal item-unavailable cart-checkout-alert schedule-time-lapsed'
        CartAlertForm={
          <CartAlertForm
            title={
              enableClickCollectScheduleOrderFlag && isTemproryClosed && !scheduleTimeCheck
                ? translateWithI18Next(STORE_NOT_SERVING_CURRENTLY)
                : translateWithI18Next(ORDER_TIME_PASSED_TITLE)
            }
            contentMsg={translateWithI18Next(TRANSLATE_MAPPING_KEY.ORDER_TIME_PASSED_MSG)}
            imageSrc={IMAGE_PATH.Timeout_Red}
            altText={''}
            confirmButtonText={translateWithI18Next(TRANSLATE_MAPPING_KEY.RESCHEDULE_ORDER_TIME_BUTTON)}
            confirmHandler={event =>
              scheduleOrderHandler(event, userOrderStateDispatch, storeInfo, enableOrderCapacityManagement)
            }
            cancelHandler={
              storeStatus === OPEN && isAsapFlagAvailable && showOrderCapacityOrderAsapAlertCta
                ? () => orderHandler(userOrderStateDispatch, history, dispatch, tenantId, cartDataId)
                : ''
            }
            cancelButtonText={translateWithI18Next(TRANSLATE_MAPPING_KEY.ORDER_ASAP)}
          />
        }
      />
    </>
  );
};
