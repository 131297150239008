import { orderReadyDateTimeUpdate } from '@kfc-global/react-shared/redux/Actions/StoresAction';
import { LOCAL_STORAGE_KEYS, ASAP, DATE_FORMATS } from 'common/constants/SharedConstants';
import { redirectToCARTPage, triggerCartModel } from 'common/utilities/utils';
import START_ORDER_CONSTANTS from 'organisms/StartOrderComponent/Constants/startOrderConstants';
import { setChangeOrderAnalyticsData } from 'organisms/StartOrderComponent/StartOrderUtilities/startOderUtils';
import { formatDateTime } from 'common/utilities/dayJsUtils';

export const closeModalHandler = userOrderStateDispatch => {
  userOrderStateDispatch({
    type: START_ORDER_CONSTANTS.SCHEDULE_MODAL_OPEN,
    scheduleModalOpen: false,
  });
  userOrderStateDispatch({
    type: START_ORDER_CONSTANTS.PLACE_ORDER_BUTTON,
    placeOrderButtonEnabled: false,
  });
};

export const scheduleOrderHandler = (event, userOrderStateDispatch, storeInfo, enableOrderCapacityManagement) => {
  event?.preventDefault();
  event?.stopPropagation();
  userOrderStateDispatch({
    type: START_ORDER_CONSTANTS.SCHEDULE_MODAL_OPEN,
    scheduleModalOpen: false,
  });
  userOrderStateDispatch({ type: START_ORDER_CONSTANTS.SET_FIND_A_KFC_VIEW, value: { findAKFC: false } });
  userOrderStateDispatch({ type: START_ORDER_CONSTANTS.SEARCH_ORDER_DATA, value: { showSearchStore: false } });
  userOrderStateDispatch({
    type: START_ORDER_CONSTANTS.SCHEDULE_ORDER_DATA,
    value: {
      changeFlow: true,
      orderCapacityDataUpdated: !enableOrderCapacityManagement,
      selectedStoreInfo: storeInfo,
      showScheduleOrderCatering: true,
    },
  });
  setChangeOrderAnalyticsData();
  userOrderStateDispatch({
    type: START_ORDER_CONSTANTS.CHANGE_SCHEDULE_MODE,
    value: { changeScheduleModeFlag: false },
  });
  userOrderStateDispatch({
    type: START_ORDER_CONSTANTS.SCHEDULE_ORDER_CLICKED,
    value: { scheduleModeClicked: true },
  });
  userOrderStateDispatch({
    type: START_ORDER_CONSTANTS.SET_ORDER_NOW,
    value: { showOrderNow: false },
  });
  userOrderStateDispatch({
    type: START_ORDER_CONSTANTS.SET_SCHEDULE_ORDER,
    value: { showScheduleOrder: true },
  });
  userOrderStateDispatch({
    type: START_ORDER_CONSTANTS.SHOW_SCHEDULE_DISPOSITION,
    value: { showSheduleDisposition: true },
  });
  userOrderStateDispatch({
    type: START_ORDER_CONSTANTS.CURRENT_STORE_INFO,
    value: storeInfo,
  });
};

export const orderHandler = (userOrderStateDispatch, history, dispatch, tenantId, cartDataId) => {
  const dispositionNew = JSON.parse(localStorage?.getItem(LOCAL_STORAGE_KEYS.DISPOSITION_NEW));
  userOrderStateDispatch({
    type: START_ORDER_CONSTANTS.SCHEDULE_MODAL_OPEN,
    scheduleModalOpen: false,
  });
  userOrderStateDispatch({
    type: START_ORDER_CONSTANTS?.SET_DISPOSITION_NEW,
    value: { dispositionNew: { ...dispositionNew, date: ASAP, selectedTime: ASAP, time: ASAP, epochTime: ASAP } },
  });
  userOrderStateDispatch({
    type: START_ORDER_CONSTANTS.SET_ORDER_NOW,
    value: { showOrderNow: true },
  });
  userOrderStateDispatch({
    type: START_ORDER_CONSTANTS.SET_SCHEDULE_ORDER,
    value: { showScheduleOrder: false },
  });
  userOrderStateDispatch({ type: START_ORDER_CONSTANTS.SET_ASAP_SCHEDULING, value: { enableASAPScheduling: true } });
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.DISPOSITION_NEW,
    JSON.stringify({
      ...dispositionNew,
      date: formatDateTime(new Date(), DATE_FORMATS.YYYY_MM_DD),
      selectedTime: ASAP,
      time: ASAP,
      epochTime: ASAP,
    }),
  );
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.DATE_TIME_ORDER,
    JSON.stringify({ date: '', selectedTime: ASAP, time: ASAP, epochTime: ASAP }),
  );
  const postBody = {
    isForNow: true,
    futureDateTime: 0,
  };
  if (cartDataId) {
    dispatch(
      orderReadyDateTimeUpdate({
        params: { basketId: cartDataId },
        tenantId: tenantId,
        postBody: postBody,
      }),
    );
  }
  redirectToCARTPage(history);
};

export const storeClosedOnClickBtnHandler = (event, userOrderStateDispatch) => {
  event.preventDefault();
  event.stopPropagation();
  userOrderStateDispatch({
    type: START_ORDER_CONSTANTS.SCHEDULE_MODAL_OPEN,
    scheduleModalOpen: false,
  });
  triggerCartModel(userOrderStateDispatch, localStorage.getItem(LOCAL_STORAGE_KEYS.RETAIN_ADDRESS));
};
