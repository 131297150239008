const WELCOME_BACK_COSTANTS = {
  YES_KEEP_IT: 'yesKeepIt',
  ORDER_FOR: 'orderFor',
  WELCOME_BACK: 'welcomeBackText',
  CHANGE_BTN: 'change',
  SCHEDULE_ORDER_DATA: 'scheduleOrderData',
  PREVIOUS_ORDER_SETTING_MSG: 'previousOrderDataSettingMsg',
  KEEP_IT: 'Keep It',
  CANCEL: 'Cancel',
  KEEP_ORDER_SETTINGS: 'keepOrderSettings',
  CHANGE: 'Change',
};

export default WELCOME_BACK_COSTANTS;
