import React, { useEffect } from 'react';
import { PAYMENT_TENDER_TYPES } from 'common/constants/SharedConstants';
import { redirectToOrderProcessingPage } from 'common/utilities/utils';
import { getPaymentOptionsTemplate } from './templates/PaymentViewTemplates';
import { dispatchPayUsingNetbanking, paymentTrancationSuccessHandler } from 'common/utilities/PaymentUtils';
import PayGateCreditDebitCardHiddenForm from '../hiddenForms/PayGateCreditDebitCardHiddenForm';

const PayGatePaymentView = props => {
  const { checkoutDetails, updateCheckoutDetails } = props;
  const {
    paymentsReducerResponse,
    savedCards,
    isClickAddPayment,
    lastUsedPaymentMethod,
    isOrderWithoutPayment,
    total,
  } = checkoutDetails;

  /*********************************************************************
   *
   *
   *********************************************************************/
  useEffect(() => {
    if (!(isOrderWithoutPayment && total === 0) && isClickAddPayment && savedCards?.length > 0) {
      updateCheckoutDetails({
        isClickAddPayment: true,
        tender: { name: PAYMENT_TENDER_TYPES.CREDIT_CARD, payload: { tender: PAYMENT_TENDER_TYPES.CREDIT_CARD } },
        cardid: savedCards?.[0]?.id,
        isPaymentTypeSelected: true,
      });
    } else if (isClickAddPayment && lastUsedPaymentMethod && !(isOrderWithoutPayment && total === 0)) {
      updateCheckoutDetails({
        isClickAddPayment: true,
        tender: {
          name: lastUsedPaymentMethod,
          payload: { tender: lastUsedPaymentMethod },
        },
        isPaymentTypeSelected: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClickAddPayment, lastUsedPaymentMethod, isOrderWithoutPayment]);

  /*********************************************************************
   *
   *
   *********************************************************************/
  useEffect(() => {
    checkoutDetails?.isUpdatedOrderActionRequest && updateCheckoutDetails({ dispatchOrderAction: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isUpdatedOrderActionRequest]);

  /*********************************************************************
   *
   *
   *********************************************************************/
  useEffect(() => {
    if (checkoutDetails?.isCreateOrderValidateCompleted && checkoutDetails?.orderId) {
      switch (checkoutDetails?.tender?.name) {
        case PAYMENT_TENDER_TYPES?.CREDIT_CARD:
          dispatchPayUsingNetbanking(checkoutDetails);
          break;
        case PAYMENT_TENDER_TYPES.PAY_BY_CASH:
          paymentTrancationSuccessHandler(props);
          break;
        // no default
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isCreateOrderValidateCompleted]);

  /*********************************************************************
   *
   *
   *********************************************************************/
  useEffect(() => {
    paymentsReducerResponse?.redirectUri &&
      updateCheckoutDetails({ enableSinglePayment: false, isPaymentTransationCompleted: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsReducerResponse?.redirectUri]);

  /*********************************************************************
   *
   * IF enable single click payment, it will call
   *********************************************************************/
  useEffect(() => {
    paymentsReducerResponse?.envelope?.body?.singlePaymentResponse?.cardPaymentResponse &&
      updateCheckoutDetails({ enableSinglePayment: true, isPaymentTransationCompleted: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsReducerResponse?.envelope?.body?.singlePaymentResponse?.cardPaymentResponse]);

  /*********************************************************************
   *
   * IF enable single click payment, it will call
   *********************************************************************/
  useEffect(() => {
    checkoutDetails?.orderId &&
      checkoutDetails?.tender?.name &&
      checkoutDetails?.isPaymentTransationCompleted &&
      checkoutDetails?.enableSinglePayment &&
      redirectToOrderProcessingPage(props);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isPaymentTransationCompleted, checkoutDetails?.enableSinglePayment]);

  const params = { checkoutDetails, updateCheckoutDetails };

  return (
    <>
      {/* Payment view */}
      {getPaymentOptionsTemplate(params)}

      {/* Hidden Forms */}
      <div className='hidden'>
        {/* Redirect to paygate URL */}
        {checkoutDetails?.isPaymentTransationCompleted && paymentsReducerResponse?.redirectUri && (
          <PayGateCreditDebitCardHiddenForm formData={paymentsReducerResponse} />
        )}
      </div>
    </>
  );
};

export default React.memo(PayGatePaymentView);
