const modulator = async () => {
  const tenantCode = process?.env?.REACT_APP_TENANT_CODE;
  const dynamicImportConfigPath = await import('common/config/dynamicImportConfig');
  return dynamicImportConfigPath.default[tenantCode.toUpperCase()].HOME_PAGE_CONSTANTS;
};

export let HOME_PAGE_CONSTANT_DATA;
(async () => {
  const modulatordata = await modulator();
  HOME_PAGE_CONSTANT_DATA = modulatordata.default;
})();
