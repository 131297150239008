import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FORM_FIELD_TYPE } from 'common/constants/SharedConstants';
import { defaultElmentConfig, phoneTileComponent, updateFormElementConfig } from '../Utils/FormComponentUtils';

const FormComponent = props => {
  const { formConfig } = props;
  const {
    fieldsConfig,
    isFormReadOnly,
    isFormValid: isDefaultFormValid,
    defaultValues: formDefaultValues,
    name,
    onFormLoadedCompleted,
    enableHiddenFields,
    enableDefaultDirtyFields,
    enablePhoneTile,
  } = formConfig;

  const {
    control,
    formState: { isValid: isFormValid, isLoading, isDirty, dirtyFields, touchedFields, defaultValues, errors },
    getValues,
    setValue,
    setError,
    clearErrors,
    getFieldState,
    reset,
    resetField,
    trigger,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: formDefaultValues,
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
    criteriaMode: 'all',
    shouldFocusError: true,
    shouldUnregister: true,
    isValid: isDefaultFormValid,
    name,
  });

  const getFormConfig = config => ({
    ...config,
    isFormReadOnly,
    isDirty,
    isLoading,
    dirtyFields,
    touchedFields,
    defaultValues,
    errors,
    isFormValid,
    formDefaultValues,
    name,
    control,
    getValueFromElements: fieldName => getValues(fieldName),
    setValueToFormElements: (fieldName, fieldValue, params) => {
      setValue(fieldName, fieldValue, params);
      trigger();
    },
    setErrorToFormElements: (fieldName, errorType, errorMessage) =>
      setError(fieldName, { type: errorType, message: errorMessage }),
    clearErrorToFormElements: fieldName => {
      clearErrors(fieldName);
      trigger();
    },
    getStateFromFormField: fieldName => getFieldState(fieldName),
    resetFormValues: (fieldData, prop) => reset(fieldData, prop),
    resetFormFieldValues: (fieldData, prop) => resetField(fieldData, prop),
    revalidateForm: fieldDetails => trigger(fieldDetails),
  });
  useEffect(() => {
    const config = getFormConfig(formConfig);
    config?.onFormChangeHandler?.({ ...config, isFormValid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormValid]);

  useEffect(() => {
    onFormLoadedCompleted && onFormLoadedCompleted(getFormConfig(formConfig));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading && enableDefaultDirtyFields) {
      trigger();
      fieldsConfig?.map?.(
        config =>
          config?.type === FORM_FIELD_TYPE?.INPUT &&
          setValue?.(config?.name, undefined, { shouldDirty: true, shouldTouch: true }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    enableHiddenFields &&
      !fieldsConfig?.some?.(data => data?.name === 'hiddenField') &&
      fieldsConfig?.push({
        name: 'hiddenField',
        value: '',
        type: FORM_FIELD_TYPE?.LABEL,
        className: 'hidden',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsConfig]);

  return (
    <>
      {enablePhoneTile
        ? phoneTileComponent(fieldsConfig, formConfig, getFormConfig)
        : fieldsConfig?.map?.(config =>
            updateFormElementConfig(getFormConfig(formConfig), {
              ...defaultElmentConfig,
              ...config,
            }),
          )}
    </>
  );
};

FormComponent.propTypes = {
  formConfig: {
    fieldsConfig: PropTypes.object,
    isFormReadOnly: PropTypes.bool,
    isFormValid: PropTypes.bool,
    onFormChangeHandler: PropTypes.func,
    defaultValues: PropTypes.object,
    name: PropTypes.string,
    onFormLoadedCompleted: PropTypes.func,
    enableHiddenField: PropTypes.bool,
    enableDefaultDirtyFields: PropTypes.bool,
    enablePhoneTile: PropTypes.bool,
  },
};

FormComponent.defaultProps = {
  formConfig: {
    fieldsConfig: [],
    isFormReadOnly: false,
    isFormValid: false,
    onFormChangeHandler: null,
    defaultValues: {},
    name: '',
    onFormLoadedCompleted: null,
    enableHiddenField: false,
    enableDefaultDirtyFields: false,
    enablePhoneTile: false,
  },
};

export default React.memo(FormComponent);
