import React, { useEffect } from 'react';
import { getPaymentOptionsTemplate } from './templates/PaymentViewTemplates';
import { useSelector } from 'react-redux';
import CanalPaymentHiddenForm from '../hiddenForms/CanalPaymentHiddenForm';
import { redirectToOrderProcessingPage } from 'common/utilities/utils';
import { PAYMENT_TENDER_TYPES } from 'common/constants/SharedConstants';
import {
  dispatchPaymentRequest,
  dispatchGiftCardBalanceRequest,
  updateCheckoutDetailsWithPaymentReducerForKFCcard,
  updateCheckoutDetailsForRenderCanalFromRNA,
  getsavedCardId,
  checklastUsedPaymentMethodInDisplayTenders,
} from '../utils/JapanPaymentUtils';
import ModalComponent from 'molecules/Modal';
import { Helmet } from 'react-helmet';
import { isEmpty } from 'lodash';
import DgftApplepay from './templates/DgftApplepay';

const JapanPaymentView = props => {
  const { checkoutDetails, updateCheckoutDetails, history } = props;
  const { paymentsReducer } = useSelector(data => data);
  const finalAmount =
    Number(paymentsReducer?.giftCardbalanceResponse?.cardBalance) -
    checkoutDetails?.total / checkoutDetails?.currencyConversionFactor;

  useEffect(() => {
    checkoutDetails?.userParams?.loggedIn && dispatchGiftCardBalanceRequest(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.userParams?.loggedIn]);

  useEffect(() => {
    updateCheckoutDetailsWithPaymentReducerForKFCcard({
      checkoutDetails,
      updateCheckoutDetails,
      paymentsReducer,
      finalAmount,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paymentsReducer?.giftCardbalanceResponse,
    checkoutDetails?.isPaymentSelected,
    checkoutDetails?.tender?.name,
    checkoutDetails?.userParams?.loggedIn,
  ]);

  useEffect(() => {
    checkoutDetails?.isUpdatedOrderActionRequest && updateCheckoutDetails({ dispatchOrderAction: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isUpdatedOrderActionRequest]);

  useEffect(() => {
    if (checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.PAY_BY_CASH) {
      checkoutDetails?.orderId &&
        checkoutDetails?.isCreateOrderValidateCompleted &&
        redirectToOrderProcessingPage(props);
    } else {
      ((checkoutDetails?.isCreateOrderValidateCompleted &&
        checkoutDetails?.orderId &&
        checkoutDetails?.isClickPlaceOrder &&
        checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.CANAL) ||
        checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.DGFT_CREDIT_CARD ||
        checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.DGFT_APPLEPAY ||
        checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.GIFT_CARD) &&
        dispatchPaymentRequest(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isCreateOrderValidateCompleted]);

  useEffect(() => {
    !isEmpty(paymentsReducer?.paymentRequestedResponse) &&
      checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.GIFT_CARD &&
      checkoutDetails?.isClickPlaceOrder &&
      redirectToOrderProcessingPage(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsReducer?.paymentRequestedResponse]);

  useEffect(() => {
    if (checkoutDetails?.paymentProvider) {
      updateCheckoutDetails({
        enableDgftCardPanel: true,
        enablePaymentCardInputPanel: false,
        DgftPaymentId: null,
        DgftTokenApiKey: null,
        DgftresHtml: null,
        DgftModalJs: null,
        DgftModalOpen: false,
        dgftAccountId: '',
        dgftPaymentId: '',
        dgftTokenApiKey: '',
        dgftresHtml: '',
        dgftModalJs: '',
        dgftModalOpen: false,
        dgftApplePayScriptLoaded: false,
        dgftApplePaySession: null,
        dgftApplePayValidationURL: null,
        deleteCardModal: false,
        deleteCardId: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.paymentProvider]);

  useEffect(() => {
    if (
      (checkoutDetails?.isPaymentTypeSelected &&
        checkoutDetails?.tender?.name !== PAYMENT_TENDER_TYPES?.DGFT_CREDIT_CARD &&
        !checkoutDetails?.cardid) ||
      (checkoutDetails?.isPaymentTypeSelected && checkoutDetails?.cardid)
    ) {
      updateCheckoutDetails({
        enableContinuePayment: false,
        isClickContinuePayment: true,
        showPlaceOrderLoading: false,
        placeOrderButtonEnabled:
          checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.GIFT_CARD ? finalAmount >= 0 : true,
        isPaymentTypeSelected: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.tender?.name, checkoutDetails?.isPaymentTypeSelected, checkoutDetails?.cardid]);

  useEffect(() => {
    if (checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.DGFT_CREDIT_CARD && checkoutDetails?.DgftModalOpen) {
      const interval = setInterval(() => {
        window?.OnLoadEvent();
      }, 1000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.DgftModalOpen]);

  useEffect(() => {
    if (
      checkoutDetails?.isClickAddPayment &&
      checkoutDetails?.total > 0 &&
      checklastUsedPaymentMethodInDisplayTenders(checkoutDetails)
    ) {
      updateCheckoutDetails({
        isClickAddPayment: true,
        tender: {
          name: checkoutDetails?.lastUsedPaymentMethod,
          payload: { tender: checkoutDetails?.lastUsedPaymentMethod },
        },
        isPaymentTypeSelected: true,
        cardid: getsavedCardId(checkoutDetails),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isClickAddPayment]);

  useEffect(() => {
    if (checkoutDetails?.isPaymentTypeSelected && checkoutDetails?.cardid) {
      updateCheckoutDetails({
        tender: {
          name: PAYMENT_TENDER_TYPES?.DGFT_CREDIT_CARD,
          payload: { tender: PAYMENT_TENDER_TYPES?.DGFT_CREDIT_CARD },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isPaymentTypeSelected, checkoutDetails?.cardid]);

  useEffect(() => {
    if (checkoutDetails?.savedCards?.length > 0) {
      updateCheckoutDetails({
        dgftAccountId: checkoutDetails?.savedCards[0]?.dgftAccountId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.savedCards]);

  useEffect(() => {
    updateCheckoutDetails({
      isClickPlaceOrder: false,
      isUpdatedOrderActionRequest: false,
      dispatchOrderAction: false,
      isCreateOrderValidateCompleted: false,
      paymentEnabled: true,
      isOrderCapacityLoading: true,
      paymentRequestedResponse: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.tender?.name]);

  useEffect(() => {
    checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.DGFT_APPLEPAY &&
      checkoutDetails?.isUpdatedOrderActionRequest &&
      updateCheckoutDetails({ dispatchOrderAction: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isUpdatedOrderActionRequest]);

  const getCreditCardModalProps = () => ({
    id: 'DgftModalPopup',
    role: 'dialog',
    modalTitleId: 'DgftModalPopup',
    show: true,
    classNames: 'DgftModalPopup',
    showCloseBtn: true,
    showBackBtn: false,
    autoFocus: true,
  });

  useEffect(() => {
    updateCheckoutDetailsForRenderCanalFromRNA({ checkoutDetails, updateCheckoutDetails });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isRenderFromRNA, checkoutDetails?.tender?.name]);
  return (
    <>
      {/* Payment Options */}
      {!checkoutDetails?.isRenderFromRNA && getPaymentOptionsTemplate(props)}

      {/* Credit Card */}
      {checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.DGFT_CREDIT_CARD && checkoutDetails?.DgftModalOpen && (
        <ModalComponent {...getCreditCardModalProps()}>
          {checkoutDetails?.DgftModalOpen && (
            <Helmet>
              <script type='text/javascript'>{checkoutDetails?.DgftModalJs[1]}</script>
            </Helmet>
          )}
          <div>
            <div dangerouslySetInnerHTML={{ __html: checkoutDetails?.DgftresHtml }}></div>
          </div>
        </ModalComponent>
      )}

      {/* Apple Pay session */}
      {checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.DGFT_APPLEPAY && (
        <DgftApplepay
          checkoutDetails={checkoutDetails}
          updateCheckoutDetails={updateCheckoutDetails}
          history={history}
        />
      )}

      {/* Hidden Forms */}
      <div className='hidden'>
        {(checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.CANAL || checkoutDetails?.isRenderFromRNA) && (
          <CanalPaymentHiddenForm
            checkoutDetails={checkoutDetails}
            data={checkoutDetails?.isRenderFromRNA ? checkoutDetails : paymentsReducer}
          />
        )}
      </div>
    </>
  );
};

export default JapanPaymentView;
