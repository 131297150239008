import { useEffect } from 'react';
import { updateClickPlaceOrderState } from 'common/utilities/PaymentUtils';
import { getGlobalPaymentsEnvironment } from '../utils/GlobalPaymentUtils';
import { config } from '@kfc-global/react-shared/config/config.utils';
import {
  GLOBAL_PAYMENTS_APPLE_PAY_MERCHANT_NAME,
  GLOBAL_PAYMENTS_APPLE_PAY_MERCHANT_IDENTIFIER,
  GLOBAL_PAYMENTS_APPLE_PAY_CLIENTID,
  GLOBAL_PAYMENTS_APPLE_PAY_MERCHANT_SESSIONS_URL,
} from '@kfc-global/react-shared/config/config.constants';
import PAYMENT_CONSTANTS from 'organisms/Payment/constants/PaymentConstants';
import { getLanguage } from '@kfc-global/kfc-i18n/lib/i18N';

const { GLOBAL_PAYMENT_APPLE_PAY_CONFIGURATION_CONSTANTS } = PAYMENT_CONSTANTS;

const updateGlobalPaymentApplePayForm = params => {
  const { checkoutDetails, updateCheckoutDetails } = params;

  const globalPaymentApplePayForm = window?.GlobalPayments?.apm.form('#payment-provider-place-order-apple-pay-form', {
    amount: checkoutDetails?.finalAmount?.toString(),
    style: 'gp-default',
    apms: ['apple-pay'],
  });

  globalPaymentApplePayForm?.ready(() => {
    console.log('Global Payment Card Form Ready');
  });

  globalPaymentApplePayForm?.on('token-success', response => {
    updateCheckoutDetails({ globalPaymentsOneTimeResponse: response });
    updateClickPlaceOrderState(checkoutDetails, updateCheckoutDetails);
  });

  globalPaymentApplePayForm?.on('token-error', response => {
    // Show error to the consumer
    console.log('Error on Global Payment Card Form');
  });
};

const GlobalPaymentApplePayForm = props => {
  const { checkoutDetails } = props;

  useEffect(() => {
    window?.GlobalPayments?.configure({
      accessToken: checkoutDetails?.globalPaymentsAccesstoken,
      apiVersion: GLOBAL_PAYMENT_APPLE_PAY_CONFIGURATION_CONSTANTS?.API_VERSION,
      env: getGlobalPaymentsEnvironment(),
      apms: {
        currencyCode: checkoutDetails?.currencyCode,
        allowedCardNetworks: GLOBAL_PAYMENT_APPLE_PAY_CONFIGURATION_CONSTANTS?.ALLOWED_CARD_NETWORKS,
        applePay: {
          allowedCardNetworks: GLOBAL_PAYMENT_APPLE_PAY_CONFIGURATION_CONSTANTS?.ALLOWED_CARD_NETWORKS,
          applePayVersionNumber: GLOBAL_PAYMENT_APPLE_PAY_CONFIGURATION_CONSTANTS?.APPLE_PAY_VERSION_NUMBER,
          buttonLocale: getLanguage?.(), //'en-US'
          buttonStyle: GLOBAL_PAYMENT_APPLE_PAY_CONFIGURATION_CONSTANTS?.BUTTON_STYLE_COLOR,
          buttonType: GLOBAL_PAYMENT_APPLE_PAY_CONFIGURATION_CONSTANTS?.BUTTON_TYPE,
          currencyCode: checkoutDetails?.currencyCode,
          countryCode: checkoutDetails?.countryCode,
          merchantName: config(GLOBAL_PAYMENTS_APPLE_PAY_MERCHANT_NAME), //'KFC CANADA',
          merchantIdentifier: config(GLOBAL_PAYMENTS_APPLE_PAY_MERCHANT_IDENTIFIER), // 'merchant.com.gpapi.sandbox',
          merchantCapabilities: GLOBAL_PAYMENT_APPLE_PAY_CONFIGURATION_CONSTANTS?.MERCHANT_CAPABILITIES,
          globalPaymentsClientID: config(GLOBAL_PAYMENTS_APPLE_PAY_CLIENTID), // 'gpapiqa1',
          merchantSessionUrl: config(GLOBAL_PAYMENTS_APPLE_PAY_MERCHANT_SESSIONS_URL),
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.globalPaymentsAccesstoken]);

  useEffect(() => {
    updateGlobalPaymentApplePayForm({ ...props });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default GlobalPaymentApplePayForm;
