import React from 'react';

import { PAYMENT_TENDER_TYPES } from '../../common/constants/SharedConstants';
import PaymentFormCreditCard from './PaymentFormCreditCard';
import PaymentFormNetBankingOtherWallet from './PaymentFormNetBankingOtherWallet';
import PaymentFormGiftCard from './PaymentFormGiftCard';

export default function PaymentForm({
  formPayload,
  setFormPayload,
  tender,
  isValid,
  setIsValid,
  updateCheckoutDetails,
  checkoutDetails,
}) {
  return (
    <React.Fragment>
      {tender?.name === PAYMENT_TENDER_TYPES.CREDIT_CARD && (
        <PaymentFormCreditCard
          formPayload={formPayload}
          setFormPayload={setFormPayload}
          isValid={isValid}
          setIsValid={setIsValid}
          updateCheckoutDetails={updateCheckoutDetails}
          checkoutDetails={checkoutDetails}
        />
      )}

      {(tender?.name === PAYMENT_TENDER_TYPES.NET_BANKING || tender?.name === PAYMENT_TENDER_TYPES.OTHER_WALLET) && (
        <PaymentFormNetBankingOtherWallet
          isValid={isValid}
          setIsValid={setIsValid}
          formPayload={formPayload}
          setFormPayload={setFormPayload}
          tender={tender}
          updateCheckoutDetails={updateCheckoutDetails}
          checkoutDetails={checkoutDetails}
        />
      )}

      {tender?.name === PAYMENT_TENDER_TYPES.GIFT_CARD && (
        <PaymentFormGiftCard
          isValid={isValid}
          setIsValid={setIsValid}
          formPayload={formPayload}
          setFormPayload={setFormPayload}
          updateCheckoutDetails={updateCheckoutDetails}
          checkoutDetails={checkoutDetails}
        />
      )}
    </React.Fragment>
  );
}
