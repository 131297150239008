import { useEffect } from 'react';

function PaymentFormCreditCardBilldesk({
  name,
  cardNumber,
  expiryDate,
  cvc,
  userEmail,
  userPhone,
  paymentRequestMessage,
}) {
  useEffect(() => {
    if (paymentRequestMessage && cardNumber && document?.form10) {
      window.ccvalidation?.process({
        bdCardForm: 'form10',
        transactionInfo: {
          cnumber: document.form10.cnumber,
          cardExpMonth: document.form10.expmon,
          cardExpYear: document.form10.expyr,
          cardHolderName: document.form10.cname2,
          cardPasscode: document.form10.cvv2,
        },
        userInfo: {
          userEmail: document.form10.emailAdd,
          userMobile: document.form10.mobileAdd,
        },
        MsgSrc: paymentRequestMessage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document?.form10, paymentRequestMessage, cardNumber]);

  return (
    <div>
      {cardNumber && (
        <form name='form10' id='form10'>
          <input type='hidden' name='cname2' id='cname2' value={name} />
          <input type='hidden' name='cnumber' id='cnumber' value={cardNumber.replace(/\s+/g, '')} />
          <input type='hidden' name='expmon' id='expmon' value={(expiryDate?.split('/')[0] || '').trim()} />
          <input type='hidden' name='expyr' id='expyr' value={`20` + (expiryDate?.split('/')[1] || '').trim()} />
          <input type='hidden' name='cvv2' id='cvv2' value={cvc} />
          <input type='hidden' name='emailAdd' id='emailAdd' value={userEmail} />
          <input type='hidden' name='mobileAdd' id='mobileAdd' value={userPhone} />
        </form>
      )}
    </div>
  );
}

export default PaymentFormCreditCardBilldesk;
