import { toastifyFn } from 'common/utilities/utils';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { getCustomerInfo } from '@kfc-global/react-shared/redux/Actions/OrderservAction';
import {
  deleteKbankSavedCards,
  deleteSavedCard,
  kbankGetSavedCards,
} from '@kfc-global/react-shared/redux/Actions/PaymentsAction';
import { savedCardResponseFormatted } from 'organisms/Payment/utils/ThailandPaymentUtils';
import { PAYMENT_GATEWAYS } from 'common/constants/SharedConstants';

export const getCardIconFromContentFul = (allCardsTypesList, cardtype) => {
  return allCardsTypesList
    ?.filter(cardItem => {
      return cardItem?.fields?.title?.toLowerCase()?.includes(cardtype?.toLowerCase());
    })
    .map(cardItem => {
      return cardItem?.fields?.icon?.fields?.desktopImage?.fields?.file?.url;
    });
};

export const cardDeleteSuccessCallback = dispatch => {
  const reqPayload = {
    updateUserData: true,
  };
  dispatch(getCustomerInfo(reqPayload));
  toastifyFn(translateWithI18Next(TRANSLATE_MAPPING_KEY?.CARD_DELETED), '', 'card-deleted-toast');
};

export const dispatchKbankGetSavedCardsApiRequestForAccountSection = params => {
  const { dispatch, tenantId, customerId, paymentProvider, setSaveCardsList } = params;
  dispatch(
    kbankGetSavedCards(
      {
        tenantId,
        customerId,
      },
      response => {
        response?.cards?.length ? setSaveCardsList(savedCardResponseFormatted(response)) : setSaveCardsList([]);
      },
      error => {
        console.log('response in kbankGetSavedCards error', error);
      },
    ),
  );
};

export const dispatchDeleteSavedCardsApiRequestForAccountSection = params => {
  const { dispatch, paymentProvider, customerId, tenantId, cardId, setSaveCardsList } = params;
  const getCardsParams = {
    dispatch,
    tenantId,
    customerId,
    paymentProvider,
    setSaveCardsList,
  };
  if (paymentProvider === PAYMENT_GATEWAYS?.THAILAND_PAYMENTS) {
    dispatch(
      deleteKbankSavedCards(
        {
          tenantId,
          customerId,
          cardId,
        },
        response => {
          dispatchKbankGetSavedCardsApiRequestForAccountSection(getCardsParams);
          toastifyFn(translateWithI18Next(TRANSLATE_MAPPING_KEY?.CARD_DELETED), '', 'card-deleted-toast');
        },
        error => {
          toastifyFn(translateWithI18Next(TRANSLATE_MAPPING_KEY?.CARD_DELETED_FAILED), '', 'card-deleted-toast');
        },
      ),
    );
  } else {
    dispatch(
      deleteSavedCard({ customerId, tenantId, cardId }, () => {
        cardDeleteSuccessCallback(dispatch);
      }),
    );
  }
};
