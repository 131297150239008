import React from 'react';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';

const LoadingBucket = () => {
  return (
    <div className='bucketContainer'>
      <div className='opacityCont'></div>
      <div className='centeredLoader'>
        <img src={IMAGE_PATH?.KFC_Loader_Gif} className={`pull-down-loader big`} alt='emptyCart' />
      </div>
    </div>
  );
};

export default LoadingBucket;
