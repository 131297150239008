import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  brainTreeInstanceHandler,
  changeActiveViewHandler,
  createBraintreeInstance,
  dispatchGenerateClientTokenAction,
  dispatchTransactionSaleAction,
  noPaymentMethodRequestableHandler,
  paymentMethodRequestableHandler,
  unSubscribeBraintreeHandler,
  triggerBraintreeRequestPaymentMethod,
} from '../utils/BraintreePaymentUtils';
import { clearTenderDetails } from 'common/utilities/PaymentUtils';
import { getGenerateClientTokenLoadingTemplate } from './templates/PaymentViewTemplates';

const BraintreePaymentView = props => {
  const { checkoutDetails, updateCheckoutDetails, checkoutDetailsUseRef } = props;
  const [braintreeInstance, setBraintreeInstance] = useState(undefined);
  const { braintreePaymentReducer } = useSelector(data => data);
  const isGenerateClientTokenLoading = useSelector(data => data.braintreePaymentReducer?.isGenerateClientTokenLoading);
  const isOrdersLoading = useSelector(
    data => data.orderReducer?.isPlaceOrder || data.braintreePaymentReducer?.transactionSaleLoading,
  );

  /*********************************************************************
   *
   *
   *********************************************************************/
  const paymentMethodRequestableHandlerBind = useCallback(
    event =>
      paymentMethodRequestableHandler(checkoutDetailsUseRef.current, updateCheckoutDetails, braintreeInstance, event), // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkoutDetailsUseRef.current, updateCheckoutDetails, braintreeInstance],
  );

  /*********************************************************************
   *
   *
   *********************************************************************/
  const changeActiveViewHandlerBind = useCallback(
    event => changeActiveViewHandler(checkoutDetailsUseRef.current, updateCheckoutDetails, event), // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkoutDetailsUseRef.current, updateCheckoutDetails],
  );

  /*********************************************************************
   *
   *
   *********************************************************************/
  const paymentOptionSelectedHandlerBind = useCallback(
    event => console.log('paymentOptionSelected *****> ', event), // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /*********************************************************************
   *
   *
   *********************************************************************/
  const noPaymentMethodRequestableHandlerBind = useCallback(
    event => noPaymentMethodRequestableHandler(checkoutDetailsUseRef.current, updateCheckoutDetails, event), // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkoutDetailsUseRef.current, updateCheckoutDetails],
  );

  /*********************************************************************
   *
   *
   *********************************************************************/
  useEffect(() => {
    updateCheckoutDetails({ showPlaceOrderLoading: isOrdersLoading });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOrdersLoading]);

  /*********************************************************************
   *
   *
   *********************************************************************/
  useEffect(() => {
    if (checkoutDetails?.isClickAddPayment) {
      clearTenderDetails(updateCheckoutDetails);
      dispatchGenerateClientTokenAction({ props, checkoutDetails, updateCheckoutDetails });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isClickAddPayment]);

  /*********************************************************************
   *
   * console.debug('allowedTenders => ', allowedTenders);
   * console.debug('getPaymentOptions => ', getPaymentOptions(allowedTenders));
   *********************************************************************/
  useEffect(() => {
    createBraintreeInstance({
      braintreeInstance,
      setBraintreeInstance,
      checkoutDetails,
      updateCheckoutDetails,
      braintreePaymentReducer,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [braintreePaymentReducer?.token]);

  /*********************************************************************
   *
   *
   *********************************************************************/
  useEffect(() => {
    brainTreeInstanceHandler({
      braintreeInstance,
      setBraintreeInstance,
      checkoutDetails,
      updateCheckoutDetails,
      braintreePaymentReducer,
      changeActiveViewHandlerBind,
      paymentMethodRequestableHandlerBind,
      noPaymentMethodRequestableHandlerBind,
      paymentOptionSelectedHandlerBind,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [braintreeInstance]);

  /*********************************************************************
   *
   *
   *********************************************************************/
  useEffect(() => {
    return (
      braintreeInstance &&
      unSubscribeBraintreeHandler({
        braintreeInstance,
        changeActiveViewHandlerBind,
        paymentMethodRequestableHandlerBind,
        noPaymentMethodRequestableHandlerBind,
        paymentOptionSelectedHandlerBind,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*********************************************************************
   *
   *
   *********************************************************************/
  useEffect(() => {
    checkoutDetails?.orderId &&
      checkoutDetails?.isCreateOrderValidateCompleted &&
      dispatchTransactionSaleAction({ props, checkoutDetails, updateCheckoutDetails });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isCreateOrderValidateCompleted]);

  /*********************************************************************
   *
   *
   *********************************************************************/
  useEffect(() => {
    checkoutDetails?.isClickContinuePayment &&
      triggerBraintreeRequestPaymentMethod(braintreeInstance, updateCheckoutDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isClickContinuePayment]);

  /*********************************************************************
   *
   *
   *********************************************************************/
  useEffect(() => {
    checkoutDetails?.isUpdatedOrderActionRequest && updateCheckoutDetails({ dispatchOrderAction: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isUpdatedOrderActionRequest]);

  return (
    <>
      {/* Display Braintree payment */}
      {checkoutDetails?.isClickAddPayment && (
        <div className='braintreePaymentContent'>
          <div id='braintree-drop-in-div' />
        </div>
      )}
      {getGenerateClientTokenLoadingTemplate(isGenerateClientTokenLoading)}
    </>
  );
};

export default React.memo(BraintreePaymentView);
